
<style scoped>
.script_editor {
  height: 400px;
  width: 100%;
}
</style>

<template>
  <a-drawer
    title="提供方接口参数增加"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="接口名称" name="portId"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-select
              v-model:value="formData.portId"
              show-search
              :filter-option="filterOption"
              placeholder="请选择"    allow-clear >
              <a-select-option v-for="item in portIds" :key="item.id" :value="item.id" :label="item.name" clearable>
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="参数类型:" name="type"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <a-select v-model:value="formData.type" allow-clear  placeholder="请选择字段类型" >
              <a-select-option value= '1'>入参</a-select-option>
              <a-select-option value= '2'>出参</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="16">
          <a-form-item label="编辑器类型:">
            <a-radio-group v-model:value="language" @change="changeEditor">
              <a-radio value="xml">xml</a-radio>
              <a-radio value="json">json</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col span="16">
          <a-form-item label="数据体:" name="content"
                       :rules="[{required:true,message:'必填信息',trigger:'blur'}]">
<!--            <a-textarea v-model:value="formData.content" style="height: 300px" :rows="4"/>-->
            <JsonXmlEditor ref="mEditor" v-if="languageFlag" class="script_editor" sonId="editorContaineripp" name="interfaceProviderParametersAdd" :language="language" :content="formData.content"
                           @scriptChange="scriptChange" @clearRunStr="clearRunStr"></JsonXmlEditor>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave" >
          <template #icon>
            <RetweetOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>

import {defineComponent, reactive, toRefs, ref, nextTick} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import JsonXmlEditor from '../../../components/extended/JsonXmlEditor'

export default defineComponent({
  components: {
    JsonXmlEditor
  },
  setup(props, {expose}, context) {
    const formRef = ref()
    const mEditor = ref(null)

    const formData = reactive({
      type:'1',//参数类型
      content:'',//数据体
      portId:undefined//接口名称
      // dataList: {
      //   portId: ''
      // },//数据来源
    })


    const state = reactive({
      language: 'xml',
      languageFlag: true,
      portIds:[],//接口名称
      dataSourceList:[],
      visible: false,
      isNew: true,
      selectedRowKeys: [],
    })

    //获取接口名称
    const jiekoumingcheng =()=>{
      let uri = getRequestURI('ExcPort_selectName')
      request(uri, {}).then(res => {
        console.log(res.result)
        if(res.code == 1) {
          state.portIds=res.result
        }else{
          message.warning(res.message)
        }
      })
    }
    const changeName =(val)=>{
      formData.dataList.portId=val
    }

    const showDrawerOne = (oldData = {},newData={}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      console.log(newData)
      if(oldData==='newAdd'){
        formData.ipId=newData
      }
      jiekoumingcheng()
    }
    const onFinish = (values) => {
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }

    //关闭
    const onClose = () => {
      state.visible = false
      formData.portId=undefined
      formData.content=''
      formData.type='1'
      state.language='xml'
      mEditor.value.restValue()
    }
    //重置
    const onFormClean = () => {
      // formRef.value.resetFields()
      // formData.portId=''
    }
    //提交
    const onSave = () => {
      if(!formData.portId){
        message['warning']('接口名称不能为空')
        return
      }
      if(!formData.type){
        message['warning']('参数类型不能为空')
        return
      }
      // scriptChange()
      // if(formData.content===''){
      //   message['warning']('数据体不能为空')
      //   return
      // }
      // if(mEditor.value.getRunScript())
      // let reg1Array = formData.content
      if(getRunStr().runStr==='\n'){
        message['warning']('数据体不能为空')
          return
      }
      // if(reg1Array.length>2000){
      //   message['warning']('数据体输入长度不能超过2000')
      // }
      getRunStr()
      let obj = {
        data:formData
      }
      let uri = getRequestURI('ExcPortConfig_importPc')
      request(uri, obj).then(data => {
        // console.log(data)
        if (data.code === 1) {
          onClose()
          message['success']('提交成功')
        } else {
          message['warning'](data.message)
        }
      })
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const scriptChange = (param) => {
      console.debug('scriptChange', param)
      // jsonxml.scriptText = param.originalStr
      let a = param.originalStrArray
      let buildStr = ''
      for (let i = 0; i < a.length; i++) {
        let text = a[i]
        buildStr += text + '\n'
      }
      formData.content = buildStr
    }

    const clearRunStr = () => {
      formData.content = ''
    }
    const getRunStr = () => {
      mEditor.value.getRunScript()
      return {
        runStr: formData.content,
        originalStr: formData.content
      }
    }
    const changeEditor = (val) => {
      state.language = val.target.value
      state.languageFlag = false
        nextTick(()=> {
          state.languageFlag = true
        })
      mEditor.value.restValue()
    }
    expose({
      showDrawerOne
    })
    return {
      filterOption,
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawerOne,
      changeName,
      jiekoumingcheng,
      scriptChange,
      clearRunStr,
      getRunStr,
      mEditor,
      changeEditor
    }
  },

})
</script>
