<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('newAdd')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="deleteUser">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="modifyUser(record, 'edit')">编辑</a
          >&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <productTemplateAdd
    ref="userDrawerRef"
    :handleSearch="handleSearch"
    :status="status"
    :searchfFilters="searchfFilters"
  ></productTemplateAdd>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import productTemplateAdd from './dockingChannelManagementAdd.vue'
import { Modal, message } from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    productTemplateAdd,

  },
  setup () {
    onMounted(() => {

    })
    const userDrawerRef = ref()
    const userDrawerRefOne = ref()
    const state = reactive({
      condition: {
        innerId: '调用方编码',
        name: '调用方名称'
      },
      status: '',
      result: [],
      abc: [],
      lastFilter: {},
      query: {
        data: {
          riskCode: '',
          riskLengthSign: '',
          riskName: '',
          riskSign: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
        }
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '调用方编码',
          width: 140,
          align: 'center',
          key: 'innerId',
          dataIndex: 'innerId',
        },
        {
          title: '调用方名称',
          width: 140,
          align: 'center',
          key: 'name',
          dataIndex: 'name',
        },

        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 200,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      selectedRowKeys: [],
      data: [],// 删除data
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ExcInsurer_selectList'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }//页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }

    //多选
    const onSelectChange = (selectedRowKeys) => {
      state.data.splice(0)
      state.selectedRowKeys = selectedRowKeys
      for (let selectedRowKeys of state.selectedRowKeys) {
        for (let result in state.result) {
          if (state.result[result].id == selectedRowKeys && state.data.includes(state.result[result]) == false) {
            state.data.push(state.result[result])
          }
        }
      }
      console.log(state.data)
    }


    //新增
    const addUser = function (status) {
      console.log(status)
      state.status = status
      userDrawerRef.value.showDrawer(status)
    }
    //编辑
    const modifyUser = function (record, status) {
      state.status = status
      userDrawerRef.value.showDrawer(record, status)
    }
    //批量删除
    const deleteUser = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk () {
          let uri = getRequestURI('ExcInsurer_deleteList')
          request(uri, {
            data: {
              ids: keys
            }
          }).then(
            (result) => {
              if (result.code === 1) {
                message['success']('删除成功')
                state.selectedRowKeys = []
                handleSearch({})
              } else {
                message.warning(result.message)
              }
            }
          )
        },
        onCancel () {
        }
      })
    }
    return {
      ...toRefs(state),
      handleSearch,
      addUser,
      userDrawerRef,
      userDrawerRefOne,
      deleteUser,
      modifyUser,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      current,
      pageSize,
      total,
      searchfFilters
    }
  },
})
</script>

<style scoped>
</style>
