<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
    @call="changes"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addProduct('add')">
      <template #icon>
        <FileAddOutlined/>
      </template>
      新增
    </a-button>
    <a-button danger @click="delMultiple">
      <template #icon>
        <DeleteOutlined/>
      </template>
      删除
    </a-button>
    <a-button danger @click="exportXML">
      <template #icon>
        <ToTopOutlined/>
      </template>
      导出为XML
    </a-button>
    <a-button danger @click="exportJSON">
      <template #icon>
        <ToTopOutlined/>
      </template>
      导出为JSON
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource1"
    :scroll="{ x: '100%'}"
    :key="time"
    :indentSize="30"
    :row-key="(record) => record.id"
    :loading="loading"
    @change="handleTableChange"
    :pagination="query.pagination"
  >
    <template #bodyCell="{ column,record, index }">
      <template v-if="column.key === 'index'">
        {{index+1}}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="modifyProduct(record, 'update')">编辑</a>&nbsp;&nbsp;
          <a style="color: orange" @click="dell(record)">删除</a>&nbsp;&nbsp;
          <a style="color: orange" @click="addtongji(record, 'addOne')">添加同级参数</a>&nbsp;&nbsp;
          <a style="color: orange" @click="addxiaji(record, 'addSon')">添加下级参数</a>&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <editChanneParmsAdmin ref="useredit"></editChanneParmsAdmin>
  <editChanneParmsAdminUpdate @select="getEditChange"  :current="query.pagination.current" ref="usereditUpdate"></editChanneParmsAdminUpdate>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request} from '../../../utils/request'
import editChanneParmsAdmin from './editChanneParmsAdmin'
import editChanneParmsAdminUpdate from './editChanneParmsAdminUpdate'
import {message, Modal} from 'ant-design-vue'
// import {getDictionaryData} from '../../../utils/request_extend'
// import {getExtendUri} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    SearchForm,
    editChanneParmsAdmin,
    editChanneParmsAdminUpdate
  },
  setup() {
    onMounted(() => {
      getjiekou()
    })
    const loading=ref(false)
    const useredit = ref()
    const usereditUpdate = ref()
    const modifyProduct = function(value, status) {
      request(getRequestURI('ExcInsurerC_selectById'),{data: {id: value.id}}).then(res => {
        if (res.code === 1) {
          usereditUpdate.value.showDrawer(res.result, status)
        }
      })
    }
    const addtongji = function(record, status) {
      let obj = {}
      request(getRequestURI('ExcInsurerC_selectById'),{data: {id: record.id}}).then(res => {
        if (res.code === 1) {
          obj.parentId=res.result.parentId
          obj.ipId = state.query.data.ipId
          obj.insName = res.result.insName
          obj.type = res.result.type
          obj.portName = res.result.portName
          obj.parentColumnName = res.result.parentColumnName
          usereditUpdate.value.showDrawer(obj,status)
        }
      })
    }
    const delMultiple = function() {
      if (state.query.data.ipId !== '' || state.query.data.type !== '') {
        Modal.confirm({
          title: '删除提示',
          content: '确定删除此数据吗?',
          onOk: () => {
            request(getRequestURI('deleteAll1_Tree'), {data:{id :state.query.data.ipId,type: state.query.data.type}}).then(res => {
              console.log(res)
              if (res.code === 1) {
                message['success']('删除成功')
                handleSearch({ipId :state.query.data.ipId, type: state.query.data.type})
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message['error']('请把数据查询出来在删除')
      }
    }
    const dell = function(value) {
      Modal.confirm({
        title: '删除提示',
        content: '确定删除此数据吗?',
        onOk: () => {
          request(getRequestURI('ExcInsurerC_delete'), {data: {id: value.id}}).then(res => {
            if (res.code === 1) {
              message['success']('删除成功')
              handleSearch({ipId :state.query.data.ipId, type: state.query.data.type})
            } else {
              message['error'](res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()   // 清除弹出框
        }
      })
    }
    const changes = function(value) {
      state.condition.ipId.data = []
      state.dataInterface.forEach(res => {
        if (res.insId === value) {
          res.children.forEach(item => {
            let obj = {}
            obj.value = item.ipId
            obj.label = item.name
            state.condition.ipId.data.push(obj)
          })
        }

      })
    }
    const addxiaji = function(record, status) {
      let obj = {}
      request(getRequestURI('ExcInsurerC_selectById'),{data: {id: record.id}}).then(res => {
        if (res.code === 1) {
          obj.parentId = res.result.id
          obj.parentColumnName = res.result.column
          obj.ipId = state.query.data.ipId
          obj.insName = res.result.insName
          obj.type = res.result.type
          obj.portName = res.result.portName
          obj.parentColumnType = res.result.columnType
          usereditUpdate.value.showDrawer(obj,status)
        }
      })
    }
    const exportXML = function() {
      if (state.query.data.ipId !== '' || state.query.data.type !== '') {
        request(getRequestURI('ExcExport_exportXml'),
          state.query
          , true, 'post').then(
          (result) => {
            if(result.code===1){
              message.success(result.message)
              window.location = result.result
            }else{
              message.warn(result.message)
            }

          }
        ).catch(e => {
          // console.log(e)
          message.error(e)
        })
      } else {
        message['error']('请把数据查询出来在导出XML')
      }
    }
    const exportJSON = function() {
      if (state.query.data.ipId !== '' || state.query.data.type !== '') {
        // state.query.data.ipId=state.query.data.portId
        request(getRequestURI('ExcExport_exportJson'),
          state.query
          , true, 'post').then(
          (result) => {
            message.success(result.message)
            window.location = result.result
          }
        ).catch(e => {
          message.error(e)
        })
      } else {
        message['error']('请把数据查询出来在导出JSON')
      }
    }
    //搜索
    const handleSearch = function (filters) {
      loading.value=true
      state.time = new Date().getTime()
      state.query.data.ipId = filters.ipId
      state.query.data.type = filters.type
      let uri = getRequestURI('ExcInsurerConfig_selectList')
      request(uri, state.query).then(res=>{
          state.dataSource1 = res.result
          loading.value=false
      })
    }
    const handleTableChange = (page) =>{
      state.query.pagination = page
    }
    const getEditChange = (searchfFilters = {},num = '') =>{
      state.query.pagination.current = num
      handleSearch(searchfFilters)
    }
    const addProduct = () => {
      useredit.value.showDrawer()
    }
    //获取调用方名称
    const getjiekou = () => {
      request(getRequestURI('select_treeLeft'),{}).then(res => {
        state.dataInterface = res.result
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.insId
          obj.label = item.insName
          state.condition.insId.data.push(obj)
        })
      })
      // request(getRequestURI('ExcPort_selectName'), {}, true, 'post').then(
      //   (result) => {
      //     const arr = []
      //     const res = result.result
      //     for (let i = 0; i < res.length; i++) {
      //       arr.push({label: res[i].name, value: res[i].name})
      //     }
      //     state.condition.portName.data = arr
      //   }
      // )
    }
    const state = reactive({
      time:null,
      dataInterface: [],
      selectedRowKeys: [],
      dataSource1: [],
      status: '',
      query:{
        data:{
          ipId: '',
          type :''
        },
        pagination: {current: 1, pageSize: 10}
      },
      columns: [
        {
          title: '字段名称',
          width: 120,
          key: 'column',
          align: 'center',
          dataIndex: 'column',
        },
        {
          title: '字段类型',
          width: 100,
          align: 'center',
          key: 'columnType',
          dataIndex: 'columnType',
          customRender: (text)=>{
            if (text.value===1){
              return 'Value'
            }else if (text.value === 2){
              return 'Map'
            }else if (text.value === 3){
              return 'List'
            }else {
              return 'Boolean'
            }
          }
        },
        {
          title: '参数类型',
          width: 110,
          align: 'center',
          key: 'type',
          dataIndex: 'type',
          customRender: (text)=>{
            if (text.value === 1){
              return '入参'
            }else {
              return  '出参'
            }
          }
        },
        {
          title: '层级关系',
          width: 100,
          align: 'center',
          key: 'relationship',
          dataIndex: 'relationship',
        },
        {
          title: '字段说明',
          width: 100,
          align: 'center',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 200,
        },
      ],
      condition: {
        insId: {
          type: 'select1',
          label: '调用方名称',
          default: '',
          data: []
        },
        ipId: {
          type: 'select1',
          label: '接口名称',
          default: '',
          data: []
        },
        type: {
          type: 'select1',
          label: '参数类型',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'paramterType'}
        }
      }
    })
    return {
      ...toRefs(state),
      handleSearch,
      useredit,
      addProduct,
      modifyProduct,
      addtongji,
      dell,
      addxiaji,
      usereditUpdate,
      getjiekou,
      changes,
      exportXML,
      exportJSON,
      delMultiple,
      loading,
      handleTableChange,
      getEditChange
    }
  }
})
</script>

<style scoped>

</style>
