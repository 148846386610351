<style scoped>
</style>

<template>
  <a-drawer
    :title="panDuan"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData"  layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="接口名称:" name="portId"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="portId"  :disabled="true"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="上级字段:" name="parentColumnName"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="formData.parentColumnName"  :disabled="true"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="column"
                       :rules="[{max:30, message: '输入长度不能超过30' ,trigger: 'change'},
                         { required: true, message: '必填信息' ,trigger: 'change'}]">
            <template #label>
              <span>字段名称:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      作为报文节点的key值，不可为汉字，最长40字符
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.column"  placeholder="请输入字段名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="name"
                       :rules="[{max:50, message: '输入长度不能超过50' ,trigger: 'change'}]">
            <template #label>
              <span>字段说明:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      最长50个字符
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.name"  placeholder="请输入字段说明"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="columnType"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <template #label>
              <span>字段类型:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当字段类型为“Value”，“Boolean”时，不允许添加下级参数，当为“Map”时，必须要有下级参数，当list下没有下级参数时，list内为String类型
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.columnType" allow-clear   placeholder="请选择字段类型" >
              <a-select-option value= '1'>Value</a-select-option>
              <a-select-option value= '2'>Map</a-select-option>
              <a-select-option value= '3'>List</a-select-option>
              <a-select-option value= '4'>Boolean</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="校验类型:" name="checkType">
            <a-select v-model:value="formData.checkType" allow-clear   placeholder="请选择是否错误信息标记" >
              <a-select-option value= '0'>不校验</a-select-option>
              <a-select-option value= '1'>非空</a-select-option>
              <a-select-option value= '2'>邮箱校验</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="defaultValue"
                       :rules="[{max:100, message: '输入长度不能超过100' ,trigger: 'change'}]">
            <template #label>
              <span>默认值:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      字段映射的时候当映射字段为空时会取配置的默认值
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.defaultValue"  placeholder="请输入默认值"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="正确值:" name="correctValue"
                       :rules="[{max:100, message: '输入长度不能超过100' ,trigger: 'change'}]">
            <a-input v-model:value="formData.correctValue"  placeholder="请输入默认值"/>
          </a-form-item>
        </a-col>

        <a-col span="12">
          <a-form-item name="decrypt">
            <template #label>
              <span>是否解密:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      如果选择“是”，则会根据加密类型对当前字段的值进行解密
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.decrypt" allow-clear   placeholder="请选择是否解密" >
              <a-select-option value= '1'>是</a-select-option>
              <a-select-option value= '0'>否</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="解密算法:" name="decryptType">
            <a-select v-model:value="formData.decryptType" placeholder="请选择解密算法" :options="encryptTypeList"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="encrypt">
            <template #label>
              <span>是否加密:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      如果选择“是”,则会根据加密类型对当前字段的值进行加密
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.encrypt" allow-clear   placeholder="请选择是否加密" >
              <a-select-option value= '1'>是</a-select-option>
              <a-select-option value= '0'>否</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="加密算法:" name="encryptType">
            <a-select v-model:value="formData.encryptType" placeholder="请选择加密算法" :options="encryptTypeList"></a-select>
          </a-form-item>
        </a-col>

      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button  type="primary"  @click="onSave" >
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import {getExtendUri} from '@/utils/request_extend'
export default defineComponent({
  components: {

  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    handleSearch: {type: Function},
    lastFilter: {type: Object},
    current:{type: Number}
  },
  setup(props, {expose}, context) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      portId:'',//接口名称
      parentColumnName:undefined,//上级字段
      column:'',//字段名称
      name:'',//字段说明
      type: '2',
      columnType:'1',//字段类型
      defaultValue:'',//默认值
      correctValue:'',//正确值
      checkType:'0',//校验类型
      decrypt:'0',//是否解密
      decryptType:undefined,//解密标记
      encrypt:'0',//是否加密
      encryptType:undefined,//加密标记
      parentId:''
    })


    const state = reactive({
      encryptTypeList: [],
      portId:'',
      dataSourceList:[],
      visible: false,
      isNew: true,
    })

    const showDrawer = (oldData = {},newData={},lastFilter={}) => {
      state.lastFilter = lastFilter
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      formData.columnType = formData.columnType + ''
      state.portId=oldData.portName
      if(newData=='details'){
        formData.id=''
          formData.column=''//字段名称
          formData.name=''//字段说明
          formData.columnType='1'//字段类型
          formData.defaultValue=''//默认值
          formData.correctValue=''//正确值
          formData.checkType='0'//校验类型
          formData.decrypt='0'//是否解密
          formData.decryptType=undefined//解密标记
          formData.encrypt='0'//是否加密
          formData.encryptType=undefined//加密标记
          state.portId=oldData.portName
        formData.portId=oldData.portId
      formData.parentId=oldData.parentId
        formData.parentColumnName=oldData.parentColumnName
      }
      if(newData=='addSon'){
        formData.id=''
          formData.column=''//字段名称
          formData.name=''//字段说明
          formData.columnType='1'//字段类型
          formData.defaultValue=''//默认值
          formData.correctValue=''//正确值
          formData.checkType='0'//校验类型
          formData.decrypt='0'//是否解密
          formData.decryptType=undefined//解密标记
          formData.encrypt='0'//是否加密
          formData.encryptType=undefined//加密标记
          state.portId=oldData.portName
        formData.portId=oldData.portId
        formData.parentId=oldData.id
        formData.parentColumnType = oldData.columnType
        formData.parentColumnName=oldData.parentColumnNameChild
      }
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['encrypt_mark']}}).then(res => {
        state.encryptTypeList = res.result.encrypt_mark
      })

    }
    //title判断状态
    // eslint-disable-next-line vue/return-in-computed-property
    const panDuan = computed(() => {
      if (props.status == 'newAdd') {
        return '提供方接口参数增加'
      } else if (props.status == 'edit') {
        return '提供方接口参数修改'
      }else if (props.status === 'details') {
        return '添加同级参数'
      }else if (props.status === 'addSon') {
        return '添加下级参数'
      }
    })

    const onFinish = (values) => {
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }

    //关闭
    const onClose = () => {
      formRef.value.resetFields()
      onFormClean()
      Object.assign(formData, {
        id: '',
        portId:'',//接口名称
        parentColumnName:undefined,//上级字段
        column:'',//字段名称
        name:'',//字段说明
        type: '2',
        columnType:'1',//字段类型
        defaultValue:'',//默认值
        correctValue:'',//正确值
        checkType:'0',//校验类型
        decrypt:'0',//是否解密
        decryptType:undefined,//解密标记
        encrypt:'0',//是否加密
        encryptType:undefined,//加密标记
        parentId:''
      })
      state.visible = false
    }
    //重置
    const onFormClean = () => {

      formData.portId=''//接口名称

    }

    //保存/下一步
    const onSave = () => {
      //接口名称校验
      if( formData.column === '') {
        message['warning']('字段名称不能为空')
        return
      }
      let reg1=formData.column
      if (reg1 && reg1.length>30){
        formData.column=''
        message['warning']('字段名称输入长度不能超过30')
        return
      }

      //字段说明校验
      let reg2=formData.name
      if (reg2 && reg2.length>50){
        formData.name=''
        message['warning']('字段说明输入长度不能超过50')
        return
      }
      //字段类型校验
      if(formData.columnType===''){
        message['warning']('字段类型不能为空')
      }
      //默认值校验
      let reg3=formData.defaultValue
      if (reg3 && reg3.length>100){
        formData.defaultValue=''
        message['warning']('默认值输入长度不能超过100')
        return
      }
      //正确值校验
      let reg4=formData.correctValue
      if (reg4 && reg4.length>100){
        formData.correctValue=''
        message['warning']('正确值输入长度不能超过100')
        return
      }
      //解密标记校验
      let reg5=formData.decrypt
      if (reg5 && reg5.length>100){
        formData.decrypt=''
        message['warning']('解密标记输入长度不能超过100')
        return
      }
      //加密标记校验
      let reg6=formData.encryptType
      if (reg6 && reg6.length>100){
        formData.encryptType=''
        message['warning']('加密标记输入长度不能超过100')
        return
      }
      let obj = {
        data:formData
      }
      if(props.status === 'edit'){
        let uri = getRequestURI('ExcPortConfig_update')
        request(uri, obj).then(res => {
          if(res.code == 1) {
            props.handleSearch(props.lastFilter,props.current)
            onClose()
            message['success']('修改成功')
          } else {
            message['warning'](res.message)
          }
        })
      }else  if(props.status === 'details'||props.status === 'addSon'){
        if(props.status === 'addSon' && Number(formData.parentColumnType) === 1) return message.warning('上级字段类型为value的数据不允许添加下级')
        let uri = getRequestURI('ExcPortConfig_insert')
        request(uri, obj).then(data => {
          if (data.code == 1) {
            props.handleSearch(state.lastFilter)
            onClose()
            message['success']('保存成功')
          } else {
            message['warning'](data.message)
          }
        })
      }
    }
    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawer,
      panDuan,



    }
  },

})
</script>
