<style scoped>
</style>

<template>
  <a-drawer
    :title="panDuan"
    :width="'30%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item label="调用方编码:" name="innerId"
                       :rules="[{max:20, message: '输入长度不能超过20' ,trigger: 'change'},
                         { required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="formData.innerId"  placeholder="请输入调用方编码"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="调用方名称:" name="name"
                       :rules="[{max:20, message: '输入长度不能超过20' ,trigger: 'change'},
                         { required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="formData.name"  placeholder="请输入调用方名称"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button  type="primary"  @click="onSave" >
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
export default defineComponent({
  components: {

  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    searchfFilters: { type: Object },
    handleSearch: {type: Function}
  },
  setup(props, {expose}, context) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      innerId:'',//接口名称
      name:'',//接口标识
    })


    const state = reactive({
      dataSourceList:[],
      visible: false,
      isNew: true,
    })

    const showDrawer = (oldData = {}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    //title判断状态
    // eslint-disable-next-line vue/return-in-computed-property
    const panDuan = computed(() => {
      if (props.status == 'newAdd') {
        return '调用方增加'
      } else if (props.status == 'edit') {
        return '调用方修改'
      }
    })

    const onFinish = (values) => {
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }

    //关闭
    const onClose = () => {
      state.visible = false
      formRef.value.clearValidate()
      onFormClean()
      formData.id= '',
        formData.innerId='',//接口名称
        formData.name=''//接口标识
    }
    //重置
    const onFormClean = () => {


    }

    //保存/下一步
    const onSave = () => {
      //接口名称校验
      if( formData.innerId === '') {
        message['warning']('调用方编码不能为空')
        return
      }
      let reg1=formData.innerId
      if (reg1.length>20){
        formData.innerId=''
        message['warning']('调用方编码输入长度不能超过20')
        return
      }

      //接口标识校验
      if( formData.name === '') {
        message['warning']('调用方名称不能为空')
        return
      }
      let reg2=formData.name
      if (reg2.length>20){
        formData.name=''
        message['warning']('调用方名称输入长度不能超过20')
        return
      }
      let obj = {
        data:formData
      }
      if(props.status == 'edit'){
        let uri = getRequestURI('ExcInsurer_update')
        console.log(uri)
        request(uri, obj).then(res => {
          if(res.code == 1) {
            onClose()
            message['success']('修改成功')
            props.handleSearch(props.searchfFilters)
          }else{
            message['warning'](res.message)
          }
        })
      }else  if(props.status == 'newAdd'){
        let uri = getRequestURI('ExcInsurer_insert')
        request(uri, obj).then(data => {
          if (data.code === 1) {
            onClose()
            props.handleSearch({})
            message['success']('保存成功')
          }else {
            message['warning']('调用方编码已存在，请重新输入')
          }
        })
      }
    }
    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawer,
      panDuan,



    }
  },

})
</script>
