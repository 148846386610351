<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <!--    <a-button type="primary" @click="addUser('add')">-->
    <!--      <template #icon>-->
    <!--        <FileAddOutlined/>-->
    <!--      </template>-->
    <!--      新增-->
    <!--    </a-button>-->
    <a-button type="default" @click="startModal">
      <template #icon>
        <check-outlined />
      </template>
      启用
    </a-button>
    <a-button type="default" @click="endModal">
      <template #icon>
        <close-outlined />
      </template>
      禁用
    </a-button>
    <a-button type="default" @click="delModal">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a-button
            type="text"
            style="margin-right: 10px; color: orange"
            size="small"
            @click="upUser(record, 'update')"
          >
            修改
          </a-button>
          <a-button
            type="text"
            size="small"
            style="color: orange"
            v-if="record.labelLevel !== 3"
            @click="addUserSon(record, 'addSon')"
          >
            添加下级
          </a-button>
        </div>
      </template>
    </template>
  </a-table>
  <product-manage
    ref="userDrawerRef"
    @select="select"
    :status="status"
  ></product-manage>
</template>

<script>
import SearchForm from '../../../components/extended/search_form'
import { computed, defineComponent, reactive, ref, toRefs, onMounted } from 'vue'
import { getRequestURI, requestUsePagination, request } from '../../../utils/request'
import ProductManage from './productManage'
import { Modal, message } from 'ant-design-vue'
import { getDictionaryValue } from '../../../utils/request_extend'
export default defineComponent({
  components: {
    ProductManage,
    SearchForm
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      columns: [
        {
          title: '标签名称',
          width: 100,
          fixed: 'left',
          key: 'labelName',
          dataIndex: 'labelName',
        },
        {
          title: '级别',
          width: 100,
          align: 'center',
          key: 'labelLevel',
          dataIndex: 'labelLevel',
          customRender: (text) => {
            if (text.value === 1) {
              return '一级'
            } else if (text.value === 2) {
              return '二级'
            } else {
              return '三级'
            }
          }
        },
        {
          title: '关联关键字',
          width: 100,
          align: 'center',
          key: 'keyword',
          dataIndex: 'keyword',
        },
        {
          title: '启用状态',
          width: 100,
          align: 'center',
          key: 'startState',
          dataIndex: 'startState',
          customRender: (text) => getDictionaryValue('status', text.value)
        },
        {
          title: '创建人',
          width: 100,
          align: 'center',
          key: 'createUser',
          dataIndex: 'createUser',
        },
        {
          title: '创建时间',
          width: 100,
          align: 'center',
          key: 'createTime',
          dataIndex: 'createTime',
        },
        {
          title: '操作',
          key: 'operation',
          align: 'left',
          width: 100,
        }
      ],
      result: [
        // {
        //   biaoqianmingcheng: '一二一二',
        //   jibie: '一级',
        //   guanlianguanjianzi: 'asdsd',
        //   qiyongzhuangtai: '启用',
        //   chuangjianren: 'asfdf',
        //   chuangjianshijian: '2021-04-24',
        //   children: [
        //     {
        //       biaoqianmingcheng: '一二',
        //       jibie: 'e二级',
        //       guanlianguanjianzi: 'asds',
        //     }
        //   ]
        // }
      ],
      loading: false,
      status: '',
      lastFilter: {},
      delQuery: [],
      selectedRowKeys: [],
      condition: {
        labelName: '标签名称',
        // labelLevel: {
        //   label: '标签级别',
        //   type: 'select',
        //   data: [
        //     {
        //       label: '一级',
        //       value: 1
        //     },
        //     {
        //       label: '二级',
        //       value: 2
        //     },
        //     {
        //       label: '三级',
        //       value: 3
        //     },
        //   ]
        // },
        startState: {
          label: '启用状态',
          type: 'select',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'status' }
        },
        startTime: {
          label: '创建开始时间',
          type: 'date-picker'
        },
        endTime: {
          label: '创建结束时间',
          type: 'date-picker'
        },
      }
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('productLabel_selectListForPage'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      if (filters.startTime && filters.endTime) {
        let status = new Date((filters.startTime)).getTime() > new Date((filters.endTime)).getTime() ? false : true
        if (!status) {
          return message.warning('结束时间不能小于开始时间')
        }
      }
      state.lastFilter = filters
      select()
    }
    const select = function () {
      state.loading = true
      run(
        {
          data: state.lastFilter,
          pagination: {
            current: 1,
            pageSize: 10
          },
        }
      )
      state.loading = false
    }
    //编辑
    const upUser = function (oldData, status) {
      state.status = status
      request(getRequestURI('productLabel_selectListForPageOne'), { data: oldData }).then(res => {
        if (res.code === 1) {
          res.result[0].startState += ''
          userDrawerRef.value.showDrawer(res.result[0], state.status)
        }
      })
    }
    const startModal = function () {
      if (!state.delQuery.length) return message['warning']('请选择启用的标签')
      const list = state.delQuery.filter(item => item.startState == 1)
      if(list && list.length) return message['warning']('选择的标签已处于启用状态,请重新选择')

      state.delQuery.forEach(item => {
        item.startState = 1
        item.startStateName = '启用'
      })
      request(getRequestURI('productLabel_update'), { data: state.delQuery }).then(res => {
        if (res.code === 1) {
          message['success'](res.message)
          select()
        } else {
          message['error'](res.message)
        }
      })
    }
    const endModal = function () {
      if (state.delQuery.length == 0) return message['warning']('请选择禁用的标签')
      const list = state.delQuery.filter(item => item.startState == 2)
      if(list && list.length) return message['warning']('选择的标签已处于禁用状态,请重新选择')
      state.delQuery.forEach(item => {
        item.startState = 2
        item.startStateName = '禁用'
      })
      request(getRequestURI('productLabel_update'), { data: state.delQuery }).then(res => {
        if (res.code === 1) {
          message['success'](res.message)
          select()
        } else {
          message['error'](res.message)
        }
      })
    }
    const delModal = function () {
      if (state.delQuery.length == 0) {
        message['warning']('请选择数据')
      } else {
        for (let item of state.delQuery) {
          if (item.labelLevel === 1) {
            message.warning('请不要删除一级标签')
            return
          }
        }
        Modal.confirm({
          title: '确认删除吗?',
          async onOk () {
            let keys = state.selectedRowKeys
            if (keys.length === 0) {
              message.warning('请选择数据')
              return
            }
            visible.value = true
            const uri = getRequestURI('productLabel_delete')
            request(uri, { data: state.delQuery }).then(res => {
              console.debug(res)
              if (res.code == 1) {
                message['success'](res.message)
                state.delQuery.splice(0)
                select()
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel () {
            // state.delQuery.splice(0)
          }
        })
      }
    }
    // const addUser = function (status) {
    //   state.status = status
    //   userDrawerRef.value.showDrawer({},state.status)
    // }
    const addUserSon = function (record, status) {
      console.debug(record)
      state.status = status
      let oldDate = {}
      oldDate.superiorLabel = record.labelName
      oldDate.labelId = record.id
      oldDate.labelLevel = record.labelLevel
      console.debug(oldDate)
      userDrawerRef.value.showDrawer(oldDate, state.status)
    }
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      state.delQuery.splice(0)
      state.selectedRowKeys = selectedRowKeys
      state.delQuery = selectedRow
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const visible = ref(false)
    return {
      ...toRefs(state),
      userDrawerRef,
      handleSearch,
      startModal,
      run,
      loading,
      current,
      pageSize,
      total,
      dataSource,
      onSelectChange,
      endModal,
      select,
      addUserSon,
      pagination,
      handleTableChange,
      delModal,
      upUser,
      // addUser
    }
  }
})
</script>

<style scoped>
</style>
