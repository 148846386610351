<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="调用方名称:" name="insId"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-select
              show-search
              allow-clear
              :filter-option="filterOption" v-model:value="formData.insId" :options="listData.qvdao" placeholder="请选择调用方名称" @change="qvdaoChanges($event)">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="调用方接口名称:" name="ipId"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-select
              v-model:value="formData.ipId"
              show-search
              allow-clear
              :filter-option="filterOption"
              :options="listData.ipId"
              placeholder="请选择">
              <!-- <a-select-option v-for="item in listData.ipId" :key="item.value" :value="item.value" clearable>
                {{ item.label }}
              </a-select-option> -->
            </a-select>
          </a-form-item>
        </a-col>
<!--        <a-col span="12">-->
<!--          <a-form-item label="接口名称:" name="portId"-->
<!--                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">-->
<!--            <a-select v-model:value="formData.portId" placeholder="请选择">-->
<!--              <a-select-option v-for="item in listData.portId" :key="item.id" :value="item.id" clearable>-->
<!--                {{ item.name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->
<!--        </a-col>-->
        <a-col span="12">
          <a-form-item label="参数类型:" name="type"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-select v-model:value="formData.type" placeholder="请选择" :options="typeList">

            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="16">
          <a-form-item label="编辑器类型:">
            <a-radio-group v-model:value="language" @change="changeEditor">
              <a-radio value="xml">xml</a-radio>
              <a-radio value="json">json</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
        <a-col span="16">
          <a-form-item label="数据体:" name="content" :rules="[{required: true,message: 'sss'}]">
<!--            <a-textarea v-model:value="formData.content" style="height: 300px" :rows="4"/>-->
<!--            <JsonXmlEditor v-if="language === 'xml'"></JsonXmlEditor>-->
            <JsonXmlEditor ref="mEditorTwo" v-if="languageFlag" class="script_editor" sonId="editorContainerecpa" name="editChanneParmsAdmin" :language="language" :content="formData.content"
                            @scriptChange="scriptChange" @clearRunStr="clearRunStr"></JsonXmlEditor>
          </a-form-item>
        </a-col>
        </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, onBeforeMount, reactive, toRefs, ref, nextTick} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import {getDictionaryData, getExtendUri} from '../../../utils/request_extend'
import JsonXmlEditor from '../../../components/extended/JsonXmlEditor'
// import {message} from 'ant-design-vue'
export default defineComponent({
  components: {JsonXmlEditor},
  props: {
    status: {
      type: String,
      default() {
        return ''
      }
    },
  },
  setup(props, {expose}) {
    const typeList = ref([])
    const mEditorTwo = ref(null)
    const state = reactive({
      language: 'xml',
      languageFlag: true,
      ipIds: '',//调用方名称
      portIds: '',//接口名称
      visible: false,
      dataInterface: [],
      type: [],

    })
    onBeforeMount(()=>{
      getDictionaryData(typeList, 'paramterType')
      console.log(typeList)
    })
    const formData = reactive({
      ipId:undefined,//调用方名称
      portId:undefined,//接口名称
      insId: undefined,
      type:'1',//参数类型,
      content:''//数据体
    })
    const listData = reactive({
      insId: [],
      ipId: [],
      portId: [],
      qvdao: []
    })
    //获取接口名称
    const jiekoumingcheng =()=>{
      let uri = getRequestURI('ExcPort_selectName')
      request(uri, {}).then(res => {
        console.log(res.result)
        if(res.code == 1) {
          listData.portId = res.result
        }
      })
    }
    const qvdaoChanges = function(value) {
      listData.ipId = []
      formData.ipId = ''
      state.dataInterface.forEach(res => {
        if (res.insId === value) {
          res.children.forEach(item => {
            let obj = {}
            obj.value = item.ipId
            obj.label = item.name
            listData.ipId.push(obj)
          })
        }
      })
    }
    //获取调用方名称
    const qudaomingcheng =()=>{
      let uri = getRequestURI('ExcInsurer_selectTree')
      request(uri, {}).then(res => {
        if(res.code == 1) {
          listData.insId=res.result
        }
      })
    }
    const onClose = () => {
      state.visible = false
      formData.type='1'
      formData.portId=undefined
      formData.content=''
      formData.insId= undefined
      formData.ipId=undefined
      state.language='xml'
      mEditorTwo.value.restValue()
    }
    const onSave = function() {
      if(!formData.ipId){
        message['warning']('调用方接口名称不能为空')
        return
      }
      if(!formData.type){
        message['warning']('参数类型不能为空')
        return
      }
      if(!formData.insId){
        message['warning']('调用方名称不能为空')
        return
      }
      if(getRunStr().runStr==='\n'){
        message['warning']('数据体不能为空')
        return
      }

      // let reg1Array = formData.content
      // if(reg1Array.length>2000){
      //   message['warning']('数据体输入长度不能超过2000')
      // }
      // delete formData.qvdao
      let obj = {
        data:formData
      }
      let uri = getRequestURI('ExcInsurerConfig_importPc')
      request(uri, obj).then(data => {
        if (data.code == 1) {
          onClose()
          message['success']('提交成功')
        }else {
          message.warning(data.message)
        }
      })
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const inNew = computed(() => {
        return '调用方接口参数增加'
    })
    const showDrawer = (oldData = {}) => {
      state.visible = true
      // state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      // console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      jiekoumingcheng()
      qudaomingcheng()
    }
    const init = function() {
        request(getExtendUri('dictionary_query_byType'), {data: {types:['paramterContentType']}}).then(res => {
          state.outTypeList = res.result.paramterContentType
          state.intoTypeList = res.result.paramterContentType
          console.log(res)
        })
      request(getRequestURI('select_treeLeft'),{}).then(res => {
        state.dataInterface = res.result
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.insId
          obj.label = item.insName
          listData.qvdao.push(obj)
        })
      })
    }
    init()
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    const scriptChange = (param) => {
      console.debug('scriptChange', param)
      // jsonxml.scriptText = param.originalStr
      let a = param.originalStrArray
      let buildStr = ''
      for (let i = 0; i < a.length; i++) {
        let text = a[i]
        buildStr += text + '\n'
      }
      formData.content = buildStr
    }

    const clearRunStr = () => {
      formData.content = ''
    }
    const getRunStr = () => {
      mEditorTwo.value.getRunScript()
      return {
        runStr: formData.content,
        originalStr: formData.content
      }
    }
    const changeEditor = (val) => {
      state.language = val.target.value
      state.languageFlag = false,
        nextTick(()=> {
        state.languageFlag = true
      })
      mEditorTwo.value.restValue()
    }
    expose({
      showDrawer
    })
    return {
      filterOption,
      ...toRefs(state),
      formData,
      listData,
      onClose,
      inNew,
      onSave,
      jiekoumingcheng,
      qudaomingcheng,
      qvdaoChanges,
      typeList,
      scriptChange,
      clearRunStr,
      getRunStr,
      mEditorTwo,
      changeEditor
    }
  }
})
</script>

<style scoped>
.script_editor {
  height: 400px;
  width: 100%;
}
</style>
