<template>
  <a-drawer
    :title="inNew"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
  <div style="margin-bottom: 5px;">
    <a-button danger @click="delAll">
      <template #icon>
        <DeleteOutlined/>
      </template>
      批量删除
    </a-button>&nbsp;&nbsp;
    <a-button default @click="addLabel">
      <template #icon>
        <FileAddOutlined/>
      </template>
      添加标签
    </a-button>
  </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      style="margin-top: 10px"
      :row-key="(record) => record.id"
      :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"
    >
    </a-table>
    <!-- 选择标签的弹框 -->
    <a-modal v-model:visible="visible1" @cancel="close" width="600px" title="选择标签" @ok="handleOk">
      <div class="modal-box">
        <div style="width:100%">
          <div class="left">
            <a-tree
              v-if="tag_Data.length > 0"
              v-model:selectedKeys="selectedKeys"
              :tree-data="tag_Data"
              :default-expand-all="true"
              @select="onSelectTree"
              :destroyOnClose="true"
              zIndex
            >
            </a-tree>
          </div>
          <div class="right">
            <p>已选标签</p>
            <a-tag closable @close="delTag(st)" v-for="st in selectedTags" :key="st.id">{{st.value}}</a-tag>
          </div>
        </div>
      </div>
    </a-modal>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {getRequestURI, request} from '../../../utils/request'

export default defineComponent({
  setup() {
    const state = reactive({
      dataSource: [],
      loading: false,
      visible: false,
      productInfoId: '',
      visible1: false,
      inNew: '产品和标签关联',
      selectedKeys:[],
      selectedRowKeys: [],
      treeList:[], // 用于接收选择标签的id
      selectedTags: [],
      selectOkTags: [],
      tag_Data: [],
      columns: [
        {
          title: '标签名称',
          width: 100,
          fixed: 'left',
          key: 'labelName',
          dataIndex: 'labelName',
        },
        {
          title: '父级名称',
          width: 100,
          fixed: 'left',
          key: 'supLabelName',
          dataIndex: 'supLabelName',
        },
        {
          title: '排序',
          width: 100,
          fixed: 'left',
          key: 'sort',
          dataIndex: 'sort',
        },
      ],
    })
    const showDrawer = function(oldData = {}) {
      console.debug(oldData)
      state.visible = true
      console.debug(oldData)
      state.productInfoId = oldData.id
      select(state.productInfoId)
    }
    const onClose = function() {
      state.visible = false
    }
    const addLabel = function() {
      select(state.productInfoId)
      state.visible1 = true
      request(getRequestURI('productLabel_selectTreeList2'),{
        data:{
          startState: '1',
          labelName:'筛选标签'
        },
        pagination:{current: 1,pageSize: 10}
      }).then(res => {
        if(res.code === 1){
          state.tag_Data = getSelectTag(res.result)
        }
      })
    }
    const getSelectTag = (result) =>{
      console.debug(result)
      if(result && result.length > 0){
        let arr = []
        result.forEach(item => {
          let node = {
            id: item.id,
            key: item.id,
            sort: item.sort,
            superiorLabel: item.superiorLabel,
            selectable: item.children !== null && item.children.length > 0 ? false : true,
            title: item.labelName,
            children: getSelectTag(item.children)
          }
          arr.push(node)
        })
        return arr
      }else{
        return []
      }
    }
    const onSelectChange = function(keys) {
      state.selectedRowKeys = keys
      console.debug(state.selectedRowKeys)
    }
    // 已选便签的删除
    const delTag = (tagData) =>{
      let index = state.selectedTags.indexOf(tagData)
      state.selectedTags.splice(index,1)
    }

    const delAll = function() {
      if (state.selectedRowKeys.length == 0) {
        message['warning']('请选择数据')
      } else {
        Modal.confirm({
          title: '确认删除吗?',
          async onOk() {
            const uri = getRequestURI('productInfoLabel1_delete')
            request(uri,{data: {ids: state.selectedRowKeys}}).then(res => {
              console.debug(res)
              if(res.code == 1) {
                console.debug(res)
                message['success'](res.message)
                state.selectedRowKeys = []
                select(state.productInfoId)
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel() {
            // state.querydel.splice(0)
          }
        })
      }
    }
    // 选择标签弹框的数据的回调
    const onSelectTree = (id,content) => {
      console.debug(state.selectedTags)
      // if(state.selectedTags.length > 2) return message.warning('最多可选3条数据')
      if(content.selectedNodes.length > 0){
        let node = {
          id: content.selectedNodes[0].key,
          value: content.selectedNodes[0].title,
          sort: content.selectedNodes[0].sort,
          superiorLabel: content.selectedNodes[0].superiorLabel
        }
        if(state.selectedTags.length > 0){
          state.selectedTags.push(node)
          var result = []
          var obj = {}
          for (var i = 0; i < state.selectedTags.length; i++) {
            if (!obj[state.selectedTags[i].id]) {
              result.push(state.selectedTags[i])
              obj[state.selectedTags[i].id] = true
            }else{
              message.warning('请勿选择相同数据')
            }
          }
          state.selectedTags = result
        }else{
          state.selectedTags.push(node)
        }
      }
    }
    // 取消
    const close = () => {
      state.selectedTags = [...state.selectOkTags]
    }
    // 选择标签的确认回调
    const handleOk =() => {
      state.visible1 = false
      // 用于在外面展示 已选标签
      state.selectOkTags = [...state.selectedTags]
      console.debug(state.selectOkTags)
      state.selectedTags.forEach(item => {
        state.treeList.push(item.id)
      })
      onSave()
    }
    const select = function(value) {
      state.selectedTags = []
      request(getRequestURI('productInfoLabel1_selectByProductId'), {data: {id: value}}).then(res=> {
        state.dataSource = res.result
        res.result.forEach(item => {
          let obj = {}
          obj.id = item.productLabelId
          obj.value = item.labelName
          obj.sort = item.sort
          obj.superiorLabel = item.supLabelName
          state.selectedTags.push(obj)
        })
        console.debug(res)
      })
    }
    const onSave = function() {
      console.debug(state.productInfoId)
      let i = 1
      console.debug('给我下完了', state.selectedTags)
      let obj = []
      state.selectedTags.forEach(item => {
        let obj1 = {}
        obj1.labelId = item.id
        obj1.sort = i++
        obj.push(obj1)
      })
     if (obj.length !== 0) {
       request(getRequestURI('productInfoLabel1_insert'), {data: {productInfoId: state.productInfoId,productLabelList: obj}}).then(res=> {
         if (res.code === 1) {
           message.success('新增成功')
           select(state.productInfoId)
         } else {
           message.warning(res.result)
         }
       })
     } else {
       message.warning('请选则新增的标签')
     }

    }
    return {
     ...toRefs(state),
      onClose,
      delAll,
      addLabel,
      showDrawer,
      close,
      handleOk,
      onSelectTree,
      delTag,
      getSelectTag,
      onSave,
      select,
      onSelectChange
    }
  }
})
</script>

<style scoped>
.select-label {
  margin-left: 30px;
}
.butt{
  width: 600px;
  margin-left: 30px;
}
.butt .a-butt {
  margin-right: 15px;
}
.a-check {
  margin-top: 20px;
  margin-left: 30px;
}
.modal-box {
  height: 350px;
}
.modal-box .left {
  height: 350px;
  width: 45%;
  overflow-y: auto;
  float: left;
  font-size: 14px;
}
.modal-box .right {
  float: right;
  width: 300px;
  height: 200px;
  padding-left: 5px;
  border-left: 1px solid rgb(222, 221, 221);
  font-size: 16px;
  font-weight: 700;
}
.a-pag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.a-check {
  margin-top: 20px;
  margin-left: 30px;
}
</style>
