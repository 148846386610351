<template>
  <div>
    <a-cascader
      v-model:value="interfaceValue"
      :options="options"
      :load-data="loadData"
      :placeholder="hint"
      :show-search="{ filter }"
      change-on-select
      :display-render="renderLabel"
      @change="changeValue"

    />
  </div>
</template>

<script>
import {onBeforeMount, reactive, toRefs} from 'vue'
import {request} from '../../../../utils/request'
import {getExtendUri} from '../../../../utils/request_extend'
import {flattenTree} from '../../../../utils/ArrayUtils'

export default {
  name: 'InterfaceIdentifierCascader',
  props: {
    valueStr: {
      type: String,
      default: () => ''
    }
  },
  // emits: [ 'update:valueStr', 'update:modelValue'],
  setup(props, {emit}) {
    console.log('默认数据：', props.valueStr)
    const getProps = (str) => {
      if (str) {
        const regex = /^([a-zA-Z0-9])+-[a-zA-Z0-9]+(-[a-zA-Z0-9]+)?$/
        const isValid = regex.test(str)
        if (isValid) {
          const strArr = str.split('-')
          if (strArr.length > 2) {
            return [strArr[0], strArr[1], strArr[2]]
          } else {
            return [strArr[0], null, strArr[1]]
          }
        } else {
          return [str]
        }
        // return ['2064',null,'visitNoticeCallback']
      }
      return []
    }
    const state = reactive({
      hint: '请设置接口标识',
      options: [],
      // 保司编码
      supplierRequestCode: 'querySupplier',
      supplierCodeList: [],
      // 产品编码
      riskCodeRequestCode: 'productInfoSelect',
      riskCodeList: [],
      // 方法类型
      methodTypeRequestCode: 'getProductInterface',
      methodTypeList: [],
      interfaceValueStr: props.valueStr,
      interfaceValue: getProps(props.valueStr),
      colNum: 3
    })
    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    const getSupplierList = async () => {
      await request(getExtendUri('querySupplier'), {
        data: {}
      }).then(res => {
        if (res.code === 1) {
          state.supplierCodeList = newList(res.result)
          state.options = state.supplierCodeList
        }
      })
    }
    const getMethodTypeList = async () => {
      await request(getExtendUri('getProductInterface'), {
        data: {}
      }).then(res => {
        if (res.code === 1) {
          state.methodTypeList = newList3(res.result)
        }
      })
    }
    // const getRequestList = (methodName, list, query, method) => {
    //   if (methodName) {
    //     let param = {
    //       data:{}
    //     }
    //     if (query) {
    //       param.data = query
    //     }
    //     list = []
    //     request(getExtendUri(methodName), param).then(res => {
    //       if (res.code === 1) {
    //         if (method) {
    //           list = method(res.result)
    //         } else {
    //           list = res.result
    //         }
    //       }
    //     })
    //   }
    // }
    const loadData = selectedOptions => {
      console.debug('执行？', selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // load options lazily
      if (targetOption.p) {
        request(getExtendUri(state.riskCodeRequestCode), {
          data: {
            supplierId: targetOption.id
          }
        }).then(res => {
          if (res.code === 1) {
            targetOption.loading = false
            state.riskCodeList = newList2(res.result)
            targetOption.children = state.riskCodeList
            state.options = [...state.options]
          }
        })
      } else {
        targetOption.loading = false
        targetOption.children = state.methodTypeList
        state.options = [...state.options]
      }
    }

    const renderLabel = (obj) => {
      console.log('renderLabel:', obj.selectedOptions)
      if (obj.selectedOptions && obj.selectedOptions[0] && obj.selectedOptions[0].children && obj.selectedOptions.length === state.colNum) {
        const result = obj.selectedOptions.map((item) => item.value).join('-')
        state.interfaceValueStr = result.replace(/--/g, '-')
      }
      return state.interfaceValueStr
    }
    const newList = (list) => {
      const keyMap = {
        'id': 'id',
        'supCode': 'value',
        'supName': 'label',
        'parentId': 'p'
      }
      let a = flattenTree(list, keyMap, {isLeaf: false})
      return a
    }

    const newList2 = (list) => {
      const keyMap = {
        'id': 'id',
        'productCode': 'value',
        'productName': 'label',
      }
      let p = {
        id: 'zzwderhede',
        label: '不选择',
        code: '',
        isLeaf: false,
      }
      let a = flattenTree(list, keyMap, {isLeaf: false})
      a.unshift(p)
      return a
    }

    const newList3 = (list) => {
      const keyMap = {
        'id': 'id',
        'code': 'value',
        'name': 'label'
      }
      let a = flattenTree(list, keyMap)
      return a
    }

    const changeValue = (value) => {
      console.log('changeValue', value)
      if (value && value.length === state.colNum) {
        const x = value.map((item) => item).join('-').replace(/--/g, '-')
        // console.log('----', value)
        // console.log('----', x)
        state.interfaceValueStr = x
        emit('changeValueToMethod', state.interfaceValueStr)
      }
      if (value === undefined) {
        // 清楚
        emit('changeValueToMethod', '')
      }
    }
    onBeforeMount(async () => {
      await getSupplierList()
      await getMethodTypeList()
      // http://developer.7ytech.com:8978/product/backend/product-service/product/supplier/common/querySupplier
      // 查询供应商接口
      // getRequestList(state.supplierRequestCode,state.supplierCodeList)
      //  查询产品编码接口
      //   http://developer.7ytech.com:8978/product/backend/product-service/product/productInfo/select
      //  入参
      //   {"data":{"supplierId":"95"}}
      //  方法类型
      //   http://developer.7ytech.com:8978/product/backend/trade-service/trade/process/getProductInterface
      //   getRequestList(state.methodTypeRequestCode,state.methodTypeList,null,newList3)
    })
    // watch(() => state.interfaceValueStr, (newValue) => {
    //   // emit('update:valueStr', newValue)
    //   // emit('update:modelValue', newValue)
    //   emit('changeValueToMethod', newValue)
    //
    //   // emit('changeValueToMethod', newValue)
    // })

    return {
      ...toRefs(state),
      loadData,
      renderLabel,
      changeValue,
      filter
    }
  }
}
</script>

<style scoped>

</style>
