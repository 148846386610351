<style scoped>
</style>

<template>
  <a-drawer
    v-if="refreshFlagRef"
    :title=panDuan
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item name="name"
                       :rules="[{max:20, message: '输入长度不能超过20' ,trigger: 'change'},
                         { required: true, message: '必填信息' ,trigger: 'change'}]">
            <template #label>
              <span>接口名称: </span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>保司名称+接口名称，如：京东安联核保接口</p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.name"  placeholder="请输入接口名称"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="method" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <!--            <interface-identifier-cascader  v-model:value-str="formData.method" />-->
            <template #label>
              <span>接口标识:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      规则一：供应商编码-接口类型，此规则适用于大部分的映射接口；
                      <br>规则二：供应商编码-产品编码-接口类型，此规则用于需要单独配置映射逻辑的产品，
                      <br>&emsp;&emsp;&emsp;&emsp;该规则的优先级更高，当产品找不到该标识的映射时，会寻找规则一的映射配置；
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <interface-identifier-cascader :value-str="formData.method" @changeValueToMethod="changeValueToMethod"/>
          </a-form-item>
        </a-col>
        <!--            <interface-identifier-cascader v-model="formData.method" :value-str="formData.method" />-->

        <a-col span="12">
          <a-form-item name="reqType"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <template #label>
              <span>接口类型:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      接口类型根据保司提供的接口对接方式去选择相应接口类型，当为回调接口时，固定选择 “FEIGN”，“FEIGN”为系统内部调用trade服务
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.reqType"  placeholder="请选择接口类型" :options="reqTypeList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="intoType"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <template #label>
              <span>入参类型:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      保司作为提供方时，入参类型按照保司要求进行配置，当为回调接口时，系统仅支持json类型，入参类型默认选择 “JSON”
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.intoType"  placeholder="请选择入参类型" :options="intoTypeList"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="outType"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
          >
            <template #label>
              <span>出参类型:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      出参类型默认和入参类型一致
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.outType"  placeholder="请选择接口类型" :options="outTypeList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="serverPath" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <template #label>
              <span>接口地址:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      1.保司作为提供方时，接口地址为保司提供的接口完整地址或路径，如：http://103.113.7.89/queryPolicy
                      <br>2.产品工厂作为提供方时，接口地址作为feign的请求地址，如：trade/callBack/signature
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.serverPath"  placeholder="请输入接口地址"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="wsdlNameSpace"
                       :rules="[{max:200, message: '输入长度不能超过100' ,trigger: 'change'},]">
            <template #label>
              <span>WSDL命名空间:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当接口类型选择“WS”时，此字段为必填
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.wsdlNameSpace"  placeholder="请输入WSDL命名空间"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="wsdlAction"
                       :rules="[{max:200, message: '输入长度不能超过200' ,trigger: 'change'}]">
            <template #label>
              <span>WSDL响应名:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当接口类型选择“WS”时，此字段为必填
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.wsdlAction"  placeholder="请输入WSDL响应名"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="wsdlMethod"
                       :rules="[{max:200, message: '输入长度不能超过200' ,trigger: 'change'}]">
            <template #label>
              <span>WSDL方法名:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当接口类型选择“WS”时，此字段为必填
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.wsdlMethod"  placeholder="请输入WSDL方法名"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="wsdlParam"
                       :rules="[{max:200, message: '输入长度不能超过200' ,trigger: 'change'}]">
            <template #label>
              <span>WSDL参数名:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当接口类型选择“WS”时，此字段为必填
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.wsdlParam"  placeholder="请输入WSDL参数名"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="wsdlResult"
                       :rules="[{max:200, message: '输入长度不能超过200' ,trigger: 'change'}]">
            <template #label>
              <span>WSDL返回参数:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当接口类型选择“WS”时，此字段为必填
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.wsdlResult"  placeholder="请输入WSDL返回参数"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否解密:" name="decrypt">
            <a-select v-model:value="formData.decrypt"  placeholder="请选择是否解密" :options="decryptList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="解密算法:" name="decryptType">
            <a-select v-model:value="formData.decryptType" placeholder="请选择解密算法" :options="encryptTypeList"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否加密:" name="encrypt">
            <a-select v-model:value="formData.encrypt"  placeholder="请选择是否加密" :options="encryptList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="加密算法:" name="encryptType">
            <a-select v-model:value="formData.encryptType" placeholder="请选择加密算法" :options="encryptTypeList"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="secret">
            <template #label>
              <span>秘钥:</span>
              <span>
                <a-popover placement="right">
                  <template #content>
                  <p>
                    当保司加解密需要秘钥字符串时，可用此字段，如果加解密秘钥不一致时，解密秘钥配置在“”供应商管理->参数配置”下，之后在适配器代码中使用
                  </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.secret" placeholder="请输入秘钥"></a-input>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button  type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>

import {computed, defineComponent, nextTick, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import {getExtendUri} from '../../../utils/request_extend'
import InterfaceIdentifierCascader from './component/InterfaceIdentifierCascader'

export default defineComponent({
  components: {
    InterfaceIdentifierCascader
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    searchfFilters: { type: Object },
    handleSearch: {type: Function}
  },
  setup(props, {expose}, context) {
    const refreshFlagRef = ref(true)
    const formRef = ref()
    const formData = reactive({
      id: '',
      name:'',//接口名称
      method:'',//接口标识
      reqType:'1',//接口类型
      intoType:undefined,//入参类型
      outType:'1',//出参类型
      serverPath:'',//接口地址
      wsdlNameSpace:'',//WSDL命名空间
      wsdlAction:'',//WSDL响应名
      wsdlParam:'',//WSDL参数名
      wsdlMethod:'',//WSDL方法名
      wsdlResult:'',//WSDL返回参数
      decrypt:'N',//是否解密
      decryptType:undefined,//解密标记
      encrypt:'N',//是否加密
      encryptType:undefined,//加密标记
      secret: ''
    })

    const state = reactive({
      dataSourceList:[],
      encryptTypeList: [],
      // 接口类型
      reqTypeList: [],
      intoTypeList: [],
      outTypeList: [],
      decryptList: [],
      encryptList: [],
      visible: false,
      isNew: true,
    })

    const showDrawer = (oldData = {}) => {
      refreshFlagRef.value = false
      nextTick(() => {
        refreshFlagRef.value = true
      })
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      formData.reqType = formData.reqType + ''
      formData.intoType = !formData.intoType && formData.intoType != '0' ? undefined : formData.intoType + ''
      formData.outType = formData.outType + ''
      console.log(formData)
    }
    const onFinish = (values) => {
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }
    //title判断状态
    // eslint-disable-next-line vue/return-in-computed-property
    const panDuan = computed(() => {
      if (props.status == 'newAdd') {
        return '提供方接口增加'
      } else if (props.status == 'edit') {
        return '提供方接口修改'
      }
    })

    //关闭
    const onClose = () => {
      state.visible = false
      formRef.value.resetFields()
      formRef.value.clearValidate()
      formData.id= ''
        formData.name=''//接口名称
        formData.method=''//接口标识
        formData.reqType=1//接口类型
        formData.intoType=undefined//入参类型
        formData.outType=1//出参类型
        formData.serverPath=''//接口地址
        formData.wsdlNameSpace=''//WSDL命名空间
        formData.wsdlAction=''//WSDL响应名
        formData.wsdlParam=''//WSDL参数名
        formData.wsdlMethod=''//WSDL方法名
        formData.wsdlResult=''//WSDL返回参数
        formData.decrypt='N'//是否解密
        formData.decryptType = undefined//解密标记
        formData.encrypt='N'//是否加密
        formData.encryptType=undefined//加密标记
        formData.secret = ''
    }


    //保存/下一步
    const onSave = () => {
      //接口名称校验
      if( formData.name === '') {
        message['warning']('接口名称不能为空')
        return
      }
      let reg1=formData.name
      if (reg1.length>20){
        formData.name=''
        message['warning']('接口名称输入长度不能超过20')
        return
      }

      //接口标识校验
      if (formData.method === '' || formData.method === undefined) {
        message['warning']('接口标识不能为空')
        return
      }
      let reg2=formData.method
      if (reg2.length > 100) {
        formData.method = ''
        message['warning']('接口标识输入长度不能超过100')
        return
      }
      //接口类型校验
      if( formData.reqType === '') {
        message['warning']('模板类型不能为空')
        return
      }
      //入参类型校验
      // if( formData.intoType === undefined) {
      //   message['warning']('入参类型不能为空')
      //   return
      // }
      if( !formData.intoType) {
        message['warning']('入参类型不能为空')
        return
      }
      //出参类型校验
      // if( formData.outType === undefined) {
      //   message['warning']('出参类型不能为空')
      //   return
      // }
      if( !formData.outType) {
        message['warning']('出参类型不能为空')
        return
      }
      if(!formData.serverPath){
        message['warning']('接口地址不能为空')
        return
      }
      //WSDL命名空间校验
      let reg3=formData.wsdlNameSpace
      if (reg3.length>100){
        formData.wsdlNameSpace=''
        message['warning']('WSDL命名空间输入长度不能超过100')
        return
      }
      //WSDL响应名校验
      let reg4=formData.wsdlAction
      if (reg4.length>100){
        formData.wsdlAction=''
        message['warning']('WSDL响应名输入长度不能超过100')
        return
      }
      //WSDL方法名校验
      let reg5=formData.wsdlMethod
      if (reg5.length>100){
        formData.wsdlMethod=''
        message['warning']('WSDL方法名输入长度不能超过100')
        return
      }
      //WSDL参数名校验
      let reg6=formData.wsdlParam
      if (reg6.length>100){
        formData.wsdlParam=''
        message['warning']('WSDL参数名输入长度不能超过100')
        return
      }
      //WSDL返回参数校验
      let reg7=formData.wsdlResult
      if (reg7.length>100){
        formData.wsdlResult=''
        message['warning']('WSDL返参数输入长度不能超过100')
        return
      }
      //解密标记校验
      let reg8=formData.decryptType
      if (reg8 && reg8.length>100){
        formData.decryptType=''
        message['warning']('解密标记输入长度不能超过100')
        return
      }
      // //加密标记校验
      // let reg9=formData.encryptType
      // if (reg9.length>100){
      //   formData.encryptType=''
      //   message['warning']('加密标记输入长度不能超过100')
      //   return
      // }

      let obj = {
        data:formData,
        pagination:{
          current:1,
          pageSize:10,
          total:''
        },
        sorters:[{
          field:'',
          order:''
        }]
      }
      if(props.status == 'edit'){
        let uri = getRequestURI('ExcPort_update')
        request(uri, obj).then(res => {
          console.log(res, '56566')
          if(res.code == 1) {
            onClose()
            message['success']('修改成功')
            props.handleSearch(props.searchfFilters)
          }else{
            message.warning(res.message)
          }
        })
      }else  if(props.status == 'newAdd'){
        let uri = getRequestURI('excport_insert23')
        console.log(uri)
        request(uri, obj).then(data => {
          if (data.code == 1) {
            onClose()
            message['success']('保存成功')
            props.handleSearch({})
          }else{
            message.warning(data.message)
          }
        })
      }
      props.handleSearch(props.searchfFilters)
    }
   const init = function() {
     request(getExtendUri('dictionary_query_byType'), {data: {types:['paramterContentType']}}).then(res => {
         state.outTypeList = res.result.paramterContentType
         state.intoTypeList = res.result.paramterContentType
         console.log(res)
     })
     request(getExtendUri('dictionary_query_byType'), {data: {types:['yes_no']}}).then(res => {
       state.decryptList = res.result.yes_no
       state.encryptList = res.result.yes_no
       console.log(res)
     })
     request(getExtendUri('dictionary_query_byType'), {data: {types:['interfaceType']}}).then(res => {
       state.reqTypeList = res.result.interfaceType
       console.log(res)
     })
     request(getExtendUri('dictionary_query_byType'), {data: {types: ['encrypt_mark']}}).then(res => {
       state.encryptTypeList = res.result.encrypt_mark
       console.log(res)
     })
   }
    init()
    expose({
      showDrawer,
    })
    const changeValueToMethod = (str) => {
      console.log('changeValueToMethod执行???', str)
      formData.method = str
      // formRef.value.validateField('method')
    }
    return {
      formRef,
      formData,
      refreshFlagRef,
      init,
      ...toRefs(state),
      showDrawer,
      changeValueToMethod,
      onClose,
      onSave,
      getRequestURI,
      onFinish,
      panDuan,
    }
  },

})
</script>
