<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addProduct('add')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="delMultiple">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <a-table
    class="aTable"
    :columns="columns"
    :data-source="dataSource1 ? dataSource1['result'] : []"
    :scroll="{ x: 1500 }"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="modifyProduct(record, 'update')"
          >编辑</a
          >&nbsp;&nbsp;
          <a style="color: orange" v-if="record.off === 1" @click="modifyOff(record)">关闭</a
          >
          <a style="color: orange" v-if="record.off === 0" @click="modifyOpen(record)">打开</a
          >
        </div>
      </template>
    </template>
  </a-table>
  <editChannelAdmin
    ref="editChannel"
    :handleSearch="handleSearch"
    :status="status"
    :searchfFilters="searchfFilters"
  ></editChannelAdmin>
</template>

<script>
import SearchForm from '../../../components/extended/search_form'
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import editChannelAdmin from './editChannelAdmin'
import {message, Modal} from 'ant-design-vue'

export default defineComponent({
  components: {
    SearchForm,
    editChannelAdmin
  },
  setup() {
    const editChannel = ref()
    const state = reactive({
      lastFilter: {},
      selectedRowKeys: [],
      status: '',
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 80,
          fixed: 'left',
          align: 'center',
        },
        {
          title: '平台接口地址',
          width: 200,
          key: 'method',
          align: 'center',
          dataIndex: 'method',
        },
        {
          title: '调用方名称',
          width: 160,
          align: 'center',
          key: 'insurerName',
          dataIndex: 'insurerName',
        },
        {
          title: '调用方编码',
          width: 150,
          align: 'center',
          key: 'innerId',
          dataIndex: 'innerId',
        },
        {
          title: '接口名称',
          width: 160,
          align: 'center',
          key: 'portName',
          dataIndex: 'portName',
        },
        {
          title: '入参类型',
          width: 160,
          align: 'center',
          key: 'intoType',
          dataIndex: 'intoType',
          customRender: (text) => {
            if (text.record.intoType === 1) {
              return 'JSON'
            } else {
              return 'XML'
            }
          }
        },
        {
          title: '出参类型',
          width: 160,
          align: 'center',
          key: 'outType',
          dataIndex: 'outType',
          customRender: (text) => {
            if (text.record.outType === 1) {
              return 'JSON'
            } else {
              return 'XML'
            }
          }
        },
        {
          title: '调用方接口地址',
          width: 200,
          align: 'center',
          key: 'receiverPath',
          dataIndex: 'receiverPath',
        },
        {
          title: '状态',
          align: 'center',
          fixed: 'right',
          key: 'off',
          dataIndex: 'off',
          width: 80,
          customRender: (text) => {
            if (text.record.off === 1) {
              return '打开'
            } else {
              return '关闭'
            }
          }
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          align: 'center',
          width: 150,
        },
      ],
      condition: {
        innerId: '调用方编码',
        insurerName: '调用方名称',
        portName: '接口名称'
      }
    })
    const {
      data: dataSource1,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ExcInsurerPort_selectList'))
    console.debug('total', total)
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      console.debug('selectedRows changed: ', selectedRows)
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const modifyOpen = function (value) {
      if (value.off === 1) return message.warning('当前已经是打开状态,请勿重复点击')
      request(getRequestURI('ExcInsurerPort_updatePut'), { data: { id: value.id } }).then(res => {
        if (res.code === 1) {
          handleSearch(searchfFilters.value)
          message['success']('打开成功')
        } else {
          message['success']('打开失败')
        }
      })
    }
    const modifyOff = function (value) {
      if (value.off === 0) return message.warning('当前已经是关闭状态,请勿重复点击')
      request(getRequestURI('ExcInsurerPort_updateOff'), { data: { id: value.id } }).then(res => {
        if (res.code === 1) {
          handleSearch(searchfFilters.value)
          message['success']('关闭成功')
        } else {
          message['success']('关闭失败')
        }
      })
    }
    const delMultiple = function () {
      let list = state.selectedRowKeys
      if (list.length === 0) {
        message['warning']('请选择数据')
      } else {
        Modal.confirm({
          title: '确认删除吗?',
          async onOk () {
            // let keys = state.selectedRowKeys
            // if (keys.length === 0) {
            //   message.warning('请选择数据')
            //   return
            // }
            // visible.value = true
            const uri = getRequestURI('ExcInsurerPort_deleteList')
            request(uri, { data: { ids: list } }).then(res => {
              console.debug(res)
              if (res.code == 1) {
                console.debug(res)
                handleSearch({})
                message['success'](res.message)
              } else {
                message['error'](res.message)
              }
              state.selectedRowKeys.splice(0)
            })
          },
          onCancel () {
            // state.querydel.splice(0)
          }
        })
      }
    }
    //新增
    const addProduct = function (status) {
      state.status = status
      editChannel.value.showDrawer(status)
    }
    //编辑
    const modifyProduct = function (value, status) {
      console.debug(value)
      state.status = status
      // editChannel.value.showDrawer(value, status)
      // let obj={
      //   id:value.id
      // }
      const uri = getRequestURI('ExcInsurerPort_selectById')
      request(uri, { data: { id: value.id } }).then(res => {
        console.debug(res, '22222')
        editChannel.value.showDrawer(res.result, status)
      })
    }
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      console.debug('handleSearch', filters)
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    return {
      ...toRefs(state),
      dataSource1,
      loading,
      handleSearch,
      handleTableChange,
      onSelectChange,
      pagination,
      addProduct,
      editChannel,
      modifyProduct,
      modifyOff,
      modifyOpen,
      delMultiple,
      searchfFilters
    }
  }
})
</script>

<style scoped>
</style>
