<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @call="changes"
    :paramsData="condition.icId.data"
    @nameLinked="changes1"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('newAdd')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="deleteUser">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="modifyUser(record, 'edit')">编辑</a
          >&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>
  <productTemplateAdd
    ref="userDrawerRef"
    :handleSearch="handleSearch"
    :status="status"
    :searchfFilters="searchfFilters"
  ></productTemplateAdd>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form'
import productTemplateAdd from './codeValueCorrespondenceAdd.vue'
import { Modal, message } from 'ant-design-vue'
import {getDictionaryValue} from '../../../utils/request_extend'
export default defineComponent({
  components: {
    SearchForm,
    productTemplateAdd
  },
  setup () {
    onMounted(() => {
      duijiequdao()
      qudaojiekou()
    })
    const userDrawerRef = ref()
    const userDrawerRefOne = ref()
    const state = reactive({
      condition: {
        insId: {
          label: '调用方名称',
          type: 'select',
          default: '',
          data: []
        },
        portId: {
          label: '调用方接口名称',
          type: 'select',
          default: '',
          data: []
        },
        icId: {
          label: '调用方参数名称',
          type: 'tree-select1',
          value: 'id',
          default: '',
          data: []
        },
        value: {
          label: '映射码值:',
          type: 'input'
        },
        code: {
          label: '调用方参数对应码值',
          type: 'input'
        },
      },
      status: '',
      result: [],
      abc: [],
      lastFilter: {},
      query: {
        data: {
          riskCode: '',
          riskLengthSign: '',
          riskName: '',
          riskSign: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
        }
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '调用方名称',
          width: 140,
          align: 'center',
          key: 'insName',
          dataIndex: 'insName',
        },
        {
          title: '调用方接口名称',
          width: 140,
          align: 'center',
          key: 'portName',
          dataIndex: 'portName',
        },
        {
          title: '调用方参数名称',
          width: 140,
          align: 'center',
          key: 'icName',
          dataIndex: 'icName',
        },
        {
          title: '映射方式',
          width: 140,
          align: 'center',
          key: 'mappingType',
          dataIndex: 'mappingType',
          customRender: (text)=> getDictionaryValue('value_Mapping_type', text.value),
        },
        {
          title: '调用方参数对应码值',
          width: 140,
          align: 'center',
          key: 'code',
          dataIndex: 'code',
        },

        {
          title: '映射码值',
          width: 140,
          align: 'center',
          key: 'value',
          dataIndex: 'value',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 200,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      dataInterface: [],
      selectedRowKeys: [],
      data: [],// 删除data
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ExcCodeValue_selectList1'))

    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const changes = function (value) {
      state.condition.portId.data = []
      state.dataInterface.forEach(res => {
        console.debug(res)
        if (res.insId === value) {
          res.children.forEach(item => {
            let obj = {}
            obj.value = item.id
            obj.name = item.ipId
            obj.label = item.name
            state.condition.portId.data.push(obj)
          })
        }
        console.debug(res)
        console.debug(value)
      })
    }
    const changes1 = function (value) {
      state.condition.icId.data = []
      state.condition.portId.data.forEach(item => {
        if (item.value === value) {
          request(getRequestURI('ExcInsurerC_selectListForIpId'), { data: { ipId: item.name } }).then(res => {
            if (res.code === 1) {
              state.condition.icId.data = res.result
            }
          })
        }
      })

      // request(getRequestURI('ExcInsurerConfig_selectList'),{data: {ipId: value}}).then(res => {
      //   console.debug(res.result)
      // })
    }
    const handleTableChange = (pag, filters, sorter) => {
      console.debug('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }//页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      console.debug(filters)
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }

    //多选
    const onSelectChange = (selectedRowKeys) => {
      state.data.splice(0)
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      for (let selectedRowKeys of state.selectedRowKeys) {
        for (let result in state.result) {
          if (state.result[result].id == selectedRowKeys && state.data.includes(state.result[result]) == false) {
            state.data.push(state.result[result])
          }
        }
      }
      console.debug(state.data)
    }
    //获取调用方和调用方接口名称
    const duijiequdao = () => {
      request(getRequestURI('select_treeLeft'), {}).then(res => {
        state.dataInterface = res.result
        res.result.forEach(item => {
          if (item.children.length !== 0) {
            item.children.forEach(item1 => {
              let obj1 = {}
              obj1.value = item1.id
              obj1.label = item1.name
              state.condition.portId.data.push(obj1)
            })
          }
          let obj = {}
          obj.value = item.insId
          obj.label = item.insName
          state.condition.insId.data.push(obj)
        })
      })
    }
    //获取调用方参数名称
    const qudaojiekou = () => {
      request(getRequestURI('ExcInsurerC_selectListForIpId'), { data: {} }).then(res => {
        if (res.code === 1) {
          // res.result.forEach(item => {
          //   let obj = {}
          //   obj.label = item.co
          // })
          state.condition.icId.data = res.result

          console.debug(state.condition.icId.data)
        }
      })
      // state.condition.icId.data = []
      // request(getRequestURI('ExcInsurerC_selectListForIpId'),{data: {}}).then(res => {
      //   res.result.forEach(item => {
      //     let obj ={}
      //     obj.value = item.id
      //     obj.label = item.column
      //     state.condition.icId.data.push(obj)
      //   })
      //   console.debug(res)
      // })
      // request(getRequestURI('ExcInsurerPort_selectPortList'), {}, true, 'post').then(
      //   (result) => {
      //     const arr = []
      //     const res = result.result
      //     for (let i = 0; i < res.length; i++) {
      //       arr.push({label: res[i].name, value: res[i].id})
      //     }
      //     state.condition.portId.data = arr
      //   }
      // )
    }


    //新增
    const addUser = function (status) {
      console.debug(status)
      state.status = status
      userDrawerRef.value.showDrawer(status)
    }
    //编辑
    const modifyUser = function (record, status) {
      state.status = status
      userDrawerRef.value.showDrawer(status, record)
    }
    //批量删除
    const deleteUser = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk () {
          let uri = getRequestURI('ExcCodeValue_delete1')
          request(uri, {
            data: {
              ids: keys
            }
          }).then(
            (result) => {
              if (result.code === 1) {
                message['success']('删除成功')
                handleSearch({})
              } else {
                message['warning'](result.message)
              }
            }
          )
        },
        onCancel () {
        }
      })
    }
    onMounted(() => {

    })
    return {
      ...toRefs(state),
      handleSearch,
      addUser,
      userDrawerRef,
      userDrawerRefOne,
      deleteUser,
      modifyUser,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      current,
      pageSize,
      total,
      duijiequdao,
      qudaojiekou,
      changes,
      changes1,
      searchfFilters,
    }
  },
})
</script>

<style scoped>
</style>
