<style scoped>
</style>

<template>
  <a-drawer
    :title="isNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
      <a-row :gutter="10"> 
      <a-col span="12">
       <a-form-item label="产品编码" name="productCode" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-input v-model:value="formData.productCode"  :disabled="isccc"/>
          </a-form-item>
      </a-col>
      <a-col span="12">
       <a-form-item name="supplierName" label="供应商名称" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select  v-model:value="formData.supplierName"  :disabled="isccc"  >
        <a-select-option value="A">投保人节点</a-select-option>
        <a-select-option value="B">被保人节点</a-select-option>
        <a-select-option value="C">产品节点</a-select-option>
        <a-select-option value="S">受益人节点</a-select-option>
        <a-select-option value="Y">银行卡节点</a-select-option>
      </a-select>
       </a-form-item>
      </a-col>  
      <a-col span="12">
       <a-form-item name="productName" label="产品名称" :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
       <a-select  v-model:value="formData.productName"  :disabled="isccc"  >
        <a-select-option value="A">投保人节点</a-select-option>
        <a-select-option value="B">被保人节点</a-select-option>
        <a-select-option value="C">产品节点</a-select-option>
        <a-select-option value="S">受益人节点</a-select-option>
        <a-select-option value="Y">银行卡节点</a-select-option>
      </a-select>
       </a-form-item>
      </a-col> 
       <a-col span="24">
        <a-button type="primary" html-type="submit" style="margin-left:1020px"  @click="addlist" v-if="lookbtn">添加</a-button>
          </a-col> 
     </a-row>
    </a-form>
    <div >
        <template v-for="(item,index) in list" :key="index">
  <div v-if="item.dataType==='01'">
        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" :name="elementValue" :label="item.elementName">
                   <a-input v-model:value="item.elementValue" :disabled="checkinput"/>
                  </a-form-item>
                    <a-button style="margin-left:1020px" @click="del(item,index)" v-if="lookbtn">删除</a-button>
   </div>
   <div v-else-if="item.dataType==='02'" >
        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" :name="elementValue" :label="item.elementName">
                      <a-textarea v-model:value="item.elementValue" :disabled="checkinput"/>
                    </a-form-item>
                    <a-button style="margin-left:1020px" @click="del(item,index)" v-if="lookbtn">删除</a-button>
   </div>
  <div  v-else>
    <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" :name="dictionaryType" :label="item.elementName" >
                        <a-select v-model:value="item.dataDictionaryType" :disabled="checkinput">
                            <!-- <a-select-option value="11">11</a-select-option>
                            <a-select-option value="22">22</a-select-option> -->
                        </a-select>
                      </a-form-item>
                     <a-button style="margin-left:1020px" @click="del(item,index)" v-if="lookbtn">删除</a-button>
   </div>
        </template>
    </div>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <CloseOutlined/>关闭
        </a-button>
        <a-button type="primary" @click="onSave" v-if="lookbtn">
          <SaveOutlined/>保存
        </a-button>
         <a-button type="primary" @click="onSavenode" v-if="lookbtn">
          <SaveOutlined/>另存为模板
        </a-button>
      </a-space>
    </template>
  </a-drawer>
  <add-table ref="userDrawerRef" :elementList="elementList" @onSave='saveTemplate'></add-table>
  <a-modal v-model:visible="templateShow" title="模板名称" @ok="handleOk">
   <div style="display:flex">
     <p>模板名称: </p>
     <a-input v-model:value="templateName" placeholder="请输入" />
    </div>   
    </a-modal>
</template>

<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref,onMounted} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
// import {now} from '../../../utils/date_util'
import {message,Modal} from 'ant-design-vue'
import addTable from './addTable'
export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined,addTable
  },

  setup(props, {expose}) {
    const userDrawerRef = ref()
    const formRef = ref()
    const data = reactive({
              createTime:'',
              createUser:'',
              dataDictionaryType:'',
              dataType:'',
              elementName:'',
              id: 0,
              isDel: 0,
              productBright:'',
              productCode:'',
              productInfoId: 0,
              showStatus: 0,
              sort: 0,
              supCode:'',
              updateTime:'',
              updateUser:'',
              pagination: {
                current: 1,
                pageSize: 10,
                total: 0
              },
              sorters: [
                {
                  field:'' ,
                  order:''
                }
              ]
        })
    const state = reactive({
      lookbtn:true,
      checkinput:false,
      isShow:2,
      visible: false,
      templateShow: false,
      templateName: null,
      isNew: true,
      formData: {
      productCode:'',
      supplierName:'',
      productName:'',
      // shoutNode:'',
      // shoutNodes:'',
      // dictionaryType:''
      },
      list:[]
    })
// 抽屉初始化
    const saveTemplate = (res)=>{
      console.debug(123456)
      state.list = [...state.list,...res]
    }
    const elementList=()=>{
      request(getRequestURI('pppq-kzys'), {data}).then(res => {
            console.debug(6666, res)
            res.result.forEach(item=>{
              if (item.elementName == '1') {
                item.elementName = '产品亮点'
              } else if (item.elementName == '2') {
                item.elementName = '产品描述'
              } else if (item.elementName == '3') {
                item.elementName = '产品理赔流程说明'
              }else if (item.elementName == '4') {
                item.elementName = '产品保全流程说明'
              }else if (item.elementName == '5') {
                item.elementName = '产品领取方式'
              }else if (item.elementName == '6') {
                item.elementName = '产品现金价值'
              }else if (item.elementName == '7') {
                item.elementName = '常见问题'
              }else if (item.elementName == '8') {
                item.elementName = '理赔案例'
              }else if (item.elementName == '9') {
                item.elementName = '产品保障责任'
              }else if (item.elementName == '10') {
                item.elementName = '产品保障计划'
              }else if (item.elementName == '11') {
                item.elementName = '供应商介绍'
              }else if (item.elementName == '12') {
                item.elementName = '利益演示'
              }else if (item.elementName == '13') {
                item.elementName = '投保提示'
              }
            })
            state.list = res.result
                state.list.forEach(item=>{
        if(item.dataDictionaryType=='01'){
          item.dataDictionaryType='短文本'
        }else if(item.dataDictionaryType=='02'){
            item.dataDictionaryType='富文本'
          }else if(item.dataDictionaryType=='03'){
            item.dataDictionaryType='表格'
          }else if(item.dataDictionaryType=='04'){
            item.dataDictionaryType='数据字典'
          }
      })
          })
        }
    onMounted(() => {
          elementList()
        })
      const isccc = ref(false)
      // 数据回显
      const showDrawer = (oldData,num) => {
      console.debug(data.productInfoId)
      data.productInfoId=oldData.id
       elementList()
      isccc.value=true
      state.formData = {
        id: '',
        productCode:'',
        supplierName:'',
        productName:''
      }
       if(num ==1){
         state.lookbtn=false
        state.isNew='查看扩展要素'
        state.checkinput=true
     }else{
         state.isNew='编辑扩展要素'
          state.checkinput=false
           state.lookbtn=true
     }



      if (oldData) {
        Object.keys(oldData).forEach(key => state.formData[key] = oldData[key])
      }
      state.visible = true
      // 等你添加的保存接口测试完以后解开这个注释,把上面那个钩子里的注释掉
      // elementList()
    }
    // 添加
    const addlist =()=>{
      console.debug(data.productInfoId)
       userDrawerRef.value.showDrawer(data.productInfoId)
    }
    // 删除
    const del =(item,index)=>{
      console.debug(item,index,65656565)
      Modal.confirm({
              title: '确认删除吗?',
              async onOk() {
                // visible.value = true
                request(getRequestURI('pppr-delelet'), {
                  data:{
                    id:item.id,
                    dataType:item.dataType,
                    elementName:item.elementName, 
                    sort:item.sort,
                    showStatus:item.showStatus, 
                  }
                }, true, 'post').then(
                  () => {
                    message.success('删除成功')
                      elementList()
                    console.debug()
                  }
                ).catch(e => {
                  message.error(e)
                })
              },
              onCancel() {
              }
            })

    }
    // 关闭弹窗
    const onClose = () => {
      
      state.visible = false
      onFormClean()
    }
    const onFormClean = () => {
      formRef.value.resetFields()
    }
    // 保存
    const onSave = async () => {
       state.list.forEach(item=>{
       item.id=null
       item.createTime=null
       item.updateTime=null
      })
      console.debug(state.list)
       request(getRequestURI('pppb-addorr'),  {
                  data:state.list
                  
                }, true, 'post').then(res => {
            console.debug(res,6666)
          message.success('保存成功')
          onClose()
          })
    }
    // 另存为模板
     const onSavenode = async () => {
       state.templateShow = true
    }
    const handleOk = ()=>{

      state.list.forEach(item=>{
       item.id=null
       item.createTime=null
       item.updateTime=null
      })
      request(getRequestURI('pppa-addnode'),  {
                  data:{
                    templateName:state.templateName,
                    productInfoSpreadList:state.list
                  }
                }, true, 'post').then(res => {
            console.debug(res,6666)
            state.templateShow = false
          message.success('保存成功')

          onClose()
          })
    } 
    onSavenode
    expose({
      showDrawer
    })
    return {
      // getDictionary,
      onSavenode,
      saveTemplate,
      del,
      // getElemengname,
      elementList,
      addlist,
      isccc,
      formRef,
      // formRules,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      handleOk,
      userDrawerRef
    }
  },

})
</script>
