<style scoped>
</style>

<template>
  <a-drawer
    :title=panDuan
    :width="'30%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item label="供应商名称:" name="supName"  :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-tree-select v-model:value="formData.supName" placeholder="请选择供应商名称"
                            :tree-data="listData.gongyingshangmingcheng" :fieldNames="{children:'children', label:'supName', key:'id', value:'id'}">
            </a-tree-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="销售渠道:" name="distributionChannel"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData.distributionChannel" placeholder="请选择模板类型" clearable
                      :options="distributionChannelList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
        <a-form-item label="模板名称:" name="templateName"
                     :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
          <a-input v-model:value="formData.templateName"  placeholder="请输入模板名称"/>
        </a-form-item>
      </a-col>
        <a-col span="24">
          <a-form-item label="模板类型:" name="templateType"
                       :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
            <a-select v-model:value="formData.templateType" placeholder="请选择模板类型" clearable
                      :options="templateTypeList">
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onFormClean">
          <template #icon>
            <ReloadOutlined />
          </template>
          重置
        </a-button>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave" >
          <template #icon>
            <SaveOutlined/>
          </template>
          提交/下一步
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>

import {defineComponent, reactive, toRefs, ref, computed} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
import {message} from 'ant-design-vue'
export default defineComponent({
  components: {

  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      },
    },
    handleSearch: {type: Function}
  },
  setup(props, {expose}, context) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      distributionChannel:undefined,//销售渠道
      templateName:'',//模板名称
      supplierId:'',//供应商名称
      templateType:undefined,//模板类型
      supName:undefined

    })


    const state = reactive({
      dataSourceList:[],
      visible: false,
      isNew: true,
      distributionChannelList:[],//销售渠道
      templateTypeList:[]//模板类型
    })
    const listData = reactive({
      gongyingshangmingcheng:[]
    })


    const showDrawer = (oldData = {}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })


      init()
      qudao()
      leixing()
    }
    const onFinish = (values) => {
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }

    //关闭
    const onClose = () => {
      state.visible = false
      onFormClean()
    }
    //title判断状态
    // eslint-disable-next-line vue/return-in-computed-property
    const panDuan = computed(() => {
      if (props.status == 'newAdd') {
        return '增加'
      } else if (props.status == 'edit') {
        return '编辑'
      }
    })
    //重置
    const onFormClean = () => {
      formData.distributionChannel=undefined,//销售渠道
        formData.templateName='',//模板名称
        formData.supplierId='',//供应商名称
        formData.templateType=undefined//模板类型
      formData.supName=undefined
    }
    //获取供应商名称
    const init = function() {
      const uri = getRequestURI('supplier_querySupplier')
      request(uri, {data:{}}).then(res => {
        console.log(res)
        if (res.code == 1) {
          listData.gongyingshangmingcheng = res.result
        }
      })
    }
    //获取销售渠道
    const qudao = () => {
      let obj = {
        types:['channel_type'],
        ids:[]
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, {data: obj}).then(res => {
        console.log(res,'456')
        state.distributionChannelList = res.result.channel_type
      })
    }
    //获取模板类型
    const leixing = () => {
      let obj = {
        types:['template_type'],
        ids:[]
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, {data: obj}).then(res => {
        console.log(res,'456')
        state.templateTypeList = res.result.template_type
      })
    }

    //保存/下一步
    const onSave = () => {
      if( formData.templateName === '') {
        message['warning']('模板名称不能为空')
        return
      }
      if( formData.distributionChannel === '') {
        message['warning']('销售渠道不能为空')
        return
      }
      if( formData.supName === '') {
        message['warning']('供应商名称不能为空')
        return
      }
      if( formData.templateType === '') {
        message['warning']('模板类型不能为空')
        return
      }

      let obj = {
        data:formData
      }
      if(props.status == 'edit'){
        formData.supplierId=formData.id
        let uri = getRequestURI('productTemplate_update')
        request(uri, obj).then(res => {
          if(res.code == 1) {
            onClose()
            props.handleSearch({})
            message['success']('修改成功')
          }
        })
      }else  if(props.status == 'newAdd'){
        formData.supplierId=formData.supName
        let uri = getRequestURI('productTemplate_insert')
        request(uri, obj).then(data => {
          if (data.code == 1) {
            props.handleSearch({})
            onClose()
            message['success']('保存成功')
          }
        })
      }
    }
    expose({
      showDrawer
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawer,
      init,
      listData,
      qudao,
      leixing,
      panDuan
    }
  },

})
</script>
