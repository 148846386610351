<style scoped>
.ant-layout {
  background: #FFFFFF;
  border: 1px solid #e0e0e0;
}

.ant-layout-sider, .ant-layout-content {
  overflow: auto;
  height: 100%;
}

.layout-split {
  display: inline-block;
  height: 100%;
  background: #e0e0e0;
  width: 5px;
  cursor: w-resize;
  z-index: 999;
  position: absolute;
}
</style>

<template>
  <a-layout style="height: 100%;position: relative;" ref="layoutRef">
    <a-layout-sider theme="light" :width="width[0]">1
    </a-layout-sider>
    <div class="layout-split" :style="{left: width[0] + 'px'}" @mousedown="(event) => startDrag(event, 0, this)"></div>
    <a-layout-sider theme="light" :width="width[1]" :style="{'margin-left': '5px'}">22
    </a-layout-sider>
    <div class="layout-split" :style="{left: ( width[0] + width[1] + 5)+ 'px'}" @mousedown="(event) => startDrag(event, 1, this)"></div>
    <a-layout-content :style="{'margin-left': '5px'}">3</a-layout-content>
  </a-layout>
</template>

<script>
import {defineComponent, reactive, toRefs, ref} from 'vue'

export default defineComponent({
  components: {},
  setup() {
    const layoutRef = ref()
    const state = reactive({
      isDrag: false,
      width: [200, 300],
      minWidth: 50,
      dragTarget: 0,
      dragX: 0,
      originalX: 0
    })
    const startDrag = (event, target) => {
      if (event.button === 0) {
        state.dragTarget = target
        state.dragX = event.clientX
        state.originalX = state.width[target]
        state.isDrag = true
      }
      console.log(event)
    }

    const drag = (event) => {
      if (event.button === 0 && state.isDrag === true) {
        event.preventDefault()
        let newWidth = state.originalX + event.clientX - state.dragX
        const maxWidth = layoutRef.value.$el.clientWidth - 10 - state.minWidth - state.width[1 - state.dragTarget]
        state.width[state.dragTarget] = newWidth < state.minWidth ? state.minWidth : (newWidth > maxWidth ? maxWidth : newWidth)
      }
    }
    const stopDrag = (event) => {
      state.isDrag = false
      console.log(event)
    }
    document.addEventListener('mouseup', stopDrag)
    document.addEventListener('mousemove', drag)
    return {
      ...toRefs(state),
      startDrag,
      drag,
      stopDrag,
      layoutRef
    }
  },
  onUnmounted() {
    document.removeEventListener('mouseup', this.stopDrag)
    document.addEventListener('mousemove', this.drag)
  }
})
</script>
