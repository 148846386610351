<style scoped>
</style>

<template>
  <a-drawer
    :title="产品扩展要素"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-tabs v-model:activeKey="activeKey"  @change="abc">
    <a-tab-pane key="1" tab="自定义添加要素" >
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" :model="formState" @finishFailed="onFinishFailed">
        <a-form-item label="要素名称" name="elementName"  placeholder="请选择">
           <a-select  v-model:value="data.elementName" placeholder="请选择"  >
              <a-select-option :value="cd.value" v-for="cd in codelist" :key="cd.value">{{cd.label}}</a-select-option>
            </a-select>
        </a-form-item>
        <a-form-item label="是否显示" name="showStatus">
          <a-select v-model:value="data.showStatus"  placeholder="请选择">
              <a-select-option value="1">是</a-select-option>
              <a-select-option value="2">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="数据类型" name="dataType">
          <a-select v-model:value="data.dataType"  placeholder="请选择">
              <a-select-option value="01">短文本</a-select-option>
              <a-select-option value="02">富文本</a-select-option>
              <a-select-option value="04">数据字典</a-select-option>
          </a-select>
            <div  style="position: relative; margin-top:25px" v-if="data.dataType == 1">
                  <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" :name="elementValue" label="短文本">
                   <a-input v-model:value="data.elementValue" />
                  </a-form-item>
            </div>
            <div  style="position: relative;  margin-top:25px" v-if="data.dataType == 2">
               <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" :name="elementValue" label="富文本">
                      <a-textarea v-model:value="data.elementValue" />
                    </a-form-item>
            </div>
           
            <div  style="position: relative;  margin-top:25px" v-if="data.dataType == 4">
                   <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" label="数据字典类型" name="dataDictionaryType">
                        <a-select v-model:value="data.dataDictionaryType">
                            <a-select-option :value="cd.value" v-for="cd in dictionarylist" :key="cd.value">{{cd.label}}</a-select-option>
                        </a-select>
                      </a-form-item>
              </div>
        </a-form-item>
        <a-form-item label="显示顺序" name="sort" >
            <a-select v-model:value="data.sort" placeholder="请输入">
              <a-select-option value="1">1</a-select-option>
              <a-select-option value="2">2</a-select-option>
              <a-select-option value="3">3</a-select-option>
              <a-select-option value="4">4</a-select-option>
              <a-select-option value="5">5</a-select-option>
              <a-select-option value="6">6</a-select-option>
              <a-select-option value="7">7</a-select-option>
              <a-select-option value="8">8</a-select-option>
              <a-select-option value="9">9</a-select-option>
              <a-select-option value="10">10</a-select-option>
              <a-select-option value="11">11</a-select-option>
              <a-select-option value="12">12</a-select-option>
              <a-select-option value="13">13</a-select-option>
              <a-select-option value="14">14</a-select-option>
              <a-select-option value="15">15</a-select-option>
              <a-select-option value="16">16</a-select-option>
              <a-select-option value="17">17</a-select-option>
              <a-select-option value="18">18</a-select-option>
              <a-select-option value="19">19</a-select-option>
              <a-select-option value="20">20</a-select-option>
              </a-select>
        </a-form-item>
    </a-form>
    </a-tab-pane>
    <a-tab-pane key="2" tab="按模板添加要素" force-render >
      <a-table :columns="columns" :data-source="list"
           :row-selection="{ selectedRowKeys: checkids, 
            onChange: onSelectChange,  type:'radio',hideSelectAll:true
            }"
           :row-key="record => record.id"
           :pagination="false " @change="handleTableChange"
           :loading="loading" 
  >
         <template #bodyCell="{ column,index }">
           <template v-if="column.key === 'index'">
                {{ index + 1 }}
             </template>
               </template>
    </a-table>
    </a-tab-pane>
       
    </a-tabs>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <CloseOutlined/>取消
        </a-button>
        <a-button type="primary" @click="onSave()">
          <SaveOutlined/>保存
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import {defineComponent, reactive, toRefs, ref,onMounted} from 'vue'
import {getRequestURI,request} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
// import {now} from '../../../utils/date_util'request
import{message} from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined, SaveOutlined
  },
  props:{
    elementList:{type: Function},
  
  },
  setup(props, {expose,emit}) {
    const formRef = ref()
    const data=reactive({
            createTime:'' ,
            createUser:'',
            dataDictionaryType:'',
            dataType:undefined,
            elementName:undefined,
            elementValue:undefined,
            id: 0,
            isDel: 0,
            productBright:'',
            productCode:'' ,
            productInfoId: 0,
            showStatus: undefined,
            sort:undefined,
            supCode:'' ,
            updateTime:'' ,
            updateUser: ''
    })    
    const state = reactive({
      templateName:'',
      tempname:'',
      dictionarylist:[],
      codelist:[],
      activeKey:'1',
      list:[],
      checkids:[],
      visible: false,
      formData: {
            elementName: [],
            // isshow: '',
            // elementType: '',
            // shoutNode:'',//短文本
            // shoutNodes:'',
            // dictionaryType:'',
            // comlist:[],
            // sort: '',
      },
       columnss: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '要素',
          key: 'element',
          fixed: 'right',
          width: 100
        },
       
      ],
       columns: [
        {
          title: '序号',
          width: 140,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '模板名称',
          width: 120,
          align: 'center',
          key: 'templateName',
          dataIndex: 'templateName',
        
        },
      
       ],
    })
    const abc=(activeKey)=>{
       console.debug(state.activeKey)
       state.activeKey=activeKey

    }
    const showDrawer = (id) => {
      console.debug( id)
      data.productInfoId=id
      state.formData = {
        id: '',
      }
      state.visible = true
    }
           // 要素名称下拉
    const getElemengname = () => {
       request(getExtendUri('dictionary_query_byType'), {data: {types: ['productelement']}}).then(res => {
        console.debug(res,133333)
        state.codelist = res.result.productelement
       console.debug( state.codelist)
        // state.
      })
    }
    //  数据字典下拉
   const getDictionary = () => {
       request(getExtendUri('dictionary_query_byType'), {data: {types: ['datatype']}}).then(res => {
        console.debug(res,789789789)
       state.dictionarylist=res.result.datatype
      })
    }
    // 模板
  const listNode=()=>{
      request(getRequestURI('ppeq-copylist'),{pagination: {
                current: 0,
                pageSize: 10,
              }}).then(res => {
             state.list=res.result
          })
        }
     onMounted(() => {
       getElemengname()
          listNode()
          getDictionary()
        })
    const onClose = () => {
      state.checkids=[]
       data.elementName=undefined
       data.showStatus=undefined
       data.sort=undefined
       data.dataType=undefined
      state.visible = false
      data.elementValue=undefined
      onFormClean()
    }
    const onFormClean = () => {
      
    }
     const onSelectChange = (selectedRowKeys,selectedRows) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys,selectedRows)
      state.checkids = selectedRowKeys
      console.debug(selectedRows)
      selectedRows.forEach(item=> {
       state.templateName = item.templateName
      })
    console.debug(state.templateName)
    }
   
    const onSave = async () => {
      try {
        // const values = await formRef.value.validate()
        if (state.activeKey==1) {
          console.debug(999999, data)
            request(getRequestURI('pppa-addlist'), {data}, true, 'post').then(
            () => {
               message.success('保存成功')
               props.elementList()
               onClose()
            }
          )
        } else if(state.activeKey==2) {
          request(getRequestURI('ppeq-mbxq'), {
            data:{templateName:state.templateName}
                        }, true, 'post').then(
            (res) => {
              emit('onSave',res.result)
              onClose()
            }
          )
        }

      } catch (errorInfo) {
        console.debug('Failed:', errorInfo)
      }
    }
    expose({
      showDrawer
    })
    return {
      abc,
      data,
      listNode,
      getDictionary,
      getElemengname,
      onSelectChange,
      // activeKey: ref('1'),
      formRef,
      // formRules,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI
    }
  },

})
</script>
