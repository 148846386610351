<style scoped>
</style>

<template>
  <a-drawer
    title="关联产品"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData" :rules="formRules" layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="模板名称" name="templateName">
            <a-input v-model:value="formData.templateName"  placeholder="请输入模板名称" :disabled="true"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item  label="&nbsp;">
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="产品编码" name="productCode">
            <a-select v-model:value="formData.productCode" placeholder="请选择模板类型" clearable
                     @change="changeProductCode" :options="productCodeList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="产品名称" name="productName">
            <a-select @change="changeProductName" v-model:value="formData.productName" placeholder="请选择模板类型" clearable
                      :options="productNameList" >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item  label="&nbsp;">
            <a-button @click="onFormClean">
              <template #icon>
                <ReloadOutlined />
              </template>
              重置
            </a-button>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item  label="&nbsp;">
            <a-button @click="add"  >
              <template #icon>
                <FileAddOutlined/>
              </template>
              添加
            </a-button>&nbsp;
            <a-button danger @click="del" >
              <template #icon>
                <DeleteOutlined/>
              </template>
              删除
            </a-button>
          </a-form-item>
        </a-col>
        <div   >
          <a-table
            :columns="columnsss"
            :data-source="formData.productInfoIdListOne"
            :scroll="{ x: 300 }"
            :pagination="false"
            style="width: 800px;"
            :row-key="(record) => record.id"
            :row-selection="{
			selectedRowKeys: selectedRowKeysOne,
			onChange: onSelectChangess,
		}">
            <template #bodyCell="{ column, index, record}" >
              <template v-if="column.key === 'index'">
                {{ index + 1 }}
              </template>
              <template  v-if="column.key === 'productCode'">
                <div>
                  <a-input v-model:value="record.productCode" />
                </div>
              </template>
              <template v-if="column.key === 'productName'">
                <div>
                  <a-input v-model:value="record.productName" />
                </div>
              </template>
            </template>
          </a-table>

        </div>
      </a-row>
    </a-form>
    <template #extra>

      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave" >
          <template #icon>
            <SaveOutlined/>
          </template>
          保存
        </a-button>
      </a-space>

    </template>
  </a-drawer>
</template>
<script>

import {defineComponent, reactive, toRefs, ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message, Modal} from 'ant-design-vue'
export default defineComponent({
  components: {

  },
  setup(props, {expose}, context) {
    const formRef = ref()
    const formData = reactive({
      id: '',
      templateName:'',//模板名称
      productCode:'',//产品编码
      productName:'',//产品名称
      productInfoIdListOne:[]

    })
    const formDataOne= reactive({
      productTemplateId: '',
      templateName:'',//模板名称
      productCode:'',//产品编码
      productName:'',//产品名称
      productInfoIdList:[]
    })


    const state = reactive({
      zhuangTai:true,
      selectedRowKeysOne:[],//删除
      nameList:[],//系统名称
      dataSourceList:[],
      list: [],
      visible: false,
      isNew: true,
      selectedRowKeys: [],
      productCodeList:[],//产品编码
      productNameList:[],//产品名称
      columnsss: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left'
        },
        {
          title: '产品编码',
          key: 'productCode',
          fixed: 'right',
          width: 100
        },
        {
          title: '产品名称',
          key: 'productName',
          fixed: 'right',
          width: 100
        }
      ],
    })
    const onSelectChangess = (selectedRowKeysOne) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeysOne)
      state.selectedRowKeysOne = selectedRowKeysOne
    }
    //搜索
    const handleSearch = function () {
      let uri = getRequestURI('productTemplate_selectTemplateProduct')
      request(uri, {data:{id: formDataOne.productTemplateId}}).then(res=>{
        formData.productInfoIdListOne = res.result

      })
    }

    //改变产品code
    const changeProductCode=(val)=>{
      state.list.forEach(item => {
        if (item.id === val) {
          formData.productName = item.productName
          formData.productCode = item.productCode
          formData.id=item.id
        }
      })
      mingcheng()
    }
    //改变产品名称
    const changeProductName=(val)=> {
      state.list.forEach(item => {
        if (item.id === val) {
          formData.productCode = item.productCode
          formData.productName = item.productName
          formData.id=item.id
        }
      })
      mingcheng()
    }


    //获取产品编码数据
    const bianma = () => {
      let obj = {
      }
      let uri = getRequestURI('productTemplate_selectProduct')
      request(uri, {data: obj}, true, 'get').then(result => {
       const arr=[]
        const res=result.result
        for (let i=0;i<res.length;i++){
          arr.push({label: res[i].productCode,value: res[i].id})
        }
        state.productCodeList=arr
      })
    }
    //获取产品名称数据
    const mingcheng = () => {
      let obj = {
        productCode:'formData.productCode'
      }
      let uri = getRequestURI('productTemplate_selectProduct')
      request(uri, {data: obj}, true, 'get').then(result => {
        const arr=[]
        state.list = result.result
        const res=result.result
        for (let i=0;i<res.length;i++){
          arr.push({label: res[i].productName,value: res[i].id})
        }
        state.productNameList=arr
        console.debug(state.productNameList)
      })
    }

    const add = function () {
      if(formData.productCode==''){
        message.warning('请选择数据')
        return
      }

        formData.productInfoIdListOne.forEach(item => {
          if (formData.id === item.id) {
            message.warning('该数据已选择')
            state.zhuangTai=false
            return
          }
        })
      if(state.zhuangTai==true) {
        formData.productInfoIdListOne.push({
          id: formData.id,
          productCode: formData.productCode,
          productName: formData.productName
        })
      }
      state.zhuangTai=true
      formData.productCode=''
      formData.productName=''
      console.debug( formData.productInfoIdListOne)
    }

    //删除
    const del = function () {
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {
          state.selectedRowKeysOne.forEach((item) => {
            formData.productInfoIdListOne.forEach((item1, index) => {
              console.debug(state.selectedRowKeysOne)
              if (item1.id == item) {
                formData.productInfoIdListOne.splice(index, 1)
              }
            })
          })

          let arr=[]
          formData.productInfoIdListOne.forEach(item=>{
            arr.push(item.id)
          })
          let uri = getRequestURI('productTemplate_templateProduct')
          request(uri, {data:{
              productInfoIdList:arr,
              productTemplateId:formDataOne.productTemplateId
            }}).then(data => {
            console.debug(data)
            if (data.code == 1) {
              message['success']('删除成功')
              handleSearch()
            }
          })
        }
      })
    }


    const showDrawerOne = (oldData = {}) => {
      formDataOne.productTemplateId=oldData.id
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.debug('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      bianma()
      mingcheng()
      handleSearch()

    }
    const onFinish = (values) => {
      context.emit('callback', values)
    }

    //关闭
    const onClose = () => {
      state.visible = false
      formData.templateName=''//模板名称
      onFormClean()
      state.selectedRowKeysOne=[]
      formData.productInfoIdListOne=[]
    }
    //重置
    const onFormClean = () => {
        formData.productCode=''//产品编码
        formData.productName=''//产品名称
    }
    //提交
    const onSave = () => {
      if(formData.productInfoIdListOne==''){
        message['warning']('请添加数据')
        return
      }
      let arr=[]
      formData.productInfoIdListOne.forEach(item=>{
        arr.push(item.id)
      })
      let uri = getRequestURI('productTemplate_templateProduct')
      request(uri, {data:{
          productInfoIdList:arr,
          productTemplateId:formDataOne.productTemplateId
        }}).then(data => {
        onClose()
        console.debug(data)
        if (data.code == 1) {
          message['success']('保存成功')
          handleSearch()
        }
      })
    }
    expose({
      showDrawerOne
    })
    return {
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawerOne,
      bianma,
      mingcheng,
      changeProductCode,
      changeProductName,
      formDataOne,
      add,
      onSelectChangess,
      del,
      handleSearch


    }
  },

})
</script>
