<template>
  <search-form
    @resetAll="clearTag"
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="select-label">
    标签 : &nbsp;<a-button
      type="primary"
      @click="onSelectTag"
      style="margin-right: 15px"
      >选择标签</a-button
    >
    <a-tag v-for="sot in selectOkTags" :key="sot.id">{{ sot.value }}</a-tag>
  </div>
  <!-- 选择标签的弹框 -->
  <a-modal
    v-model:visible="visible"
    @cancel="close"
    width="600px"
    title="选择标签"
    @ok="handleOk"
  >
    <div class="modal-box">
      <div style="width: 100%">
        <div class="left">
          <a-tree
            v-if="tag_Data.length > 0"
            v-model:selectedKeys="selectedKeys"
            :tree-data="tag_Data"
            :default-expand-all="true"
            @select="onSelectTree"
            :destroyOnClose="true"
            zIndex
          >
          </a-tree>
        </div>
        <div class="right">
          <p>已选标签</p>
          <a-tag
            closable
            @close="delTag(st)"
            v-for="st in selectedTags"
            :key="st.id"
            >{{ st.value }}</a-tag
          >
        </div>
      </div>
    </div>
  </a-modal>
  <a-divider></a-divider>

  <div class="butt">
    <a-button class="a-butt" @click="saveMaterial">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button class="a-butt" @click="onShelf">
      <template #icon>
        <FileAddOutlined />
      </template>
      上架
    </a-button>
    <a-button class="a-butt" @click="onOffShelf">
      <template #icon>
        <FileAddOutlined />
      </template>
      下架
    </a-button>
    <a-button class="a-butt" @click="delCard">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
  </div>
  <AddSourceMaterial :handleSearch="handleSearch" ref="addShowDrawer" />
  <updateMaterial :handleSearch="handleSearch" ref="updateShowDrawer" :searchfFilters="searchfFilters"/>
  <div class="a-check">
    <poster-tab
      :isDisabled="isDisabled"
      @getIds="getIds"
      ref="postertab"
      :isShow="isShow"
      @tab-change="tabsChange"
      :data="tab_data"
      :dataType="dataType"
    ></poster-tab>
  </div>
  <div class="a-pag">
    <div></div>
    <!-- v-model:current="pagination.current" -->
    <!-- v-model:pageSize="pagination.pageSize" -->
    <a-pagination
      v-model:pageSize="pagination.pageSize"
      v-model:current="pagination.current"
      :total="pagination.total"
      @change="onShowSizeChange"
    />
  </div>
</template>

<script>

// import dayjs from 'dayjs'
import { message, Modal } from 'ant-design-vue'
import { getRequestURI, request } from '../../../utils/request.js'
// import { getDictionaryValue } from '../../../utils/request_extend'
import { defineComponent, reactive, toRefs, ref, provide, onMounted, nextTick } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import AddSourceMaterial from './addSourceMaterial.vue'
import updateMaterial from './updateMaterial.vue'
import posterTab from '../../../components/extended/posterTab'
export default defineComponent({
  components: {
    SearchForm,
    AddSourceMaterial,
    updateMaterial,
    posterTab
  },
  setup () {
    const addShowDrawer = ref()
    const updateShowDrawer = ref()
    const postertab = ref()
    const state = reactive({
      idList: [],
      status: 'status',
      tab_data: [],
      tag_Data: [], // 选择标签的数据
      isShow: '1',
      activeKey: '1',
      dataType: 'img',
      selectedKeys: [],
      treeList: [], // 用于接收选择标签的id
      selectedTags: [], // 用于展示选择标签的已选标签
      // 用于在外面展示 已选标签
      selectOkTags: [],
      checkAll: false,
      visible: false,
      isDisabled: false,
      condition: {
        productName: {
          label: '产品名称',
          placeholder: '请输入',
          type: 'input',
        },
        channelId: {
          label: '销售渠道',
          type: 'select',
          placeholder: '请选择',
          default: '1',
          data: []
        },
        status: {
          label: '上架状态',
          type: 'select',
          default: '1',
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'materialUpDownStatus' }
        },
        materialName: {
          label: '素材名称',
          placeholder: '请输入素材名称',
          type: 'input',
        },
        createTimeStart: {
          label: '创建开始时间',
          type: 'date-time-picker',
        },
        createTimeEnd: {
          label: '创建结束时间',
          type: 'date-time-picker',
        },
        updateTimeStart: {
          label: '更新开始时间',
          type: 'date-time-picker',
        },
        updateTimeEnd: {
          label: '更新结束时间',
          type: 'date-time-picker',
        },

      }

    })
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0
    })
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    // 查询
    const handleSearch = (filters) => {
      if (filters) {
        searchfFilters.value = filters
      }
      if (filters.createTimeStart >= filters.createTimeEnd) {
        return message.warning('创建结束时间需要大于创建开始时间')
      }
      if (filters.updateTimeStart >= filters.updateTimeEnd) {
        return message.warning('更新结束时间需要大于更新开始时间')
      }

      request(getRequestURI('productMaterial_selectProductMaterial'), {
        data: {
          ...filters,
          type: state.activeKey,
          labelNameList: [...state.treeList]
        },
        pagination: {
          current: pagination.current,
          pageSize: pagination.pageSize
        }
      }).then(res => {
        if (res.code === 1) {
          state.tab_data = getResult(res.result)
          console.log('切换的res', state.tab_data)
          nextTick(() => {
            Object.assign(pagination, res.pagination)
          })
          if (res.result.length > 0) {
            state.isDisabled = true
          }
        } else {
          message.warning(res.message)
        }
      })
    }
    provide('handleSearch', handleSearch)
    // 分页
    const onShowSizeChange = (current, pageSize) => {
      pagination.current = current
      pagination.pageSize = pageSize
      handleSearch({})
    }


    const getResult = (res) => {
      res.forEach(item => {
        if (item.isTop === '1') {
          item.isTop = true
        } else {
          item.isTop = false
        }
        // item.status = getDictionaryValue('materialUpDownStatus', item.status)
      })
      return res
    }

    const saveMaterial = () => {
      const oldData = state.condition.channelId.data
      addShowDrawer.value.showDrawer(state.activeKey, oldData)
    }


    // 选择标签按钮
    const onSelectTag = () => {
      state.visible = true
      request(getRequestURI('productLabel_selectTreeList2'), {
        data: {
          startState: '1',
          labelName: '物料标签'
        },
        pagination: { current: 1, pageSize: 10 }
      }).then(res => {
        if (res.code === 1) {
          state.tag_Data = getSelectTag(res.result)
        }
      })
    }
    const getSelectTag = (result) => {
      console.log(result)
      if (result && result.length > 0) {
        let arr = []
        result.forEach(item => {
          let node = {
            id: item.id,
            key: item.id,
            selectable: item.children !== null && item.children.length > 0 ? false : true,
            title: item.labelName,
            children: getSelectTag(item.children)
          }
          arr.push(node)
        })
        return arr
      } else {
        return []
      }
    }


    // 选择标签弹框的数据的回调
    const onSelectTree = (id, content) => {
      if (state.selectedTags.length > 2) return message.warning('最多可选3条数据')
      if (content.selectedNodes.length > 0) {
        let node = {
          id: content.selectedNodes[0].key,
          value: content.selectedNodes[0].title
        }
        if (state.selectedTags.length > 0) {
          state.selectedTags.push(node)
          var result = []
          var obj = {}
          for (var i = 0; i < state.selectedTags.length; i++) {
            if (!obj[state.selectedTags[i].id]) {
              result.push(state.selectedTags[i])
              obj[state.selectedTags[i].id] = true
            } else {
              message.warning('请勿选择相同数据')
            }
          }
          state.selectedTags = result
        } else {
          state.selectedTags.push(node)
        }
      }
    }

    // 选择标签的确认回调
    const handleOk = () => {
      state.visible = false
      // 用于在外面展示 已选标签
      state.selectOkTags = [...state.selectedTags]
      state.selectedTags.forEach(item => {
        state.treeList.push(item.id)
      })
    }
    // 取消
    const close = () => {
      state.selectedTags = [...state.selectOkTags]
    }
    // 已选便签的删除
    const delTag = (tagData) => {
      let index = state.selectedTags.indexOf(tagData)
      let tree = state.treeList.indexOf(tagData)
      state.selectedTags.splice(index, 1)
      state.treeList.splice(tree, 1)
    }
    // tabs切换的回调
    const tabsChange = (activeKey) => {
      state.activeKey = activeKey
      if (activeKey === '1') {
        state.dataType = 'img'
        handleSearch({})
      } else if (activeKey === '2') {
        state.dataType = 'yinpin'
        handleSearch({})
      } else if (activeKey === '3') {
        state.dataType = 'shipin'
        handleSearch({})
      } else if (activeKey === '4') {
        state.dataType = 'wenjian'
        handleSearch({})
      }
    }

    // 编辑按钮 组件传值
    const handelUpdate = (value) => {
      request(getRequestURI('productMaterial_selectMaterialById'), { data: { id: value.id, type: state.activeKey } }).then(res => {
        if (res.code === 1) {
          updateShowDrawer.value.showDrawer(state.activeKey, res.result)
        }
      })
    }
    provide('handelUpdate', handelUpdate)

    // 销售渠道下拉选
    const getChannelType = () => {
      request(getRequestURI('channel_queryListForPage'), { data: {}, pagination: { current: 1, pageSize: 999 } }).then(res => {
        console.log('销售渠道下拉选', res.result)
        res.result.forEach(item => {
          state.condition.channelId.data.push({ value: item.id, label: item.channelName })
        })
      })
    }

    onMounted(() => {
      getChannelType()
    })
    const getIds = (value) => {
      if (value && value.length > 0) {
        Object.assign(state.idList, [...value])
      }
    }
    const clearTag = () => {
      state.selectedTags = []
      state.selectOkTags = []
      state.treeList = []
    }
    // 删除
    const delCard = () => {
      console.log(state.idList, state.activeKey)
      const statusList = state.idList.filter(item => item.status === '1')
      if(statusList.length) return  message.warning('上架的素材不能被删除，请先下架该素材')
      if (state.idList.length > 0) {
        let ids = []
        state.idList.forEach(item =>{
          ids.push(item.id)
        })
        Modal.confirm({
          title: '删除提示',
          content: '确定删除此数据吗?',
          onOk: () => {
            request(getRequestURI('productMaterial_deleteMaterial'),
              {
                data: { ids, type: state.activeKey }
              }
            ).then(res => {
              if (res.code === 1) {
                message.success('删除成功')
                handleSearch({})
                postertab.value.clearIds()
                state.idList = []
              } else {
                message.warning(res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message.warning('请选择数据,至少一条')
      }
    }

    // 上架
    const onShelf = () => {
      if (state.idList.length > 0) {
        let ids = []
        for (let i = 0; i < state.idList.length; i++) {
          if(state.idList[i].status === '1'){
            return message.warning('素材处于上架状态，请下架后再进行此操作')
          }else{
            ids.push(state.idList[i].id)
          }
        }
        Modal.confirm({
          title: '上架提示',
          content: '确定上架此数据吗?',
          onOk: () => {
            request(getRequestURI('productMaterial_updatePut'),
              {
                data: { ids, type: state.activeKey }
              }
            ).then(res => {
              if (res.code === 1) {
                message.success('上架成功')
                handleSearch({})
                postertab.value.clearIds()
                state.idList = []
              } else {
                message.warning(res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message.warning('请选择数据,至少一条')
      }
    }
    // 下架
    const onOffShelf = () => {
      if (state.idList.length > 0) {
        console.log(1111, state.idList)
        let ids = []
        for (let i = 0; i < state.idList.length; i++) {
          if(state.idList[i].status === '2'){
            return message.warning('素材处于下架状态，请上架后再进行此操作')
          }else{
            ids.push(state.idList[i].id)
          }
        }
        Modal.confirm({
          title: '下架提示',
          content: '确定下架此数据吗?',
          onOk: () => {
            request(getRequestURI('productMaterial_updateOff'),
              {
                data: { ids, type: state.activeKey }
              }
            ).then(res => {
              if (res.code === 1) {
                message.success('下架成功')
                handleSearch({})
                postertab.value.clearIds()
                state.idList = []
              } else {
                message.warning(res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message.warning('请选择数据,至少一条')
      }
    }
    // 置顶
    const updateTop = getRequestURI('productMaterial_updateTop')
    provide('updateTop', updateTop)


    return {
      ...toRefs(state),
      close,
      postertab,
      getIds,
      clearTag,
      delCard,
      tabsChange,
      getChannelType,
      onShowSizeChange,
      onShelf,
      onOffShelf,
      delTag,
      handleSearch,
      saveMaterial,
      onSelectTag,
      handleOk,
      onSelectTree,
      pagination,
      updateShowDrawer,
      addShowDrawer,
      searchfFilters
    }

  }
})


</script>

<style scoped>
.select-label {
  margin-left: 30px;
}
.butt {
  width: 600px;
  margin-left: 30px;
}
.butt .a-butt {
  margin-right: 15px;
}
.a-check {
  margin-top: 20px;
  margin-left: 30px;
}
.modal-box {
  height: 350px;
}
.modal-box .left {
  height: 350px;
  width: 45%;
  overflow-y: auto;
  float: left;
  font-size: 14px;
}
.modal-box .right {
  float: right;
  width: 300px;
  height: 200px;
  padding-left: 5px;
  border-left: 1px solid rgb(222, 221, 221);
  font-size: 16px;
  font-weight: 700;
}
.a-pag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
:deep(.a-tabs){
  height: auto;
}
</style>
