<template>
  <a-drawer
    :title="panDuan"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item label="原始数据:" name="initializedData">
            <a-textarea v-model:value="formData.initializedData" :rows="4" :disabled="isDisable"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="请求原始数据:" name="remarks">
            <a-textarea v-model:value="formData.toData" :rows="4" :disabled="isDisable"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="转化原始数据:" name="remarks">
            <a-textarea v-model:value="formData.toDscData" :rows="4" :disabled="isDisable"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="返回原始数据:" name="remarks">
            <a-textarea v-model:value="formData.outData" :rows="4" :disabled="isDisable"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="返回转化数据:" name="remarks">
            <a-textarea v-model:value="formData.outDscData" :rows="4" :disabled="isDisable"/>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="异常数据:" name="remarks">
            <a-textarea v-model:value="formData.exceptionData" :rows="4" :disabled="isDisable"/>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'
import {getRequestURI, request} from '@/utils/request'
import {message} from 'ant-design-vue'

export default defineComponent({
  setup() {
    const formData = reactive({
      RawData: '',
      toData: '',
      toDscData: '',
      outData: '',
      outDscData: '',
      exceptionData: ''
    })
    const state = reactive({
      panDuan: '日志详情',
      isDisable: false,
      visible: false
    })
    const onClose = () => {
      state.visible = false
    }
    const showDrawer = function (oldData = {}) {
      console.log(oldData)
      state.visible = true
      formData.exceptionData = ''
      formData.outData = ''
      formData.outDscData = ''
      formData.toDscData = ''
      formData.initializedData = ''
      getDetailData(oldData.id)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    // 获取详情数据
    const getDetailData = (id) =>{
      request(getRequestURI('excLog_selectDetail'), {id}).then(res =>{
        if(res.code === 1){
          Object.keys(formData).forEach(key => {
            formData[key] = res.result[key] || formData[key]
          })
        }else{
          message.warning(res.message)
        }
      })
    }
    return {
      ...toRefs(state),
      onClose,
      showDrawer,
      formData
    }
  }
})
</script>

<style scoped>
textarea {
  height: 75px;
}
</style>
