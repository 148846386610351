<template>
<div>1</div>
</template>

<script>
export default {
  name: 'index'
}
</script>

<style scoped>

</style>
