<template>
  <a-drawer
    :title="titleName"
    :width="'65%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form layout="horizontal" ref="formRef" :model="data" autocomplete="off">
      <a-row :gutter="15">
        <a-col span="24">
          <a-form-item style="width: 550px" label="产品名称" name="productName">
            <a-select
              v-model:value="data.productInfoId"
              placeholder="请选择"
              dropdown-class-name="value-select"
              show-search
              allow-clear
              :filter-option="filterOption"
              :options="formData.productName"
            >
              <!-- <a-select-option :value="pt.value" v-for="pt in formData.productName" :key="pt.value">{{ pt.label }}
              </a-select-option> -->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item style="width: 550px" label="销售渠道" name="salesChannel">
            <a-select
              v-model:value="data.channelId"
              placeholder="请选择"
              dropdown-class-name="value-select"
              show-search
              allow-clear
              :filter-option="filterOption"
              :options="formData.salesChannel"
            >
              <!-- <a-select-option :value="sc.value" v-for="sc in formData.salesChannel" :key="sc.value">{{ sc.label }}
              </a-select-option> -->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="getKey === '1' ? true : false">
          <a-form-item style="width: 550px" label="素材名称" name="materialName">
            <a-input v-model:value="data.materialName" placeholder="请输入素材名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item>
            标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;签: &nbsp;<a-button @click="onSelectTag">选择标签</a-button>
            &nbsp;&nbsp;&nbsp;<a-tag v-for="sot in selectOkTags" :key="sot.productLabelId">{{ sot.labelName }}</a-tag>
          </a-form-item>
        </a-col>
        <!-- 上传图片 -->
        <a-col span="24" v-if="getKey === '1' ? true : false">
          <a-form-item label="上传图片" name="click" :rules="[{required:true,trigger:'blur'}]">
            <uploadFileNew :key="time" :showImg="showImg" listType="picture-card" filetype="img"
                           v-if="!isCan"
                           @fileChange="uploadCosFileChange" :num="1"></uploadFileNew>
            <p>请上传jpg、png格式图片,大小不要超过15M</p>
          </a-form-item>
          <div>
            <a-checkbox :class="{checkActive: data.isShelf}" v-model:checked="data.isShelf">请勾选是否默认上架，不勾选则默认为待上架状态
            </a-checkbox>
          </div>
        </a-col>
        <!-- 上传音频 -->
        <a-col span="24" v-if="getKey === '2' ? true : false">
          <a-form-item style="width: 550px" label="音频名称" name="audioName"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-input v-model:value="data.audioName" placeholder="请输入音频名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="getKey === '2' ? true : false">
          <a-form-item label="上传音频" name="audioUrl" :rules="[{required:true,trigger:'blur'}]">
            <upload-file-new v-if="!isUpdateShow" :num="1" filetype="amr" listType="audio"
                             @fileChange="onAudioUpload"></upload-file-new>
            <div v-if="isUpdateShow">
              <a style="margin-top: 5px;display: inline-block;" href="javascript:void(0)" @click="onPreviewFile(data)">{{ data.audioName }}</a>
              <DeleteOutlined style="margin-left: 10px;margin-top: 5px;cursor: pointer;" @click="handleRemove"/>
            </div>
            <p style="margin-top: 8px; color: #999">请上传amr格式音频,播放长度不超过120s</p>
          </a-form-item>
        </a-col>
        <!-- 上传视频 -->
        <a-col span="24" v-if="getKey === '3' ? true : false">
          <a-form-item style="width: 550px" label="视频名称" name="videoName"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-input v-model:value="data.videoName" placeholder="请输入视频名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="getKey === '3' ? true : false">
          <a-form-item style="width: 550px" label="视频简介" name="videoIntroduce"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-textarea v-model:value="data.videoIntroduce" show-count :maxlength="30"/>
          </a-form-item>
        </a-col>
        <a-col span="12" v-if="getKey === '3' ? true : false">
          <a-form-item label="视频上传" name="videoUrl" :rules="[{required:true,trigger:'blur'}]">
            <upload-file-new v-if="!isUpdateShow" :num="1" filetype="video" listType="picture"
                             @fileChange="onVideoUpload"></upload-file-new>
            <div v-if="isUpdateShow">
              <a style="margin-top: 5px;display: inline-block;" href="javascript:void(0)" @click="onPreviewFile(data)">{{ data.videoName }}</a>
              <DeleteOutlined style="margin-left: 10px;margin-top: 5px;cursor: pointer;" @click="handleRemove"/>
            </div>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="getKey === '3' ? true : false">
          <a-form-item label="视频封面" name="videoPictureUrl" :rules="[{required:true,trigger:'blur'}]">
            <upload-file-new listType="picture-card" :key="time" :showImg="showImg" :num="1" filetype="img"
                             @fileChange="uploadCosFileChange" @handelDel="handelDelVideo"></upload-file-new>
            <p>请上传jpg、png格式图片,大小不要超过15M</p>
          </a-form-item>
        </a-col>
        <!-- 上传文件 -->
        <a-col span="24" v-if="getKey === '4' ? true : false">
          <a-form-item style="width: 550px" label="文件名称" name="fileName" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-input v-model:value="data.fileName" placeholder="请输入文件名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="getKey === '4' ? true : false">
          <a-form-item style="width: 550px" label="文件简介" :rules="[{required:true,message:'必填信息',trigger:'change'}]"
                       name="fileIntroduce">
            <a-textarea v-model:value="data.fileIntroduce" show-count :maxlength="30"/>
          </a-form-item>
        </a-col>
        <a-col span="24" v-if="getKey === '4' ? true : false">
          <a-form-item label="文件上传" name="fileUrl" :rules="[{required:true,trigger:'blur'}]">
            <upload-file-new v-if="!isUpdateShow" ref="clearName" :showImg="showImg" :num="1" listType="picture"
                             @clearInput="onClearInput" @fileChange="onFileUpload"></upload-file-new>
            <div v-if="isUpdateShow">
              <a style="margin-top: 5px;display: inline-block;" href="javascript:void(0)" @click="onPreviewFile(data)">{{ data.fileName }}</a>
              <DeleteOutlined style="margin-left: 10px;margin-top: 5px;cursor: pointer;" @click="handleRemove"/>
            </div>
            <p style="margin-top: 8px">请上传docx、doc、xlsx、xls、ppt、pdf格式,大小不要超过20M</p>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <CloseOutlined/>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <SaveOutlined/>
          提交
        </a-button>
      </a-space>
    </template>
    <!-- 选择标签的弹框 -->
    <a-modal v-model:visible="tagShow" @cancel="treeClose" width="600px" title="选择标签" @ok="handleOk">
      <div class="modal-box">
        <div style="width:100%">
          <div class="left">
            <a-tree
              v-if="tag_Data.length > 0"
              v-model:selectedKeys="selectedKeys"
              :tree-data="tag_Data"
              :default-expand-all="true"
              @select="onSelectTree"
              :destroyOnClose="true"
            >
            </a-tree>
          </div>
          <div class="right">
            <p>已选标签</p>
            <a-tag closable @close="delTag(st)" v-for="st in selectedTags" :key="st.productLabelId">{{ st.labelName }}
            </a-tag>
          </div>
        </div>
      </div>
    </a-modal>
    <a-modal v-model:visible="videoShow" :footer="null" @cancel="videoClose" :title="data.videoName"
             :destroyOnClose="true">
      <video id="video" width="300" height="280" controls="controls">
        <source :src="data.videoUrl" type="video/mp4">
      </video>
    </a-modal>
    <a-modal v-model:visible="audioShow" :footer="null" @cancel="videoClose" :title="data.audioName"
             :destroyOnClose="true">
      <video id="video" width="300" height="280" controls="controls">
        <source :src="data.audioUrl" type="audio/mp3">
      </video>
    </a-modal>
  </a-drawer>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, onMounted, nextTick} from 'vue'
import {getRequestURI, request} from '../../../utils/request.js'
import {CloseOutlined, SaveOutlined} from '@ant-design/icons-vue'
import uploadFileNew from '../../../components/extended/uploadFileNew'
// import uploadFile  from '../../../components/extended/uploadFile'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    uploadFileNew,
    CloseOutlined,
    SaveOutlined,
    // uploadFile
  },
  props: {
    searchfFilters: { type: Object },
    handleSearch: {
      type: Function
    }
  },
  setup(props) {
    const clearName = ref()
    const formRef = ref()
    const data = reactive({
      id: 0,
      productName: undefined,
      productInfoId: undefined,
      channelId: undefined,
      materialName: '',
      isShelf: true,
      pictureUrl: '', // 图片路径
      audioName: '',
      audioUrl: '',
      videoName: '',
      videoUrl: '',
      videoPictureUrl: '',
      videoIntroduce: '',
      fileName: '',
      fileUrl: '',
      fileIntroduce: ''
    })
    const formData = reactive({
      productName: [],
      salesChannel: []
    })
    const isCan = ref(false)

    const state = reactive({
      selectedKeys: [],
      time: null,
      showImg: [],
      tag_Data: [], // 选择标签的回调
      getKey: '',
      titleName: '上传图片',
      visible: false,
      tagShow: false,
      treeList: [], // 用于接收选择标签的id
      selectedTags: [], // 用于展示选择标签的已选标签
      // 用于在外面展示 已选标签
      selectOkTags: [],
      isUpdateShow: false,
      videoShow: false,
      audioShow: false
    })

    const showDrawer = (activeKey, value) => {
      console.debug(value)
      state.getKey = activeKey
      if (activeKey === '1') {
        state.titleName = '编辑图片'
        Object.assign(data, {
          id: value.id,
          pictureUrl: value.pictureUrl,
          materialName: value.materialName,
          productInfoId: value.productInfoId ? value.productInfoId : undefined,
          productName: value.productName ? value.productName : undefined,
          channelId: value.channelId ? value.channelId : undefined,
          isShelf: value.status === '1' ? true : false
        })
        let obj = {}
        obj.url = value.pictureUrl
        state.showImg.push(obj)
        state.selectedTags = [...value.labelNameList]
        state.selectOkTags = [...value.labelNameList]
        if (state.selectedTags.length > 0) {
          state.selectedTags.forEach(item => {
            state.treeList.push(item.productLabelId)
          })
        }
      } else if (activeKey === '2') {
        state.titleName = '编辑音频'
        Object.assign(data, {
          id: value.id,
          audioName: value.audioName,
          audioUrl: value.audioUrl,
          productInfoId: value.productInfoId ? value.productInfoId : undefined,
          productName: value.productName ? value.productName : undefined,
          channelId: value.channelId ? value.channelId : undefined,
          isShelf: value.status === '上架' ? true : false
        })
        let obj = {}
        obj.url = value.audioUrl
        obj.name = value.audioName
        state.showImg.push(obj)
        state.isUpdateShow = true
        state.selectedTags = [...value.labelNameList]
        state.selectOkTags = [...value.labelNameList]
        if (state.selectedTags.length > 0) {
          state.selectedTags.forEach(item => {
            state.treeList.push(item.productInfoId)
          })
        }
      } else if (activeKey === '3') {
        state.titleName = '编辑视频'
        Object.assign(data, {
          id: value.id,
          productInfoId: value.productInfoId ? value.productInfoId : undefined,
          productName: value.productName ? value.productName : undefined,
          channelId: value.channelId ? value.channelId : undefined,
          isShelf: value.status === '上架' ? true : false,
          videoIntroduce: value.videoIntroduce,
          videoName: value.videoName,
          videoUrl: value.videoUrl,
          videoPictureUrl: value.videoPictureUrl
        })
        let obj = {}
        obj.url = value.videoPictureUrl
        obj.name = value.videoName
        state.showImg.push(obj)
        state.isUpdateShow = true
        state.selectedTags = [...value.labelNameList]
        state.selectOkTags = [...value.labelNameList]
        if (state.selectedTags.length > 0) {
          state.selectedTags.forEach(item => {
            state.treeList.push(item.productInfoId)
          })
        }
      } else if (activeKey === '4') {
        state.titleName = '编辑文件'
        Object.assign(data, {
          id: value.id,
          fileName: value.fileName,
          fileUrl: value.fileUrl,
          fileIntroduce: value.fileIntroduce,
          productInfoId: value.productInfoId ? value.productInfoId : undefined,
          productName: value.productName ? value.productName : undefined,
          channelId: value.channelId ? value.channelId : undefined,
          isShelf: value.status === '上架' ? true : false
        })
        let obj = {}
        obj.name = value.fileName
        obj.url = value.fileUrl
        state.showImg.push(obj)
        state.isUpdateShow = true
        state.selectedTags = [...value.labelNameList]
        state.selectOkTags = [...value.labelNameList]
        if (state.selectedTags.length > 0) {
          state.selectedTags.forEach(item => {
            state.treeList.push(item.productInfoId)
          })
        }
      }
      state.visible = true
    }
    // 上传图片的返回值
    const uploadCosFileChange = (picData) => {
      if (state.getKey === '1') {
        data.pictureUrl = picData.url
      } else if (state.getKey === '4') {
        data.videoPictureUrl = picData.url
      }
    }

    const clearImg = () => {
      isCan.value = true
      //nextTick 函数是在下一次dom更新后回调，
      //这就可以实现先执行的上面的代码，让input[file]节点消失，
      //然后再dom更新后回调该函数，又执行了让input[file]节点显示，这样就实现了刷新
      nextTick(() => {
        isCan.value = false
      })
    }

    // 点击展示对应的
    const onPreviewFile = (file) => {
      console.debug(state.getKey)
      if (state.getKey === '3') {
        state.videoShow = true
      } else if (state.getKey === '2') {
        state.audioShow = true
      } else if (state.getKey === '4') {
        window.location = file.fileUrl
      }
    }
    // 删除按钮
    const handleRemove = () => {
      state.isUpdateShow = false
      state.showImg = []
      if(state.getKey === '4'){
        data.fileUrl = ''
      }
    }
    // 视频关闭
    const videoClose = () => {
      state.videoShow = false
      state.audioShow = false
      let a = document.getElementById('video')
      a.pause()
      a.currentTime = 0
    }
    // 视频图片删除
    const handelDelVideo = () =>{
      data.videoPictureUrl = ''
    }

    const onClose = () => {
      state.visible = false
      state.time = new Date().getTime()
      state.showImg = []
      state.isUpdateShow = false
    }
    const onClearInput = () => {

    }
    // 选择标签按钮
    const onSelectTag = () => {
      state.tagShow = true
      request(getRequestURI('productLabel_selectTreeList2'), {
        data: {
          startState: '1',
          labelName: '物料标签'
        },
        pagination: {current: 1, pageSize: 10}
      }).then(res => {
        if (res.code === 1) {
          state.tag_Data = getSelectTag(res.result)
        }
      })
    }
    const getSelectTag = (result) => {
      if (result && result.length > 0) {
        let arr = []
        result.forEach(item => {
          let node = {
            id: item.id,
            key: item.id,
            selectable: item.children !== null && item.children.length > 0 ? false : true,
            title: item.labelName,
            children: getSelectTag(item.children)
          }
          arr.push(node)
        })
        return arr
      } else {
        return []
      }
    }
    // 选择标签弹框的数据的回调
    const onSelectTree = (id, content) => {
      if (state.selectedTags.length > 2) return message.warning('最多可选3条数据')
      if (content.selectedNodes.length > 0) {
        let node = {
          productLabelId: content.selectedNodes[0].key,
          labelName: content.selectedNodes[0].title
        }
        if (state.selectedTags.length > 0) {
          state.selectedTags.push(node)
          var result = []
          var obj = {}
          for (var i = 0; i < state.selectedTags.length; i++) {
            if (!obj[state.selectedTags[i].productLabelId]) {
              result.push(state.selectedTags[i])
              obj[state.selectedTags[i].productLabelId] = true
            } else {
              message.warning('请勿选择相同数据')
            }
          }
          state.selectedTags = result
          console.debug(state.selectedTags)
        } else {
          state.selectedTags.push(node)
        }
      }
    }
    // 选择标签的确认回调
    const handleOk = () => {
      state.treeList = []
      state.tagShow = false
      // 用于在外面展示 已选标签
      state.selectOkTags = [...state.selectedTags]
      state.selectedTags.forEach(item => {
        state.treeList.push(item.productLabelId)
      })
    }
    // 取消
    const treeClose = () => {
      state.selectedTags = [...state.selectOkTags]
    }
    // 已选便签的删除
    const delTag = (tagData) => {
      let index = state.selectedTags.indexOf(tagData)
      console.debug('已选便签的删除', index)
      state.selectedTags.splice(index, 1)
      console.debug('state的', state.selectedTags)
    }

    const onSave = async () => {
      if (state.getKey === '1') {
        updatePicture()
      } else if (state.getKey === '2') {
        console.debug('上传音频')
        updateAudio()
      } else if (state.getKey === '3') {
        console.debug('上传视频')
        updateVideo()
      } else if (state.getKey === '4') {
        console.debug('上传文件')
        updateFile()
        if(clearName.value.cleInput){
          clearName.value.cleInput.value = ''
        }
      }
      state.showImg = []
      clearImg()
    }
    // const setProductName = () => {
    //   formData.productName.forEach(item => {
    //     if(item.label === data.productInfoId){
    //       data.productName = item.value
    //     }
    //   })
    // }
    // 更新图片
    const updatePicture = () => {
      if (!data.pictureUrl) {
        return message.warning('请上传图片')
      }
      request(getRequestURI('productMaterial_updatePicture'), {
        data: {
          id: data.id,
          channelId: data.channelId,
          pictureUrl: data.pictureUrl,
          productInfoId: data.productInfoId,
          productName: getNameById(data.productInfoId),
          materialName: data.materialName,
          productLabelIdList: state.treeList.length > 0 ? [...state.treeList] : [],
          status: data.isShelf ? 1 : 0
        }
      }).then(res => {
        successMethod(res)
      })
    }
    const onFileUpload = (file) => {
      data.fileUrl = file.url
    }
    const updateFile = () => {
      if(!data.fileIntroduce) return message.warning('请输入文件简介')
      request(getRequestURI('productMaterial_updateFile'), {
        data: {
          id: data.id,
          channelId: data.channelId,
          productInfoId: data.productInfoId,
          productName: getNameById(data.productInfoId),
          productLabelIdList: state.treeList.length > 0 ? [...state.treeList] : [],
          status: data.isShelf ? 1 : 0,
          fileName: data.fileName,
          fileIntroduce: data.fileIntroduce,
          fileUrl: data.fileUrl,
        }
      }).then(res => {
        successMethod(res)
      })
    }
    const onAudioUpload = (audio) => {
      data.audioUrl = audio.url
    }
    const updateAudio = () => {
      request(getRequestURI('productMaterial_updateAudio'), {
        data: {
          id: data.id,
          channelId: data.channelId,
          productName: getNameById(data.productInfoId),
          productInfoId: data.productInfoId,
          productLabelIdList: state.treeList.length > 0 ? [...state.treeList] : [],
          status: data.isShelf ? 1 : 0,
          audioName: data.audioName,
          audioUrl: data.audioUrl,
        }
      }).then(res => {
        successMethod(res)
      })
    }
    const onVideoUpload = (video) => {
      data.videoUrl = video.url
    }
    const updateVideo = () => {
      request(getRequestURI('productMaterial_updateVideo'), {
        data: {
          id: data.id,
          channelId: data.channelId,
          productInfoId: data.productInfoId,
          productName: getNameById(data.productInfoId),
          productLabelIdList: state.treeList.length > 0 ? [...state.treeList] : [],
          status: data.isShelf ? 1 : 0,
          videoName: data.videoName,
          videoIntroduce: data.videoIntroduce,
          videoPictureUrl: data.videoPictureUrl,
          videoUrl: data.videoUrl,
        }
      }).then(res => {
        successMethod(res)
      })
    }
    const getNameById = (id) => {
      for (let i = 0; i < formData.productName.length; i++) {
        if (id === formData.productName[i].value) {
          return formData.productName[i].label
        }
      }
      return ''
    }
    const successMethod = (res) => {
      if (res.code === 1) {
        message.success('保存成功')
        props.handleSearch(props.searchfFilters)
        state.visible = false
        Object.assign(data, {
          id: 0,
          productName: undefined,
          channelId: undefined,
          productInfoId: undefined,
          materialName: '',
          isShelf: true,
          pictureUrl: '', // 图片路径
          audioName: '',
          audioUrl: '',
          videoName: '',
          videoUrl: '',
          videoPictureUrl: '',
          videoIntroduce: '',
          fileName: '',
          fileUrl: '',
          fileIntroduce: ''
        })
        state.selectedTags = []
        state.selectOkTags = []
        state.treeList = []
      } else {
        message.warning(res.message)
      }
    }


    //产品下拉查询
    const getProductName = () => {
      request(getRequestURI('select'), {
        data: {
          productCode: '',
          productId: null,
          supProductCode: '',
          supplierId: null,
          systemCode: ''
        },
      }, true, 'post').then(
        (result) => {

          const arr = []
          const res = result.result
          const label = 'productName'
          const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])

          for (let i = 0; i < sysName.length; i++) {
            arr.push({label: sysName[i].productName, value: sysName[i].id})
          }
          formData.productName = arr
          // console.debug('33333', formData.productName)
        }
      )
    }
    // 销售渠道下拉选
    const getChannelType = () => {
      formData.salesChannel = []
      request(getRequestURI('channel_queryListForPage'), {
        data: {},
        pagination: {current: 1, pageSize: 999}
      }).then(res => {
        res.result.forEach(item => {
          formData.salesChannel.push({value: item.id, label: item.channelName})
        })
      })
    }
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    onMounted(() => {
      getChannelType()
      getProductName()
    })

    return {
      filterOption,
      ...toRefs(state),
      uploadCosFileChange,
      // setProductName,
      onFileUpload,
      onVideoUpload,
      clearName,
      onClearInput,
      onSave,
      handleOk,
      treeClose,
      delTag,
      showDrawer,
      onSelectTag,
      onSelectTree,
      onClose,
      onAudioUpload,
      formData,
      data,
      activeKey: ref('1'),
      tabPosition: ref('top'),
      formRef,
      onPreviewFile,
      handleRemove,
      videoClose,
      isCan,
      clearImg,
      handelDelVideo
    }
  }
})
</script>

<style scoped>
.radio-slot {
  margin-top: 20px;
  height: 150px
}

.checkActive {
  color: #1890ff;
}

.modal-box {
  height: 350px;
}

.modal-box .left {
  float: left;
  font-size: 14px;
}

.modal-box .right {
  float: right;
  width: 300px;
  height: 200px;
  padding-left: 5px;
  border-left: 1px solid rgb(222, 221, 221);
  font-size: 16px;
  font-weight: 700;
}
</style>
