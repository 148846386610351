<style scoped>
  .bg {
    margin-bottom: 20px;
  }
</style>

<template>
  <a-drawer
    title="数据同步"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <search-form
      :Condition="condition"
      :ColSpan="12"
      @callback="handleSearch"
      v-if="look"
    ></search-form>


    <a-table
      class="bg"
      :columns="columns"
      :key="time"
      :data-source="dataSourceList"
      :row-selection="{
			selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
		}"
      :row-key="(record) => record.id"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="loading"
    >
      <template #bodyCell="{ column, index, }">
        <template v-if="column.key === 'index'">
          {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
        </template>
      </template>

    </a-table>

    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="dataSynchronous">
          <template #icon>
            <SaveOutlined/>
          </template>
          数据同步
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {CloseOutlined, SaveOutlined,} from '@ant-design/icons-vue'
import {computed, defineComponent, h, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
// import {getRequestURI, requestUsePagination} from '../../../utils/request'
import SearchForm from '../../../components/extended/search_form.vue'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    CloseOutlined,
    SaveOutlined,
    SearchForm,
  },

  setup(props, {expose}) {
    const formRef = ref()
    const isCheckInfo = ref(false)
    const formData = reactive({})

      const state = reactive({
        time:null,
        look:false,
        searchNow: {},
        visible: false,
        columns: [
          {
            title: '序号',
            key: 'index',
            fixed: 'center',
            width: 80,
            align: 'center',
          },
          {
            title: '接口名称',
            width: 300,
            align: 'center',
            key: 'name',
            dataIndex: 'name',
          },
          {
            title: '接口标识',
            width: 200,
            align: 'center',
            key: 'method',
            dataIndex: 'method',
          },
          {
            title: '入参类型',
            width: 150,
            align: 'center',
            key: 'intoType',
            dataIndex: 'intoType',
            customRender: (text) => {
              if (text.value === 1) {
                return 'JSON'
              } else if (text.value === 2) {
                return 'XML'
              }
            }
          },
          {
            title: '出参类型',
            width: 150,
            align: 'center',
            key: 'outType',
            dataIndex: 'outType',
            customRender: (text) => {
              if (text.value === 1) {
                return 'JSON'
              } else if (text.value === 2) {
                return 'XML'
              }
            }
          },
          {
            title: '接口地址',
            width: 200,
            dataIndex: 'serverPath',
            key: 'serverPath',
            align: 'center',
            customRender: ({text}) => {
              // 不要这样写，会报jsx语法支持问题
              // return {
              //   children: <div style={{width: '200px', wordWrap: 'break-word'}}>{text}</div>,
              // }
              return h(
                'div',
                {style: {width: '200px', wordWrap: 'break-word'}},
                text,
              )
            },
            // scopedSlots: {
            //   customRender: 'serverPath',
            // },
          },
        ],
        condition: {
          systemCode: {
            label: '数据来源:',
            type: 'select1',
            default: '',
            data: []
          },
          name: {
            label: '接口名称',
          },
          method: {
            label: '接口标识:',
          },
        },
        selectedRowKeys: [],
        dataSourceList:[]
      })
      const serveNameShow = ref(false)


      //数据来源 下拉
      const getDataSource = () => {
        request(getRequestURI('env_sync_query'), {
          // systemCode: '',
          // systemName: ''
        }, true, 'get').then(
          (result) => {
            const arr = []
            const res = result.result
            const label = 'systemName'
            const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
            for (let i = 0; i < sysName.length; i++) {
              arr.push({label: sysName[i].systemName, value: sysName[i].systemCode})
            }
            state.condition.systemCode.data = arr
            console.log(state.condition.systemCode.data)
          }
        )
      }
      onMounted(() => {
        // getProductName()
        // getSupName()
        getDataSource()
      })
      const onSelectChange = (selectedRowKeys, filters, sorter) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys, filters, sorter)
        state.selectedRowKeys = selectedRowKeys
      }

      const {
        data: dataSource,
        run,
        loading,
        current,
        pageSize,
        total,
      } = requestUsePagination(getRequestURI('syncEdtData_selectEdtDataList'))
      // service/product/syncEdtData/selectEdtDataList
//查询
      // "method": "",
     // "name": "",
     //   "systemCode": ""
      const handleSearch = function (filters) {
        if(filters.systemCode === undefined){
          return message.error('请选择数据来源')
        }
        console.log('查询参数==', filters)
        state.searchNow = filters
        run({
          pagination: {
            current: 1,
            pageSize: 10
          },
          data: filters,
        }).then(res=>{
          state.dataSourceList=res.result
        })

      }
      const pagination = computed(() => ({
        current: current.value,
        pageSize: pageSize.value,
        total: total.value,
      }))
      const handleTableChange = (pag, filters, sorter) => {
        console.log('===', pag, filters, sorter)
        run({
          pagination: {
            current: pag?.current,
            pageSize: pag?.pageSize
          },

          sorter: [{
            field: '',
            order: ''
          }],
          data: state.searchNow,
        }).then(res=>{
          state.dataSourceList=res.result
        })
      }
      //抽屉
      const showDrawer = (oldData) => {
        state.look= true
        state.visible = true
        Object.keys(formData).forEach((key) => {
          formData[key] = oldData[key] || formData[key]
        })
      }
      //关闭 清空
      const onClose = () => {
        state.look = false,
          state.visible = false
        /*state.dataSourceList = []
        pagination.value.total=0
        state.time=new Date().getTime()
        state.selectedRowKeys=[]*/
      }
      //重置
      const onFormClean = () => {
        formRef.value.resetFields()
        state.targetKeys = []
        state.visible = false
      }

      //环境 同步
      const dataSynchronous = () => {
        let keys = state.selectedRowKeys
        if (keys.length === 0) {
          message.warning('请先选择数据')
          return
        }
        loading.value = true
        let data = {
          ids: keys,
          systemCode: state.searchNow.systemCode
        }
        request(getRequestURI('syncEdtData_syncEdtData'), {data}).then(
          (result) => {
            if (result.code === 1){
              message.success(result.message + '数据同步成功')
              state.result = result.result
              loading.value = false
            }else{
              message.warning(result.message)

            }

          }
        ).catch(e => {
          console.log(e)
        })

      }
     /* const init = function () {
        request(getRequestURI('select'),{data: {}}).then(result =>{
          const arr = []
          const res = result.result
          const label = 'productName'
          const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
          for (let i = 0; i < sysName.length; i++) {
            arr.push({label: sysName[i].productName, value: sysName[i].id})
          }
          state.condition.productId.data = arr
        })
      }
      init()*/
      expose({
        showDrawer,
      })
      return {
        formRef,
        formData,
        ...toRefs(state),
        dataSynchronous,
        onClose,
        onFormClean,
        loading,
        dataSource,
        total,
        handleSearch,
        onSelectChange,
        handleTableChange,
        pagination,
        serveNameShow,
        checked: ref(false),
        isCheckInfo,
        getDataSource,
      }
    },
  })
</script>


