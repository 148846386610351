<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
    <div class="btn-bar">
      <a-button type="primary" @click="addUser('newAdd')">
        <template #icon>
          <FileAddOutlined/>
        </template>
        新增
      </a-button>
      <a-button danger @click="deleteUser">
        <template #icon>
          <DeleteOutlined/>
        </template>
        删除
      </a-button>
    </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
    selectedRowKeys: selectedRowKeys,
			onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >

    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="modifyUser(record,'edit')">编辑</a>&nbsp;&nbsp;
          <a style="color: orange" @click="toConfigure()">模板配置</a>&nbsp;&nbsp;
          <a style="color: orange" @click="relation(record)">关联产品</a>

        </div>
      </template>
    </template>
  </a-table>
  <productTemplateAdd ref="userDrawerRef" :handleSearch="handleSearch" :status="status"></productTemplateAdd>
  <productTemplateguanLian ref="userDrawerRefOne" :handleSearch="handleSearch" :status="status"></productTemplateguanLian>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI,request, requestUsePagination} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
import SearchForm from '../../../components/extended/search_form'
import productTemplateAdd from './productTemplateAdd.vue'
import productTemplateguanLian from './productTemplateguanLian.vue'
import {Modal,message} from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    productTemplateAdd,
    productTemplateguanLian
  },
  setup() {
    onMounted(() => {
      // huoqu()
      qudao()
      leixing()
    })
    const userDrawerRef = ref()
    const userDrawerRefOne = ref()
    const state = reactive({
      condition: {
        supplierId:{
          label: '供应商名称',
          type: 'tree-select',
          value:'id',
          urlKey: 'querySupplier'
        },

        templateName:'模板名称',
        distributionChannel:{
          label: '销售渠道',
          type: 'select',
          default: '',
          data: [
          ]
        },
        updateUser:'上传者',
        createTimeStart: {
          label: '创建开始时间',
          type: 'date-time-picker',
        },
        createTimeEnd: {
          label: '创建结束时间',
          type: 'date-time-picker',
        },
      },
      status:'',
      result: [],
      abc:[],
      lastFilter:{},
      query: {
        data: {
          riskCode: '',
          riskLengthSign:'',
          riskName: '',
          riskSign: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
        }
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '供应商名称',
          width: 140,
          align: 'center',
          key: 'supName',
          dataIndex: 'supName',
        },
        {
          title: '销售渠道',
          width: 140,
          align: 'center',
          key: 'distributionChannel',
          dataIndex: 'distributionChannel',
          customRender:(text)=>{
            for(let a of state.condition.distributionChannel.data){
              if(text.text===a.value){
                return a.label
              }
            }
          }
        },
        {
          title: '模板名称',
          width: 140,
          align: 'center',
          key: 'templateName',
          dataIndex: 'templateName',
        },
        {
          title: '模板类型',
          width: 140,
          align: 'center',
          key: 'templateType',
          dataIndex: 'templateType',
          customRender:(text)=> {
            for (let a of state.abc) {
              if (text.text === a.value) {
                return a.label
              }
            }
          }
        },
        {
          title: '创建时间',
          width: 140,
          align: 'center',
          key: 'createTime',
          dataIndex: 'createTime',
        },
        {
          title: '上传者',
          width: 140,
          align: 'center',
          key: 'updateUser',
          dataIndex: 'updateUser',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 200,
          scopedSlots: {customRender: 'operation'}
        },
      ],
      selectedRowKeys: [],
      data:[],// 删除data
      del:'',
      deleteQuery: {},

    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('select_productTemplate'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data:state.lastFilter,
      })
    }
    const handleSearch = function (filters) {
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }

   //获取销售渠道
    const qudao = () => {
      let obj = {
        types:['channel_type'],
        ids:[]
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, {data: obj}).then(result => {
        const arr = []
        const res = result.result.channel_type
        for (let i = 0; i < res.length; i++) {
          arr.push({label: res[i].label, value: res[i].value})
        }

        state.condition.distributionChannel.data = arr

      })
    }

    //获取模板类型
    const leixing = () => {
      let obj = {
        types:['template_type'],
        ids:[]
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, {data: obj}).then(res => {
        console.log(res,'456')
        state.abc = res.result.template_type
      })
    }

    //多选
    const onSelectChange = (selectedRowKeys) => {
      state.data.splice(0)
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      for (let selectedRowKeys of state.selectedRowKeys) {
        for (let result in state.result) {
          if (state.result[result].id == selectedRowKeys && state.data.includes(state.result[result]) == false) {
            state.data.push(state.result[result])
          }
        }
      }
      console.log(state.data)
    }


    //新增
    const addUser = function (status) {
      console.log(status)
      state.status = status
      userDrawerRef.value.showDrawer(status)
    }
    //编辑
    const modifyUser = function(record,status) {
      state.status = status
      userDrawerRef.value.showDrawer(record,status)
    }
    //批量删除
    const deleteUser = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk() {

          let uri = getRequestURI('productTemplate_delete')
          request(uri, {data: {
              ids:keys
            }}).then(
            (result) => {
              if(result.code == 1) {
                message['success']('删除成功')
                state.selectedRowKeys = []
                handleSearch({})
              }else{
                message.warning(result.message)
              }

            }
          )
        },
        onCancel() {
        }
      })
    }
    //模板配置
    const toConfigure = () => {

    }
    //关联产品
    const relation = (record) => {
      state.status = status
      userDrawerRefOne.value.showDrawerOne(record)
    }

    return {
      ...toRefs(state),
      handleSearch,
      addUser,
      userDrawerRef,
      userDrawerRefOne,
      deleteUser,
      modifyUser,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      qudao,
      toConfigure,
      current,
      pageSize,
      total,
      relation,
      leixing

    }
  },
})
</script>

<style scoped>

</style>
