<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="调用方名称:" name="insName">
            <a-select :disabled="true" v-model:value="formData.insName">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="接口名称:" name="portName">
            <a-select :disabled="true" v-model:value="formData.portName">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="上级字段:" name="parentColumnName">
            <a-select :disabled="true" placeholder="请选择上级字段" v-model:value="formData.parentColumnName">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="column"
                       :rules="[{required:true,max: 30, message: '必填项,输入长度不能超过30' ,trigger: 'change'},]">
            <template #label>
              <span>字段名称:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      作为报文节点的key值，不可为汉字，最长40字符
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.column" placeholder="请输入字段名称">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="name" :rules="[{required:false,max: 50, message: '输入长度不能超过50' ,trigger: 'change'},]">
            <template #label>
              <span>字段说明:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      最长50个字符
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.name" placeholder="请输入字段说明">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="columnType" :rules="[{required:true, message: '必填项' ,trigger: 'change'},]">
            <template #label>
              <span>字段类型:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当字段类型为“Value”，“Boolean”时，不允许添加下级参数，当为“Map”时，必须要有下级参数，当list下没有下级参数时，list内为String类型
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.columnType" placeholder="请选择字段类型" :options="ziduanleixing">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="defaultValue"
                       :rules="[{required:false,max: 100, message: '输入长度不能超过100' ,trigger: 'change'},]">
            <template #label>
              <span>默认值:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      字段映射的时，如果映射字段为空，则会取默认值
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-input v-model:value="formData.defaultValue" placeholder="请输入默认值">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否错误标记:" name="errorFlag">
            <a-select allowClear v-model:value="formData.errorFlag" :options="listData.xinxibiaoji">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否错误信息标记:" name="errorMsgFlag">
            <a-select allowClear v-model:value="formData.errorMsgFlag" :options="listData.xinxibiaoji">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="decrypt">
            <template #label>
              <span>是否解密:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      如果选择“是”，则会根据加密类型对当前字段的值进行解密
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.decrypt" :options="yesNoList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="解密算法:" name="decryptType">
            <a-select v-model:value="formData.decryptType" placeholder="请选择解密算法" :options="encryptTypeList"></a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="encrypt">
            <template #label>
              <span>是否加密:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      如果选择“是”，则会根据加密类型对当前字段的值进行加密
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.encrypt" :options="yesNoList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="加密算法:" name="encryptType">
              <a-select v-model:value="formData.encryptType" placeholder="请选择加密算法" :options="encryptTypeList"></a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button  type="primary"  @click="onSave" >
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, onBeforeMount, reactive, toRefs,ref} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import {getDictionaryData, getExtendUri} from '../../../utils/request_extend'

export default defineComponent({
  props: {
    status: {
      type: String,
      default() {
        return ''
      }
    },
    current: {type:Number}
  },
  setup(props, {expose, emit}) {
    const state = reactive({
      visible: false,
      status: '',
      encryptTypeList:[]

    })
    const formData = reactive({
      insName: '',
      portName: '',
      parentColumnName: undefined,
      column: '',
      name: '',
      ipId: '',
      id: undefined,
      columnType: undefined,
      defaultValue: undefined,
      decrypt: '0',
      decryptType: undefined,
      encrypt: '0',
      encryptType: undefined,
      type: undefined,
      parentId: 0,
      parentColumnType: '',
      errorFlag: 1,
      errorMsgFlag: 1
    })
    const listData = reactive({
      tigongfanganziduan: [],
      guanxi: [],

      shifoujiami: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        }
      ],
      xinxibiaoji: [
        {
          label: '是',
          value: 0
        },
        {
          label: '否',
          value: 1
        }
      ],
      shifoujiemi: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        }
      ],
    })
    const formRef = ref()
    const onClose = () => {
      formRef.value.resetFields()
      formData.insName = ''
        formData.id = undefined
        formData.portName = ''
        formData.parentColumnName = undefined
        formData.column = ''
        formData.name = ''
        formData.ipId = ''
        formData.columnType = undefined
        formData.defaultValue = undefined
        formData.decrypt = '0'
        formData.decryptType = undefined
        formData.encrypt = '0'
        formData.encryptType = undefined
        formData.type = undefined
        formData.parentId = 0
        formData.errorFlag = 1
        formData.errorMsgFlag = 1
      state.visible = false
    }
    const onSave = () => {
        // formData.errorFlag = formData.errorFlag ? formData.errorFlag *1 : null,
        // formData.errorMsgFlag = formData.errorMsgFlag ? formData.errorMsgFlag * 1 : null
      let obj = {
        data: formData
      }
      let column=formData.column
      if (column.length>30){
        formData.column=''
        message['warning']('字段名称输入长度不能超过30')
        return
      }
      if(!formData.column){
        return message.warn('字段名称不能为空')
      }
      if(!formData.columnType){
        return message.warn('字段类型不能为空')
      }
      if (state.status === 'addOne') {
        request(getRequestURI('ExcInsure_insert'),obj).then(res => {
          if (res.code === 1) {
            message['success'](res.message)
            emit('select', {ipId: obj.data.ipId,type: obj.data.type}, props.current)
            onClose()
          } else {
            message['error'](res.message)
          }
        })
      } else if(state.status === 'update') {
        request(getRequestURI('ExcInsurerC_update'),obj).then(res => {
          if (res.code === 1) {
            message['success'](res.message)
            emit('select', {ipId: obj.data.ipId,type: obj.data.type}, props.current)
            onClose()
          } else {
            message['error'](res.message)
          }
        })
      } else {
        if(Number(formData.parentColumnType) === 1) return message.warning('上级字段类型为value的数据不允许添加下级')
        request(getRequestURI('ExcInsure_insert'),obj).then(res => {
          if (res.code === 1) {
            message['success'](res.message)
            emit('select', {ipId: obj.data.ipId,type: obj.data.type}, props.current)
            onClose()
          } else {
            message['error'](res.message)
          }
        })
      }
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const inNew = computed(() => {
      if (state.status === 'update') {
        return '调用方接口参数修改'
      } else if (state.status === 'addOne') {
        return '添加同级参数'
      } else {
        return '添加下级参数'
      }
    })
    const showDrawer = (oldData = {}, status) => {
      state.visible = true
      state.status = status
      // state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      // console.log('editNewUser', state.isNew, oldData)
      Object.keys(oldData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      formData.columnType ? formData.columnType += '' : undefined
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['encrypt_mark']}}).then(res => {
        state.encryptTypeList = res.result.encrypt_mark
      })
    }
    const ziduanleixing = ref()
    const yesNoList =ref()
    onBeforeMount(()=>{
      getDictionaryData(ziduanleixing, 'field_type')
      getDictionaryData(yesNoList, 'is_encrypt')
    })
    expose({
      showDrawer
    })
    return {
      yesNoList,
      formRef,
      ziduanleixing,
      ...toRefs(state),
      formData,
      listData,
      onClose,
      inNew,
      onSave
    }
  }
})
</script>

<style scoped>

</style>
