<template>
  <a-drawer
    :title="inNew"
    :width="'60%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="15">
        <a-col span="24">
          <a-form-item style="width: 550px" label="产品名称" name="productName">
            <a-input v-model:value="formData.productName"></a-input>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item style="width: 550px" label="销售渠道" name="saleChannelName">
            <a-select v-model:value="formData.saleChannelCode" placeholder="请选择" :options="listDate.xiaoshouqvdao"
                      @change="xiaoshou($event)">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item>
            标签: &nbsp;<a-button type="primary" @click="onSelectTag" style="margin-right: 15px">选择标签</a-button>
            <a-tag v-for="sot in selectOkTags" :key="sot.id">{{sot.labelName}}</a-tag>
          </a-form-item>
        </a-col>
        <a-col span="2">
          <label>海报类型:</label>
        </a-col>
        <a-col span="11">
          <a-select v-model:value="formData.posterGradeOne" placeholder="请选择" :options="listDate.haibaoyiji"
                    style="width:400px;" @change="yijiliandong($event)">
          </a-select>
        </a-col>
        <a-col span="11">
          <a-select v-model:value="formData.posterGradeTwo" placeholder="请选择" :options="listDate.haibaoerji"
                    style="width:400px;">
          </a-select>
        </a-col>
        <a-col span="24" style="margin-top: 22px">
          <a-form-item label="上传海报:" name="click">
            <upload-file-new @click="tableyvlan" filetype="img" :key="time" :showImg="showImg" @fileChange="uploadCosFileChange"
                             listType="picture" num="1"></upload-file-new>
          </a-form-item>
        </a-col>
        <!--        <a-col span="24" style="margin-top: 22px">-->
        <!--          <a-form-item label="上传海报:" name="logo">-->
        <!--            <upload-file-new filetype="img" :showImg="showImg" @fileChange="uploadCosFileChange" listType="picture" num="1"></upload-file-new>-->
        <!--          </a-form-item>-->
        <!--        </a-col>-->
      </a-row>
      <a-table :data-source="formData.posterList" :columns="columns" v-if="tablelook === '2'">
        <template #bodyCell="{ column, index, record }">
          <template v-if="column.key === 'posterUrl'">
            <img style="width: 50px; height: 50px" :src="record.posterUrl" @click="image(record)"/>
          </template>
          <template v-if="column.key === 'operation'">
            <div>
              <a-button type="text" style="margin-right: 10px; color: orange;" size="small"
                        @click="upUser(record,index)">
                编辑
              </a-button>
              <a-button type="text" size="small" style="color: orange;" @click="addUserSon(index)">
                删除
              </a-button>
            </div>
          </template>
        </template>
      </a-table>
      <a-modal v-model:visible="visible1" title="海报信息设置" @cancel="asd" @ok="handleOk">
        <a-form-item label="海报名称:">
          <a-textarea style="width: 100%" v-model:value="formData.posterName"></a-textarea>
        </a-form-item>
        <a-form-item label="分享文案:">
          <a-textarea style="width: 100%;height: 100px" v-model:value="formData.posterList.shareContent"></a-textarea>
        </a-form-item>
      </a-modal>
      <a-modal :visible="previewVisible" title="查看" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="imgasd"/>
      </a-modal>
      <a-col span="12">
        <a-form-item label="海报二维码">
          <a-switch v-model:checked="erweima"/>&nbsp;企业二维码
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="直播">
          <a-switch v-model:checked="zhibo"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="表单">
          <a-switch v-model:checked="biaodan"/>
        </a-form-item>
      </a-col>
      <a-col span="12">
        <a-form-item label="其他">
          <a-switch v-model:checked="qita"/>
        </a-form-item>
      </a-col>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()" v-if="!isDisable">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
    <!-- 选择标签的弹框 -->
    <a-modal v-model:visible="tagShow" @cancel="treeClose" width="600px" title="选择标签" @ok="handleOk1">
      <div class="modal-box">
        <div style="width:100%">
          <div class="left">
            <a-tree
              :tree-data="tag_Data"
              @select="onSelectTree"
              :destroyOnClose="true"
            >
            </a-tree>
          </div>
          <div class="right">
            <p>已选标签</p>
            <a-tag closable @close="delTag(st)" v-for="st in selectedTags" :key="st.id">{{st.labelName}}</a-tag>
          </div>
        </div>
      </div>
    </a-modal>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, reactive, toRefs} from 'vue'
import uploadFileNew from '../../../components/extended/uploadFileNew'
import {getRequestURI, request} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
import {message} from 'ant-design-vue'

export default defineComponent({
  components: {
    uploadFileNew
  },
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    }
  },
  setup(props, {expose,emit}) {
    const listDate = reactive({
      xiaoshouqvdao: [
      ],
      haibaoyiji: [
        {value: 1, label: '供应商海报'},
        {value: 2, label: '分类海报'},
        {value: 3, label: '定制海报'}
      ],
      haibaoerji: []
    })
    const formData = reactive({
      posterGradeOne: undefined,
      posterGradeTwo: undefined,
      saleChannelName: '',
      productName: undefined,
      posterName: undefined,
      saleChannelCode: '',
      id: '',
      logo: '',
      shelfStatus: '',
      posterList: [],
      // shareContent: ''
      // salesChannel: undefined,
      // payType: 1,
      //
      // otherLink: '',
      // applet: '',
      // appletPath: '',
      // isShelf: false,
      // audioName:'',
      // videoName:'',
      // videoIntroduction:'',
      // txtName: '',
      // txtIntroduction: ''
    })
    const state = reactive({
      time:null,
      tagShow: false,
      treeList: [],
      tag_Data: [],
      selectedTags: [],
      selectOkTags: [],
      erweima: true,
      zhibo: false,
      biaodan: false,
      qita: false,
      logo: '',
      visible: false,
      visible1: false,
      index: '',
      previewVisible: false,
      imgasd: '',
      showImg: [],
      tablelook: '1',
      columns: [
        {
          title: '预览',
          width: 100,
          fixed: 'left',
          align: 'center',
          key: 'posterUrl',
          dataIndex: 'posterUrl'
        },
        {
          title: '海报名称',
          width: 100,
          align: 'center',
          key: 'posterName',
          dataIndex: 'posterName'
        },
        {
          title: '分享文案',
          width: 100,
          align: 'center',
          key: 'shareContent',
          dataIndex: 'shareContent'
        },
        {
          title: '状态',
          width: 100,
          align: 'center',
          key: 'uploadStatus',
          dataIndex: 'uploadStatus'
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 100
        }
      ]
    })
    const tableyvlan = () => {

      console.log(formData.logo)
    }
    const xiaoshou = function (value) {
      console.log(value)
      // saleChannelName
      listDate.xiaoshouqvdao.forEach(item => {
        if (value === item.value) {
          formData.saleChannelName = item.label
        }
      })
      console.log(formData.saleChannelName)
    }
    const showDrawer = (oldData = {}) => {
      // if(oldData.uploadAgreement!==''){
      //   let obj = {}
      //   obj.url = oldData.uploadAgreement
      //   state.showImg.push(obj)
      //   console.log(obj)
      //   console.log(state.showImg,'666')
      // }
      state.visible = true
      console.log(oldData.posterGradeTwo)
      yijiliandong(oldData.posterGradeOne)
      if (props.status === 'update') {
        state.tablelook = '2'
      }
      state.selectedTags = [...oldData.labelNameList]
      state.selectOkTags = [...oldData.labelNameList]
      console.log(state.selectOkTags)
      if(state.selectedTags.length > 0){
        state.selectedTags.forEach(item => {
          state.treeList.push(item.productLabelId)
        })
        console.log(state.treeList)
      }
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
      console.log(formData)
     }
    const onSave = function () {
      if (props.status === 'add') {
        delete formData.logo
        delete formData.id

        let obj = {
          data: {productLabelIdList: [...state.treeList],...formData}
          // data: formData
        }
        request(getRequestURI('pppa-002'), obj).then(res => {
          if (res.code === 1) {
            onClose()
            emit('handleSearch')
            message['success'](res.message)
          } else {
            message['warning'](res.message)
          }
          formData.logo = ''
          formData.id = ''
        })
      } else {
        delete formData.logo
        let obj = {
          data: formData
        }
        request(getRequestURI('pppu-004'), obj).then(res => {
          if (res.code === 1) {
            onClose()
            emit('handleSearch')
            message['success'](res.message)
          } else {
            message['warning'](res.message)
          }
          formData.logo = ''
        })
      }
    }
    const yijiliandong = function (value) {
      listDate.haibaoerji.splice(0)
      formData.posterGradeTwo = undefined
      if (value === 1) {
        const uri = getRequestURI('supplier_querySupplier')
        request(uri, {data:{}}).then(res => {
          console.log(res)
          if (res.code == 1) {
            res.result.forEach(item => {
              let obj = {}
              obj.value = item.supCode
              obj.label = item.supName
              listDate.haibaoerji.push(obj)
            })
            console.log(listDate.haibaoerji)
          }
        })
      } else if (value === 2) {
        listDate.haibaoerji.push(
          {
            label: '寿险',
            value: '1'
          },
          {
            label: '财产险',
            value: '2'
          },
          {
            label: '健康险',
            value: '3'
          }
        )
      }
    }
    const uploadCosFileChange = (data) => {
      console.log(data, '3333333')
      formData.logo = data.url

      state.tablelook = '2'
      formData.posterList.push({
        posterUrl: formData.logo,
        posterName: data.enclosureName,
        uploadStatus: 1
      })
      console.log(formData.logo, '6666')
    }
    const handleCancel = function () {
      state.previewVisible = false
    }
    const inNew = computed(() => {
      if (props.status === 'add') {
        return '海报增加'
      } else {
        return '海报修改'
      }
    })
    const addUserSon = function (index) {
      formData.posterList.splice(index, 1)
    }
    const image = function (value) {
      console.log(value.logo)
      state.imgasd = value.posterUrl
      state.previewVisible = true
    }
    const onClose = function () {
      console.log(state.showImg)
      state.visible = false
      formData.posterGradeOne = undefined,
        formData.posterGradeTwo = undefined,
        formData.saleChannelName = '',
        formData.productName = undefined,
        formData.posterName = undefined,
        formData.saleChannelCode = '',
        formData.shelfStatus = '',
        formData.posterList = [],
        state.treeList = [],
        state.selectedTags = []
        state.selectOkTags = []
        state.tablelook = '1'
        formData.logo = ''
        state.showImg = []
      state.time = new Date().getTime()
    }
    const handleOk = function () {
      formData.posterList.forEach((item, index) => {
        if (index === state.index) {
          item.shareContent = formData.posterList.shareContent
        }
      })
      state.visible1 = false
    }
    const upUser = function (value, index) {
      state.index = index
      console.log(value)
      formData.posterName = value.posterName
      console.log(formData.posterName)
      state.visible1 = true
    }
    // 选择标签按钮
    const onSelectTag = () => {
      state.tagShow = true
      state.tagShow = true
      request(getRequestURI('productLabel_selectListForPage'),{
        data:{},
        pagination:{current: 1,pageSize: 10}
      }).then(res => {
        if(res.code === 1){
          state.tag_Data = getSelectTag(res.result)
        }
      })
    }
    const getSelectTag = (result) =>{
      if(result && result.length > 0){
        let arr = []
        result.forEach(item => {
          let node = {
            id: item.id,
            key: item.id,
            selectable: item.children !== null && item.children.length > 0 ? false : true,
            title: item.labelName,
            children: getSelectTag(item.children)
          }
          arr.push(node)
        })
        return arr
      }else{
        return []
      }
    }
    // 选择标签弹框的数据的回调
    const onSelectTree = (id,content) => {
      if(state.selectedTags.length > 2) return message.warning('最多可选3条数据')
      if(content.selectedNodes.length > 0){
        let node = {
          id: content.selectedNodes[0].key,
          labelName: content.selectedNodes[0].title
        }
        if(state.selectedTags.length > 0){
          state.selectedTags.push(node)
          var result = []
          var obj = {}
          for (var i = 0; i < state.selectedTags.length; i++) {
            if (!obj[state.selectedTags[i].id]) {
              result.push(state.selectedTags[i])
              obj[state.selectedTags[i].id] = true
            }else{
              message.warning('请勿选择相同数据')
            }
          }
          state.selectedTags = result
          console.log(state.selectedTags)
        }else{
          state.selectedTags.push(node)
        }
      }
    }
    // 选择标签的确认回调
    const handleOk1 =() => {
      state.treeList = []
      state.tagShow = false
      // 用于在外面展示 已选标签
      state.selectOkTags = [...state.selectedTags]
      state.selectedTags.forEach(item => {
        state.treeList.push(item.id)
      })
    }
    // 取消
    const treeClose = () => {
      state.selectedTags = [...state.selectOkTags]
    }
    // 已选便签的删除
    const delTag = (tagData) =>{
      let index = state.selectedTags.indexOf(tagData)
      state.selectedTags.splice(index,1)
      console.log('state的', state.selectedTags)
      console.log(state.selectOkTags)
    }
    const init = function () {
      request(getExtendUri('dictionary_query_byType'), {data: {types: ['channel_type']}}).then(res => {
        res.result.channel_type.forEach(item => {
          let obj = {}
          obj.label = item.label
          obj.value = item.value - 1
          listDate.xiaoshouqvdao.push(obj)
        })
      })
    }
    init()
    expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      onClose,
      inNew,
      listDate,
      formData,
      onSelectTag,
      uploadCosFileChange,
      tableyvlan,
      upUser,
      handleOk,
      addUserSon,
      image,
      handleCancel,
      onSave,
      xiaoshou,
      yijiliandong,
      onSelectTree,
      treeClose,
      delTag,
      handleOk1
    }
  }
})

</script>

<style scoped>

</style>
