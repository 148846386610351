<template>
  <a-drawer
    :title="inNew"
    :width="'50%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      :rules="formRef"
      ref="formRef"
      autocomplete="off"
      :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }"
    >
      <!--      <a-form-item label="是否创建标签组" name="superiorLabel">-->
      <!--        <a-radio-group v-model:value="yesNo">-->
      <!--       <a-radio :value="false">否</a-radio>-->
      <!--        <a-radio :value="true">是</a-radio>-->
      <!--        </a-radio-group>-->
      <!--      </a-form-item>-->
      <a-form-item label="上级标签" name="superiorLabel">
        <a-select :disabled="true" v-model:value="formData.superiorLabel" :options="listDate.shangjibiaoqian"
                  @change="bianqiangaibian(formData.superiorLabel)"></a-select>
      </a-form-item>
      <a-form-item label="名称" :rules="[{required:true,message:'必填信息',trigger:'change'}]" name="labelName">
        <a-input v-model:value="formData.labelName"/>
      </a-form-item>
      <a-form-item label="标签值" name="labelValue" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
        <a-input v-model:value="formData.labelValue"/>
      </a-form-item>
      <a-form-item label="启用状态" name="startState" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
        <a-select v-model:value="formData.startState" :options="startstatus"></a-select>
      </a-form-item>
      <a-form-item label="关联关键字" name="keyword">
        <a-textarea v-model:value="formData.keyword"></a-textarea>
      </a-form-item>
      <a-form-item label="顺 序" name="sort"
                   :rules="[{pattern:/^[0-9]+$/, message: '支持正整数格式' ,trigger: 'change'}
          ]"
      >
        <a-input :maxlength="3" v-model:value="formData.sort"/>
      </a-form-item>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, onBeforeMount, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import {getDictionaryData} from '../../../utils/request_extend'

export default defineComponent({
  props: {
    status: {
      type: String,
      require: true,
      default() {
        return 'sd'
      }
    }
  },
  setup(props, {expose, emit}) {
    const formRef = ref()
    const formData = reactive({
      superiorLabel: null,
      labelName: '',
      id: 0,
      labelId: null,
      labelLevel: 1,
      sort: null,
      labelValue: '',
      startState: '',
      keyword: ''
    })
    const listDate = reactive({
      yesNoList: [
        {
          label: '是',
          value: true
        },
        {
          label: '否',
          value: false
        }
      ],
      shangjibiaoqian: []
    })
    const startstatus = ref()
    const onClose = () => {
      formRef.value.clearValidate()
      state.visible = false
      formData.superiorLabel = ''
      formData.labelName = ''
      formData.labelValue = ''
      formData.startState = ''
      formData.sort = null,
        formData.labelLevel = 1,
        formData.keyword = ''
      formData.labelId = null
    }
    onBeforeMount(()=>{
      getDictionaryData(startstatus, 'status')
    })
    const state = reactive({
      yesNo: false,
      visible: false,
      isNew: true,
      result: [],
      status: '',
      activeKey: ref('1'),
      transferStyle: {width: '50%'},
      roleTitles: ['未添加授权角色列表', '已添加角色列表'],
      roleData: []
    })
    const showDrawer = (oldData = {}, status) => {
      state.status = status
      state.visible = true
      if (status === 'add') {

        state.yesNo = true
      } else {
        if(formData.labelLevel === 1) formData.superiorLabel = '当前已为顶级标签，无上级标签'
        state.yesNo = false
      }
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      Object.keys(formData).forEach((key) => {
        formData[key] = oldData[key] || formData[key]
      })
    }
    const bianqiangaibian = function (value) {
      console.log(value)
      // eslint-disable-next-line vue/no-mutating-props
      state.status = 'addSon'
      console.log(props.status)
      state.result.forEach(item => {
        if (item.labelName === value) {
          formData.labelId = item.id
          formData.labelLevel = item.labelLevel
        }
      })
    }
    const onSave = async function () {
      const values = await formRef.value.validate()
      console.log(values)
      // if (state.yesNo === false) {
      //   console.log('sss', formData.superiorLabel)
      //   if (formData.superiorLabel === '' || formData.superiorLabel === undefined || formData.superiorLabel === null) {
      //     message.warning('上级标签不能为空')
      //     return
      //   }
      // }
      if (formData.labelName === '' || formData.labelName === undefined) {
        message.warning('名称不能为空')
        return
      }
      if (formData.labelValue === '' || formData.labelValue === undefined) {
        message.warning('标签值不能为空')
        return
      }
      // let biaoqianzhiyuanzheng = /^[1-9][0-9]*$/
      // let biaoqianzhi = formData.labelValue
      // let yuanzheng = biaoqianzhiyuanzheng.test(biaoqianzhi)
      // if (!yuanzheng) {
      //   message.warning('标签值只允许输入数字')
      //   return
      // }
      if (!formData.startState) {
        message.warning('启用状态不能为空')
        return
      }

      if (state.status === 'add') {
        delete formData.id
        let obj = {
          data: formData
        }
        request(getRequestURI('productLabel_insert'), obj).then(res => {
          formData.id = 0
          if (res.code === 1) {
            message['success'](res.message)
            onClose()
            emit('select')
          } else {
            message['error'](res.message)
          }
        })
      } else if (props.status === 'update') {
        // let obj = {
        //   data: formData
        // }
        request(getRequestURI('productLabel_update'), {data: [formData]}).then(res => {
          if (res.code === 1) {
            message['success'](res.message)
            onClose()
            emit('select')
          } else {
            message['error'](res.message)
          }
        })
      } else {
        if (formData.labelLevel === 1) {
          formData.labelLevel = 2
        } else if (formData.labelLevel === 2) {
          formData.labelLevel = 3
        }
        delete formData.id
        let obj = {
          data: formData
        }
        request(getRequestURI('productLabel_insertOne'), obj).then(res => {
          formData.id = 0
          if (res.code === 1) {
            message['success'](res.message)
            onClose()
            emit('select')
          } else {
            message['error'](res.message)
          }
        })
      }
    }
    const inNew = computed(() => {
      if (props.status == 'update') {
        return '修改标签信息'
      } else if (props.status == 'add') {
        return '增加标签组'
      } else {
        return '增加下级'
      }
    })
    const init = function () {
      request(getRequestURI('productLabel_selectListForPageTwo'), {data: {}}).then(res => {
        if (res.code === 1) {
          state.result = res.result
          res.result.forEach(item => {
            let obj = {}
            obj.label = item.labelName
            obj.value = item.labelName
            listDate.shangjibiaoqian.push(obj)
          })
        }
      })
    }
    init()
    expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      onClose,
      formData,
      init,
      onSave,
      bianqiangaibian,
      listDate,
      inNew,
      formRef,
      startstatus
    }
  }
})

</script>

<style scoped>

</style>
