export const detailModal =
  '二, 映射方式限制, 按照优先级排序\n' +
  '  1. 不区分目标方数据类型：\n' +
  '        1. js脚本\n' +
  '                说明：\n' +
  '                        优先执行脚本，将返回值映射到目标字段后返回，目标字段支持多种类型格式，如Value、Map、List。\n' +
  '                注意项：\n' +
  '                        a) Js脚本要求至少有1个或多个映射字段。如果为单个映射字段，那么在js文件中脚本方法的入参即为映射字段值，\n' +
  '                             如果为多映射字段，需要通过”脚本入参.key” 来获取每个映射字段的值，key即为映射字段名称\n' +
  '                        b) 如果目标字段是Map或List，其下级属性配置的映射会失效，所以需要在脚本中对目标字段的属性也一同进行处理\n' +
  '                校验方式：\n' +
  '                        a) 校验至少有1个或多个映射字段\n' +
  '                        b) 校验该层级是否为Map或List，如果下面有映射，则阻断放回提示\n' +
  '  2. 目标类型为List类型：\n' +
  '        1. List多对一映射：\n' +
  '                说明：\n' +
  '                        将一个或多个Map或List类型的映射字段映射到一个集合中\n' +
  '                注意项：\n' +
  '                        a) 要求至少有1个或多个映射字段\n' +
  '                        b) 映射字段类型必须为Map或List类型，目标字段为List类型\n' +
  '                        c) Map或List的属性字段依旧可以映射\n' +
  '                校验方式：\n' +
  '                        a) 目标类型为List\n' +
  '                        b) 要求至少有1个或多个映射字段\n' +
  '                        c) 映射字段类型必须为Map或List类型，目标字段为List类型\n' +
  '        2. 健康告知\n' +
  '                说明：\n' +
  '                        会执行健康告知的特殊处理逻辑\n' +
  '                注意项：\n' +
  '                        a)        健康告知类型的功能，针对性很强，仅处理核保中健康告知集合的转换逻辑\n' +
  '                        b)        要求至少有一个或多个映射字段\n' +
  '                        c)        映射字段类型必须为系统内投保人、被保人、代理人健康告知集合\n' +
  '                        d)        每个保司都要实现一个健康告知的代码适配器，接口为HealthToldHandleService\n' +
  '                        e)        后台会根据映射字段，并补充投被保人的社保，性别，年龄字段，作为参数，传递到健康告知的适配器中\n' +
  '                校验方式：\n' +
  '                        a) 目标类型为List\n' +
  '                        b) 要求至少有1个或多个映射字段\n' +
  '                        c) 映射字段的层级必须符合我们系统中预设的健康告知结构\n' +
  '                        \n' +
  '  3. 目标类型为Value类型：\n' +
  '        1. 外部结构字段映射：\n' +
  '                说明：\n' +
  '                        目标字段映射值不为空，会优先判断映射方式是否为“外部结构字段映射” 映射，如果符合条件，该字段不光会在\n' +
  '                        当前映射条件下进行匹配，还会全局寻找匹配字段\n' +
  '                注意项：\n' +
  '                        a) 要求仅有1个映射字段\n' +
  '                校验方式：\n' +
  '                        a) 目标类型为Value\n' +
  '                        b) 要求只能有1个映射字段\n' +
  '                        c) 映射字段类型为Value\n' +
  '        2. 获取投保要素值映射：\n' +
  '                说明：\n' +
  '                        从投保要素集合中获取到匹配的投保要素值。如果符合条件，规定入参必须为投保要素集合，无匹配项则返回null\n' +
  '                注意项：\n' +
  '                        a) 要求仅有1个映射字段\n' +
  '                        b) 映射字段必须为投保要素集合\n' +
  '                校验方式：\n' +
  '                        a) 目标类型为Value\n' +
  '                        b) 要求只能有1个映射字段\n' +
  '                        b) 映射字段必须为投保要素集合，映射字段的层级必须符合我们系统中预设的健康告知结构\n' +
  '        3. 泰康流水号:\n' +
  '                说明：\n' +
  '                        该方式针对性比较强，是为了适配泰康流水号“年月日-递增序号”的格式而创建的，只在泰康流水号字段映射使用。\n' +
  '                注意项：\n' +
  '                        a) 无映射字段\n' +
  '                        b) 映射目标字段必须为泰康的流水号字段\n' +
  '                校验方式：\n' +
  '                        a) 目标类型为Value\n' +
  '                        b) 可以没有映射字段，或者固定一个字段\n' +
  '                        c) 映射目标字段必须为泰康的流水号字段，映射字段的层级必须符合泰康流水号结构\n' +
  '        4. 默认值\n' +
  '                说明：\n' +
  '                        不满足1-3的映射条件，同时映射值为空条件下，会判断该字段是否有配置默认值，如果符合条件，填充默认值，\n' +
  '                        如果不符合，则填入空值（空值可能为null或空字符串）\n' +
  '                注意项：\n' +
  '                        a) 在提供方接口参数管理下给字段设置了默认值\n' +
  '        5. 值映射\n' +
  '                说明：\n' +
  '                        当根据映射值能找到预设的字典值时，直接赋值。找不到时还会查找设置了关键字“default_value”的默认值，\n' +
  '                        找到则赋默认值，否则赋原映射值\n' +
  '                注意项：\n' +
  '                        a) 要求只能有1个映射字段\n' +
  '                        b) 映射字段的集合中可以设置“default_value”来设置默认值\n' +
  '                校验方式：\n' +
  '                        a) 目标字段类型为Value\n' +
  '                        b) 映射字段类型为Value\n' +
  '        6. 日期格式转换\n' +
  '                说明：\n' +
  '                        将原日期格式转换成目标格式\n' +
  '                注意项：\n' +
  '                        a) 要求只能有一个映射字段\n' +
  '                        b) 入参的时间格式只能为“yyyy-MM-dd”或“yyyy-MM-dd HH:mm:ss”\n' +
  '                校验方式：\n' +
  '                        a) 目标字段类型为Value\n' +
  '                        b) 映射字段类型为Value\n' +
  '        7. 值计算\n' +
  '                说明：\n' +
  '                        将原字段值经过计算后的值赋值到目标字段上\n' +
  '                注意项：\n' +
  '                        a) 要求只能有一个映射字段\n' +
  '                        b) 映射值为数字类型，支持加减乘除计算方式\n' +
  '                校验方式：\n' +
  '                        a) 目标字段类型为Value\n' +
  '                        b) 映射字段类型为Value\n' +
  '        8. 截取值：\n' +
  '                说明：\n' +
  '                        将原字段值经过截取后的值赋值到目标字段上\n' +
  '                注意项：\n' +
  '                        a) 要求只能有一个映射字段\n' +
  '                        b) 填写的下标要和映射值长度匹配\n' +
  '                校验方式：\n' +
  '                        a) 目标字段类型为Value'

export const detailSource= [
  {
    id: '1',
    name1: 'List',
    name2: 'List',
    name3: '支持',
    name4: 'a) 在配置时，目标List的属性不能映射非映射字段List中的属性，如目标List为A，映射List为B，结果A下属性a1映射了ListC的属性c1;\n' +
            'b) 目标List的属性不能映射非映射字段List同层级的属性，如目标List为C，链路为A->B->C，AB均为List类型，映射List为Z，链路为X->Y->Z，XY也均为List类型。结果C下属性c1映射了Y的属性y1;\n' +
            'c) 目标List的属性可以映射链路中不包含List层级的属性值，如目标List为A，结果A下属性a1映射了Map类型C的属性c1;'
  },
  {
    id: '2',
    name1: 'List',
    name2: 'Map',
    name3: '支持',
    name4: '只能将Map类型映射到List集合第一个位置'
  },
  {
    id: '3',
    name1: 'List',
    name2: 'Value',
    name3: '支持',
    name4: '只能将Value值型映射到List集合第一个位置，且List集合只能是基础数据类型，校验该集合下只有一个值'
  },
  {
    id: '4',
    name1: 'Map',
    name2: 'List',
    name3: '支持',
    name4: '将list第1位映射到Map上;'
  },
  {
    id: '5',
    name1: 'Map',
    name2: 'Map',
    name3: '支持',
    name4: ''
  },
  {
    id: '6',
    name1: 'Map',
    name2: 'Value',
    name3: '不支持',
    name4: ''
  },
  {
    id: '7',
    name1: 'Value',
    name2: 'List',
    name3: '支持',
    name4: '将list第1位映射到Value上，List的泛型必须是基础数据类型，校验该集合下只有一个值'
  },
  {
    id: '8',
    name1: 'Value',
    name2: 'Map',
    name3: '不支持',
    name4: ''
  },
  {
    id: '9',
    name1: 'Value',
    name2: 'Value',
    name3: '支持',
    name4: ''
  }
]
