<template >
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUsers('newAdd')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>

    <a-button danger @click="daochuXML">
      <template #icon>
        <ToTopOutlined />
      </template>
      导出为XML
    </a-button>
    <a-button danger @click="daochuJSON">
      <template #icon>
        <ToTopOutlined />
      </template>
      导出为JSON
    </a-button>
     <a-button danger @click="delMultiple">
      <template #icon>
        <DeleteOutlined/>
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :indentSize="30"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-key="(record) => record.id"
    :pagination="pagination"
    :key="time"
    @change="handleTableChange"
    :loading="loading"
  >
    <!--    :expandIcon="expandIcon"-->
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a-button
            type="text"
            style="color: orange"
            size="small"
            @click="upUser(record, 'edit')"
          >
            编辑
          </a-button>
          <a-button
            type="text"
            style="color: orange"
            size="small"
            @click="deleteOne(record, index)"
            >删除
          </a-button>
          <a-button
            type="text"
            style="color: orange"
            size="small"
            @click="selUser(record, 'details')"
            >添加同级参数
          </a-button>
          <a-button
            type="text"
            size="small"
            style="color: orange"
            @click="addUser(record, 'addSon')"
          >
            添加下级参数</a-button
          >
          <!--          v-if="record.supGrade!='4'"-->
        </div>
      </template>
    </template>
  </a-table>
  <editAgent
    ref="userDrawerRef"
    :status="status"
    :handleSearch="handleSearch"
    :lastFilter="lastFilter"
    :current="pagination.current"
  ></editAgent>
  <ParameterImport ref="daoRuRef"></ParameterImport>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import editAgent from './InterfaceProviderParametersEdit'
import ParameterImport from './InterfaceProviderParametersAdd'
// import external from './externalSystemSynchronization'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import {message, Modal} from 'ant-design-vue'

export default defineComponent({
  components: {
    editAgent,
    SearchForm,
    ParameterImport,
  },
  setup() {
    onMounted(() => {
      getjiekou()
    })
    const userDrawerRef = ref()
    const daoRuRef = ref()
    const state = reactive({
      lastFilter: {},
      time: null,
      del: '',
      deleteQuery: {},
      dataSourceList: [],
      condition: {
        portId: {
          label: '接口名称',
          type: 'select',
          default: '',
          rules: [{ required: true, message: '必填信息', trigger: 'change' }],
          data: []
        },
        type: {
          label: '参数类型',
          type: 'select',
          rules: [{ required: true, message: '必填信息', trigger: 'change' }],
          urlKey: 'queryDictionaryType',
          queryParam: { type: 'paramterType' }
        }
      },
      columns: [
        {
          title: '字段名称',
          dataIndex: 'column',
          key: 'column',
          align: 'center',
          fixed: 'left',
        },
        {
          title: '字段类型',
          align: 'center',
          dataIndex: 'columnType',
          key: 'columnType',
          customRender: (text) => {
            if (text.value === 1) {
              return 'Value'
            } else if (text.value === 2) {
              return 'Map'
            } else if (text.value === 3) {
              return 'List'
            } else {
              return 'Boolean'
            }
          }
        },
        {
          title: '参数类型',
          align: 'center',
          dataIndex: 'type',
          key: 'type',
          customRender: (text) => {
            if (text.value === 1) {
              return '入参'
            } else {
              return '出参'
            }
          }
        },
        {
          title: '层级关系',
          align: 'center',
          dataIndex: 'relationship',
          key: 'relationship'
        },
        {
          title: '字段说明',
          align: 'center',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 300,
          scopedSlots: { customRender: 'operation' }
        }
      ],
      status: '',
      tableList: [],
      selectedRowKeys: [],

      query: {
        data: {
          ipId: '',
          portId: '',
          type: ''
        }
      }
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ExcPortConfig_selectTree'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter: [{
          field: '',
          order: ''
        }],
        data: state.lastFilter,
      })
    }//页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters, num='') {
      state.lastFilter = filters
      run({
        pagination: {
          current: num ?num : 1,
          pageSize: 10
        },
        sorter: [{
          field: '',
          order: ''
        }],
        data: filters,
      })
    }

    //搜索
    // const handleSearch = function (filters) {
    //   state.time = new Date().getTime()
    //   state.lastFilter.portId = filters.portId
    //   state.lastFilter.type = filters.type
    //   let uri = getRequestURI('ExcPortConfig_selectTree')
    //   request(uri, state.query).then(res=>{
    //     console.log(res,'123')
    //     state.dataSourceList = res.result
    //     expandIcon()
    //   })
    // }
    //删除按钮
    const deleteOne = (record) => {
      state.deleteQuery = record.id
      let data = {
        id: state.deleteQuery
      }
      // visible.value = true
      Modal.confirm({
        title: '确认删除吗？',
        async onOk () {
          let uri = getRequestURI('ExcPortConfig_delete')
          request(uri, { data }).then(
            (result) => {
              if (result.code === 1) {
                message['success']('删除成功')
                handleSearch(state.lastFilter)
              } else {
                message['warning'](result.message)
              }
            }
          )
        },
        onCancel () {
        }
      })
    }
    const expandIcon = function () {
      state.dataSourceList.forEach((a) => {
        return a
      })

    }
    //新增
    const addUsers = function (status, aaa) {
      console.log(status)
      state.status = status
      aaa = state.lastFilter.portId
      daoRuRef.value.showDrawerOne(status, aaa)
    }
    //添加下级参数
    const addUser = function (record, status) {
      console.log(record)
      state.status = status
      let obj = {
        id: record.id
      }
      const uri = getRequestURI('ExcPortConfig_selectById')
      request(uri, { data: obj }).then(res => {
        userDrawerRef.value.showDrawer(res.result, status,state.lastFilter)
      })

    }
    //编辑
    const upUser = function (record, status) {
      state.status = status
      let obj = {
        id: record.id
      }
      const uri = getRequestURI('ExcPortConfig_selectById')
      request(uri, { data: obj }).then(res => {
        userDrawerRef.value.showDrawer(res.result, status,state.lastFilter)
      })
    }
    //添加同级参数
    const selUser = function (record, status) {
      state.status = status
      let obj = {
        id: record.id
      }
      const uri = getRequestURI('ExcPortConfig_selectById')
      request(uri, { data: obj }).then(res => {
        userDrawerRef.value.showDrawer(res.result, status,state.lastFilter)
      })
    }
    //导出为JSON
    const daochuJSON = function () {
      console.log(state.lastFilter)
      if (state.lastFilter.portId !== '' || state.lastFilter.ipId !== '' || state.lastFilter.type !== '') {
        // state.lastFilter.ipId=state.lastFilter.portId
        let query = {
          data: {
            ipId: state.lastFilter.ipId,
            portId: state.lastFilter.portId,
            type: state.lastFilter.type
          }
        }
        request(getRequestURI('ExcExport_exportJson'),
          query
          , true, 'post').then(
            (result) => {
              console.log('导出的result', result)
              message.success(result.message)
              window.location = result.result
            }
          ).catch(e => {
            // console.log(e)
            message.error(e)
          })
      } else {
        message['error']('请先查询数据,再选择数据后,导出JSON文档')
      }


    }
    //导出为XML
    const daochuXML = function () {
      if (state.lastFilter.portId !== '' || state.lastFilter.ipId !== '' || state.lastFilter.type !== '') {
        let query = {
          data: {
            ipId: state.lastFilter.ipId,
            portId: state.lastFilter.portId,
            type: state.lastFilter.type
          }
        }
        request(getRequestURI('ExcExport_exportXml'),
          query
          , true, 'post').then(
            (result) => {
              console.log('导出的result', result)
              message.success(result.message)
              window.location.href = result.result
            }
          ).catch(e => {
            // console.log(e)
            message.error(e)
          })
      } else {
        message['error']('请先查询数据,再选择数据后,导出XML文档')
      }
    }

    //获取接口名称
    const getjiekou = () => {
      request(getRequestURI('ExcPort_selectName'), {}, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          for (let i = 0; i < res.length; i++) {
            arr.push({ value: res[i].id, label: res[i].name })
          }
          state.condition.portId.data = arr
        }
      )
    }
    //统一删除
    const delMultiple = () => {
      if (state.lastFilter.portId !== '' || state.lastFilter.type !== '') {
        Modal.confirm({
          title: '删除提示',
          content: '确定删除全部数据吗?',
          onOk: () => {
            request(getRequestURI('ExcPortConfig_deleteTree'), {
              data: {portId: state.lastFilter.portId, type: state.lastFilter.type } }).then(res => {
              if (res.code === 1) {
                message['success']('删除成功')
                handleSearch({})
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message['warning']('请把数据查询出来再删除')
      }

    }
    return {
      userDrawerRef,
      addUser,
      addUsers,
      upUser,
      selUser,
      ...toRefs(state),
      handleSearch,
      deleteOne,
      daoRuRef,
      daochuJSON,
      daochuXML,
      getjiekou,
      expandIcon,
      delMultiple,
      dataSource,
      loading,
      pagination,
      handleTableChange
    }
  },
})
</script>
<style scoped>
</style>
