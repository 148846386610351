<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
    @call="changes"
    @resetAll="resetAll"
  ></search-form>
  <div class="btn-bar">
<!--    <a-button danger @click="zidongpipei()">-->
<!--      <template #icon>-->
<!--        <FileAddOutlined/>-->
<!--      </template>-->
<!--      自动匹配-->
<!--    </a-button>-->
<!--    <a-button type="primary" @click="handelSyncSourceData">-->
<!--      <template #icon>-->
<!--        <check-outlined/>-->
<!--      </template>-->
<!--      同步源数据-->
<!--    </a-button>-->
    <a-button @click="lookRuleDescription">
      <template #icon>
        <block-outlined />
      </template>
      规则说明
    </a-button>
    <a-button danger @click="delMultiple">
      <template #icon>
        <DeleteOutlined/>
      </template>
      删除
    </a-button>
  </div>
  <a-table
    :columns="paramType=='1'?columns2:columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :indentSize="10"
    :scroll="{ x: 1000}"
    :key="time"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column,record, index }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="modifyProduct(record, 'update')">编辑</a>&nbsp;&nbsp;
<!--          <a style="color: orange" @click="deleteProduct(record)">删除</a>&nbsp;&nbsp;-->
          <a style="color: orange" @click="clearProduct(record)">清空</a>&nbsp;&nbsp;
        </div>
      </template>
    </template>
  </a-table>

  <a-modal title="规则说明" style="width: 1000px;" centered :closable="false" v-model:visible="isIdentical"
           @ok="insuranceErrorHandleOk">
    <div class="modal_class">
      一, 字段结构映射条件，仅支持一对一
      <a-table
        :columns="columnsData"
        :data-source="detailSource"
        :key="time"
        :row-key="(record) => record.id"
        style="white-space: pre-line"
        :pagination="false"
      >
        <template #bodyCell="{ column,index }">
          <template v-if="column.key === 'index'">
            {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
          </template>
        </template>
      </a-table>
      <p style="font-size: 1.01rem;white-space: pre-wrap;color: #333333;margin: 30px 0.25rem 0;">{{ detailModal }}</p>
    </div>
    <template #footer>
      <a-button type="link"
                style="width: 100%;font-size: 20px;text-align: center !important; color: #1677FF;"
                @click="insuranceErrorHandleOk">确认
      </a-button>
    </template>
  </a-modal>
  <editChannelreflect ref="edituser" @handleSearch="handleSearch(lastFilter,pagination.current)" :status="status"></editChannelreflect>
</template>

<script>
import {defineComponent, reactive, toRefs, ref, onMounted, computed} from 'vue'
import SearchForm from '../../../components/extended/search_form'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import editChannelreflect from './editChannelreflect'
import {message, Modal} from 'ant-design-vue'
import  {detailModal, detailSource} from './modal'

export default defineComponent({
  components: {
    SearchForm,
    editChannelreflect
  },
  setup() {
    onMounted(() => {
      getjiekou()
    })
    const edituser = ref()
    const columns = computed(()=> {
      return [
        {
          title: '序号',
          width: 15,
          align: 'center',
          key: 'index',
          dataIndex: 'index',
          fixed: 'left'
        },
        {
          title: '调用方字段名称',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'icolumnList' : 'icolumn',
          dataIndex: state.paramType === '1' ? 'icolumnList' : 'icolumn',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },
        {
          title: '调用方层级关系',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'irelationshipList' : 'irelationship',
          dataIndex: state.paramType === '1' ? 'irelationshipList' : 'irelationship',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },
        {
          title: '参数类型',
          width: 5,
          align: 'center',
          key: 'type',
          dataIndex: 'type',
          customRender: (text)=>{
            if (text.value === 1){
              return '入参'
            }else {
              return  '出参'
            }
          }
        },
        {
          title: '提供方字段名称',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'pcolumn' : 'pcolumnList',
          dataIndex: state.paramType === '1' ? 'pcolumn' : 'pcolumnList',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },
        {
          title: '提供方层级关系',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'prelationship' : 'prelationshipList',
          dataIndex: state.paramType === '1' ? 'prelationship' : 'prelationshipList',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 10,
          fixed: 'right'
        }
      ]
    })
    const columns2 = computed(()=> {
      return [
        {
          title: '序号',
          width: 15,
          align: 'center',
          key: 'index',
          dataIndex: 'index',
          fixed: 'left'
        },
        {
          title: '提供方字段名称',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'pcolumn' : 'pcolumnList',
          dataIndex: state.paramType === '1' ? 'pcolumn' : 'pcolumnList',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },
        {
          title: '提供方层级关系',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'prelationship' : 'prelationshipList',
          dataIndex: state.paramType === '1' ? 'prelationship' : 'prelationshipList',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },

        {
          title: '参数类型',
          width: 5,
          align: 'center',
          key: 'type',
          dataIndex: 'type',
          customRender: (text)=>{
            if (text.value === 1){
              return '入参'
            }else {
              return  '出参'
            }
          }
        },
        {
          title: '调用方字段名称',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'icolumnList' : 'icolumn',
          dataIndex: state.paramType === '1' ? 'icolumnList' : 'icolumn',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },
        {
          title: '调用方层级关系',
          width: 10,
          align: 'center',
          key: state.paramType === '1' ? 'irelationshipList' : 'irelationship',
          dataIndex: state.paramType === '1' ? 'irelationshipList' : 'irelationship',
          customRender: (text)=>{
            return Array.isArray(text.value) ? text.value.join(',') : text.value
          }
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 10,
          fixed: 'right'
        }
      ]
    })
    const columnsData = computed(()=> {
      return [
        {
          title: '序号',
          width: 15,
          align: 'center',
          key: 'index',
          dataIndex: 'index',
          fixed: 'left'
        },
        {
          title: '提供方',
          width: 70,
          align: 'center',
          key: 'name1',
          dataIndex: 'name1'
        },
        {
          title: '调用方',
          width: 70,
          align: 'center',
          key: 'name2',
          dataIndex: 'name2'
        },
        {
          title: '是否支持',
          width: 70,
          align: 'center',
          key: 'name3',
          dataIndex: 'name3'
        },
        {
          title: '描述',
          width: 400,
          align: 'left',
          key: 'name4',
          dataIndex: 'name4'
        },
      ]
    })
    const state = reactive({
      isIdentical: false,
      detailModal: detailModal,
      detailSource: detailSource,
      lastFilter:{},
      time: null,
      query: {
        data: {
          ipId: '',
          type: ''
        }
      },
      paramType: '1',
      selectData: [],
      dataInterface: [],
      selectedRowKeys: [],
      dataSource1: [],
      status: '',
      condition: {
        insId: {
          type: 'select1',
          label: '调用方名称',
          default: '',
          data: []
        },
        ipId: {
          type: 'select1',
          label: '接口名称',
          default: '',
          data: []
        },
        type: {
          type: 'select1',
          label: '参数类型',
          urlKey: 'queryDictionaryType',
          queryParam: {type: 'paramterType'}
        }
      }
    })

    //获取接口名称
    const getjiekou = () => {
      request(getRequestURI('select_treeLeft'), {}).then(res => {
        state.dataInterface = res.result
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.insId
          obj.label = item.insName
          state.condition.insId.data.push(obj)
        })
      })
    }
    const insuranceErrorHandleOk = () =>{
      state.isIdentical =false
    }
    const lookRuleDescription = ()=>{
      state.isIdentical = true
    }
    const resetAll = () => {
      // console.log('2222')
      state.lastFilter={}
      changes()
    }
    const changes = function (value) {
      console.log('11111',value,state.condition.insId)
      state.condition.ipId.data = []
      state.dataInterface.forEach(res => {
        if (res.insId === value) {
          res.children.forEach(item => {
            let obj = {}
            obj.value = item.ipId
            obj.label = item.name
            state.condition.ipId.data.push(obj)
          })
        }
      })
    }
    // const deleteProduct = function(value) {
    //   delete value.children
    //   value.ipId = state.lastFilter.ipId
    //   Modal.confirm({
    //     title: '删除提示',
    //     content: '确定删除此数据吗?',
    //     onOk: () => {
    //       request(getRequestURI('ExcInsurerPortConfig_delete123'), {data: value}).then(res =>{
    //         if (res.code === 1) {
    //           message.success('删除成功')
    //           handleSearch({ipId :state.lastFilter.ipId, type: state.lastFilter.type})
    //         } else {
    //           message.warning(res.message)
    //         }
    //       })
    //     },
    //     onCancel: () => {
    //       Modal.destroyAll()   // 清除弹出框
    //     }
    //   })
    // }
    const clearProduct = (item) => {
      console.log(JSON.stringify(item))
      Modal.confirm({
        title: '清空提示',
        content: '确定清空此数据映射的值吗?',
        onOk: () => {
          request('/edt-service/edt/ExcInsurerPortConfig/reset', {data:{
            id: item.id,
            type:item.type
            }}
          ).then(res =>{
            if (res.code === 1) {
              message.success('清空成功')
              handleSearch({ipId :state.lastFilter.ipId, type: state.lastFilter.type})
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          Modal.destroyAll()   // 清除弹出框
        }
      })
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ExcInsurerConfig_selectList1'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter:[{
          field:'',
          order:''
        }],
        data:state.lastFilter,
      })
    }
    const handleSearch = function (filters, num = '') {

      state.lastFilter = filters
      console.log('filters',filters,state.lastFilter)
      if (filters && filters.type) {
        state.paramType = filters.type
      } else {
        state.paramType = '1'
      }
      run({
        pagination: {
          current: num? num : 1,
          pageSize: 10
        },
        sorter:[{
          field:'',
          order:''
        }],
        data: filters,
      })
    }

    //搜索
    // const handleSearch = function (filters) {
    //   state.time = new Date().getTime()
    //   console.log(filters, 123456)
    //   state.query.data.ipId = filters.ipId
    //   state.query.data.type = filters.type
    //   let uri = getRequestURI('ExcInsurerConfig_selectList1')
    //   request(uri, state.query).then(res => {
    //     console.log(res, '123')
    //     state.dataSource1 = res.result
    //   })
    // }
    const modifyProduct = function (record, status) {
      console.log(state.lastFilter, record)
      state.selectData = record
      record.ipId = state.lastFilter.ipId
      request(getRequestURI('ExcInsurerPortC_SQ'),{data: state.selectData}).then(res => {
        if (res.code === 1) {
          edituser.value.showDrawer(res.result)
        }
      })
      state.status = status
    }

    const zidongpipei = function () {
      if (state.lastFilter.ipId == '') {
        message['warning']('接口名称不能为空')
        return
      } else {
        request(getRequestURI('ExcInsurerPortC_match'), {
          data: {
            ipId: state.lastFilter.ipId,
            type: state.lastFilter.type
          }
        }).then(res => {
          if (res.code === 1) {
            message['success'](res.message)
          } else {
            message['error'](res.message)
          }
        })
      }
    }
    // 同步源数据
    const handelSyncSourceData = () =>{
      if (state.lastFilter.ipId == '') {
        message['warning']('接口名称不能为空')
        return
      } else {
        request(getRequestURI('ExcInsurerPortConfig_incrementMatch'), {
          data: {
            ipId: state.lastFilter.ipId,
            type: state.lastFilter.type
          }
        }).then(res => {
          if (res.code === 1) {
            message['success'](res.message)
            handleSearch(state.lastFilter)
          } else {
            message['error'](res.message)
          }
        })
      }
    }

    //统一删除
    const delMultiple = () => {
      console.log('state',state.lastFilter)
      if (!state.lastFilter.insId) {
        return message.warning('请把数据查询出来之后删除')
      }
      if (!state.lastFilter.ipId) {
        return message.warning('请把数据查询出来之后删除')
      }
      if (!state.lastFilter.type) {
        return message.warning('请把数据查询出来之后删除')
      }
        Modal.confirm({
          title: '删除提示',
          content: '确定删除此数据吗?',
          onOk: () => {
            request(getRequestURI('ExcInsurerConfig_deleteAll'),
              { data: { insId: state.lastFilter.insId, ipId: state.lastFilter.ipId, type: state.lastFilter.type } }).then(res => {
              console.log(res)
              if (res.code === 1) {
                message['success']('删除成功')
                handleSearch({})
              } else {
                message['error'](res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })


    }

    return {
      ...toRefs(state),
      modifyProduct,
      edituser,
      getjiekou,
      handleSearch,
      zidongpipei,
      handelSyncSourceData,
      delMultiple,
      changes,
      dataSource,
      loading,
      pagination,
      handleTableChange,
      // deleteProduct,
      clearProduct,
      columns,
      columns2,
      columnsData,
      resetAll,
      insuranceErrorHandleOk,
      lookRuleDescription,
    }
  }
})
</script>

<style scoped>
.modal_class{
  max-height: 600px;
  overflow: auto;
}
</style>
