<style scoped>
</style>

<template>
  PageTemplate
</template>

<script>
import {defineComponent, reactive, toRefs} from 'vue'

export default defineComponent({
  components: {},
  setup() {
    const state = reactive({
    })

    return {
      ...toRefs(state),
    }
  }
})
</script>
