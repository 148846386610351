<template>
  <search-form
    :Condition="condition"
    :ColSpan="8"
    @callback="handleSearch"
    @call="changes"
    @resetAll="chan"
  ></search-form>
  <div class="select-label">
    标签 : &nbsp;<a-button type="primary" @click="onSelectTag"
      >选择标签</a-button
    >
    <a-tag v-for="sot in selectOkTags" :key="sot.id">{{ sot.value }}</a-tag>
  </div>
  <!-- 选择标签的弹框 -->
  <a-modal
    v-model:visible="visible"
    @cancel="close"
    width="600px"
    title="选择标签"
    @ok="handleOk"
  >
    <div class="modal-box">
      <div style="width: 100%">
        <div class="left">
          <a-tree
            v-model:selectedKeys="selectedKeys"
            :tree-data="tag_Data"
            @select="onSelectTree"
            :destroyOnClose="true"
          >
          </a-tree>
        </div>
        <div class="right">
          <p>已选标签</p>
          <a-tag
            closable
            @close="delTag"
            v-for="st in selectedTags"
            :key="st.id"
            >{{ st.value }}</a-tag
          >
        </div>
      </div>
    </div>
  </a-modal>
  <a-divider></a-divider>

  <div class="butt">
    <a-button class="a-butt" @click="saveMaterial('add')">新增</a-button>
    <a-button class="a-butt" @click="onShelf()">上架</a-button>
    <a-button class="a-butt" @click="onOffShelf()">下架</a-button>
    <a-button class="a-butt" @click="del">删除</a-button>
  </div>
  <div class="a-check">
    <poster-tab
      ref="delrt"
      @getIds="getIds"
      :isShow="isShow"
      :data="data"
      :dataType="dataType"
    ></poster-tab>
  </div>
  <div class="a-pag">
    <div></div>
    <!-- v-model:current="pagination.current" -->
    <a-pagination
      v-model:pageSize="pagination.pageSize"
      :total="pagination.total"
      @change="onShowSizeChange"
    />
  </div>
  <posteredit
    ref="useredit"
    @handleSearch="handleSearch({})"
    :status="status"
  ></posteredit>
</template>

<script>
import { defineComponent, provide, ref, onMounted, reactive, toRefs } from 'vue'
import posterTab from '../../../components/extended/posterTabb'
import { message, Modal } from 'ant-design-vue'
import SearchForm from '../../../components/extended/search_form'
import Posteredit from './posteredit'
import { getRequestURI, request } from '../../../utils/request'
export default defineComponent({
  components: {
    Posteredit,
    posterTab,
    SearchForm
  },
  setup () {
    const delrt = ref()
    const useredit = ref()
    const state = reactive({
      statusAll: 'status',
      dataType: 'haibao',
      status: '',
      tag_Data: [],
      idList: [],
      condition: {
        productName: '产品名称',
        saleChannelCode: {
          label: '销售渠道',
          type: 'select',
          data: [
            {
              label: '个人',
              value: '1'
            },
            {
              label: '银行',
              value: '2'
            },
            {
              label: '电话',
              value: '3'
            },
            {
              label: '网销',
              value: '4'
            },
            {
              label: '卡单',
              value: '5'
            },
          ]
        },
        shelfStatus: {
          type: 'select',
          label: '上架状态',
          data: [
            {
              label: '已上架',
              value: 0
            },
            {
              label: '待上架',
              value: 1
            },
            {
              label: '下架',
              value: 2
            }
          ]
        },
        posterGradeOne: {
          type: 'select',
          label: '海报类型一级',
          data: [
            {
              label: '供应商海报',
              value: 1
            },
            {
              label: '分类海报',
              value: 2
            },
            {
              label: '定制海报',
              value: 3
            },
          ]
        },
        posterGradeTwo: {
          type: 'select',
          label: '海报类型二级',
          data: [

          ]
        }
      },
      isShow: '2',
      selectOkTags: [],
      selectedKeys: [],
      visible: false,
      treeList: [], // 用于接收选择标签的id
      selectedTags: [], // 用于展示选择标签的已选标签
      data: [],
      selectedRowKeys: []
    })
    const asd = function () {

    }
    // 选择标签按钮
    const onSelectTag = () => {
      state.visible = true
      request(getRequestURI('productLabel_selectListForPage'), {
        data: {},
        pagination: { current: 1, pageSize: 10 }
      }).then(res => {
        if (res.code === 1) {
          state.tag_Data = getSelectTag(res.result)
          console.debug('555555555', state.tag_Data)
        }
      })
    }
    const chan = function () {
      state.selectOkTags.splice(0)
      state.selectedTags.splice(0)
      state.treeList.splice(0)
    }
    const changes = function (value) {
      state.condition.posterGradeTwo.data.splice(0)
      if (value === 1) {

        const uri = getRequestURI('supplier_querySupplier')
        request(uri, { data: {} }).then(res => {
          console.debug(res)
          if (res.code == 1) {
            res.result.forEach(item => {
              let obj = {}
              obj.value = item.supCode
              obj.label = item.supName
              state.condition.posterGradeTwo.data.push(obj)
            })
            console.debug(state.condition.posterGradeTwo.data)
          }
        })

      } else if (value === 2) {
        state.condition.posterGradeTwo.data.push(
          {
            label: '寿险',
            value: '1'
          },
          {
            label: '财产险',
            value: '2'
          },
          {
            label: '健康险',
            value: '3'
          }
        )
      }
    }
    const getSelectTag = (result) => {
      console.debug(result)
      if (result && result.length > 0) {
        let arr = []
        result.forEach(item => {
          let node = {
            id: item.id,
            key: item.id,
            selectable: item.children !== null && item.children.length > 0 ? false : true,
            title: item.labelName,
            children: getSelectTag(item.children)
          }
          arr.push(node)
        })
        return arr
      } else {
        return []
      }
    }
    // 选择标签弹框的数据的回调
    const onSelectTree = (id, content) => {
      if (state.selectedTags.length > 2) return message.warning('最多可选3条数据')
      if (content.selectedNodes.length > 0) {
        let node = {
          id: content.selectedNodes[0].key,
          value: content.selectedNodes[0].title
        }
        if (state.selectedTags.length > 0) {
          state.selectedTags.push(node)
          var result = []
          var obj = {}
          for (var i = 0; i < state.selectedTags.length; i++) {
            if (!obj[state.selectedTags[i].id]) {
              result.push(state.selectedTags[i])
              obj[state.selectedTags[i].id] = true
            } else {
              message.warning('请勿选择相同数据')
            }
          }
          state.selectedTags = result
        } else {
          state.selectedTags.push(node)
        }
      }
    }
    const getIds = (value) => {
      console.debug(value)
      if (value && value.length > 0) {
        Object.assign(state.idList, [...value])
      }
    }
    // 删除
    const del = () => {
      console.debug(state.idList)
      if (state.idList.length > 0) {
        Modal.confirm({
          title: '删除提示',
          content: '确定删除此数据吗?',
          onOk: () => {
            request(getRequestURI('posterDel'),
              {
                data: { ids: [...state.idList] }
              }
            ).then(res => {
              if (res.code === 1) {
                message.success('删除成功')
                delrt.value.deletel()
                state.idList = []
                handleSearch({})
              } else {
                message.warning(res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message.warning('请选择数据,至少一条')
      }
    }
    // 上架
    const onShelf = () => {
      if (state.idList.length > 0) {
        Modal.confirm({
          title: '上架提示',
          content: '确定上架此数据吗?',
          onOk: () => {
            request(getRequestURI('updateP'),
              {
                data: { ids: [...state.idList] }
              }
            ).then(res => {
              if (res.code === 1) {
                message.success('上架成功')
                delrt.value.deletel()
                state.idList = []
                handleSearch({})
              } else {
                message.warning(res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message.warning('请选择数据,至少一条')
      }
    }
    // 下架
    const onOffShelf = () => {
      if (state.idList.length > 0) {
        Modal.confirm({
          title: '下架提示',
          content: '确定下架此数据吗?',
          onOk: () => {
            request(getRequestURI('updateO'),
              {
                data: { ids: [...state.idList] }
              }
            ).then(res => {
              if (res.code === 1) {
                message.success('下架成功')
                delrt.value.deletel()
                state.idList = []
                handleSearch({})
              } else {
                message.warning(res.message)
              }
            })
          },
          onCancel: () => {
            Modal.destroyAll()   // 清除弹出框
          }
        })
      } else {
        message.warning('请选择数据,至少一条')
      }
    }
    // 已选便签的删除
    const delTag = (tagData) => {
      let index = state.selectedTags.indexOf(tagData)
      state.selectedTags.splice(index, 1)
      console.debug('state的', state.selectedTags)
      console.debug(state.selectOkTags)
    }

    const saveMaterial = function (status) {
      state.status = status
      useredit.value.showDrawer()
    }
    // 分页
    const onShowSizeChange = (current, pageSize) => {
      console.debug(current, pageSize)
      pagination.current = current
      pagination.pageSize = pageSize
      handleSearch({})
    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filter) {
      request(getRequestURI('pppq-001'), {
        data: { ...filter, productLabelIdList: [...state.treeList] },
        pagination: {
          current: pagination.current,
          pageSize: 8,
        }
      }).then(res => {
        if (res.result.length === 0) {
          pagination.current = 1
          handleSearch({})
        }
        state.data = res.result
        pagination.total = res.pagination.total
        res.result.forEach((item, index) => {
          if (item.posterList.length !== 0) {
            state.data[index].pictureUrl = item.posterList[0].posterUrl || ''
          }
        })
        state.tab_data = getResult(res.result)
        console.debug(state.data)
      })
    }
    provide('handleSearch', handleSearch)
    const pagination = reactive({
      current: 1,
      pageSize: 8,
      total: 0
    })
    const getResult = (res) => {
      res.forEach(item => {
        if (item.isTop === '1') {
          item.isTop = true
        } else {
          item.isTop = false
        }
      })
    }
    const onSelectChange = (selectedRowKeys, selectedRow) => {
      console.debug('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      console.debug('selectedRowKeys changed: ', selectedRow)
    }

    const handelUpdate = (value) => {
      console.debug(value)
      state.status = 'update'
      request(getRequestURI('pppq-003'), { data: { id: value.id } }).then(res => {
        if (res.code === 1) {
          useredit.value.showDrawer(res.result)
        }
      })
    }
    // 选择标签的确认回调
    const handleOk = () => {
      state.visible = false
      // 用于在外面展示 已选标签
      state.selectOkTags = [...state.selectedTags]
      state.selectedTags.forEach(item => {
        state.treeList.push(item.id)
      })
    }
    // 取消
    const close = () => {
      state.selectedTags = [...state.selectOkTags]
    }
    // // 选择标签的确认回调
    // const handleOk =(e) => {
    //   console.debug(e)
    //   // state.visible = false
    //   // console.debug(88888, state.selectedKeys)
    // }
    // // 选择标签的取消回调
    // const treeClose = (e) =>{
    //   console.debug('取消',e)
    // }
    // // 已选便签的删除
    // const delTag = () =>{
    //
    // }
    // 置顶
    const updateTop = getRequestURI('updateTo')
    provide('updateTop', updateTop)
    provide('handelUpdate', handelUpdate)
    const init = function () {
      const uri = getRequestURI('supplier_querySupplier')
      request(uri, { data: {} }).then(res => {
        console.debug(res)
        if (res.code == 1) {
          res.result.forEach(item => {
            let obj = {}
            obj.value = item.supCode
            obj.label = item.supName
            state.condition.posterGradeTwo.data.push(obj)
          })
        }
      })
    }
    init()
    return {
      ...toRefs(state),
      onSelectChange,
      onSelectTag,
      useredit,
      onSelectTree,
      handleSearch,
      saveMaterial,
      pagination,
      onShowSizeChange,
      handleOk,
      close,
      delTag,
      del,
      getIds,
      delrt,
      asd,
      onOffShelf,
      onShelf,
      changes,
      chan
    }
  }
})
</script>

<style scoped>
.select-label {
  margin-left: 30px;
}
.butt {
  width: 600px;
  margin-left: 30px;
}
.butt .a-butt {
  margin-right: 15px;
}
.a-check {
  margin-top: 20px;
  margin-left: 30px;
}
.modal-box {
  height: 350px;
}
.modal-box .left {
  float: left;
  font-size: 14px;
}
.modal-box .right {
  float: right;
  width: 300px;
  height: 200px;
  padding-left: 5px;
  border-left: 1px solid rgb(222, 221, 221);
  font-size: 16px;
  font-weight: 700;
}
.a-pag {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.a-check {
  margin-top: 20px;
  margin-left: 30px;
}
</style>
