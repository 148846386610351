<style scoped>
</style>
<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <!-- 表格数据 -->
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
    style="margin-top: 10px"
  >
    <a-spin :delay="200" />
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button
          type="text"
          @click="modifyUser(record, 1)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          查看
        </a-button>
        <a-button
          type="text"
          @click="addUsers(record, 2)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          编辑扩展要素
        </a-button>
      </template>
    </template>
  </a-table>
  <add-users ref="userDrawerRef"></add-users>
</template>
<script>
import { defineComponent, reactive, ref, toRefs, onMounted, computed } from 'vue'
import SearchForm from '../../../components/extended/search_form'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import addUsers from './addUsers'
export default defineComponent({
  components: {
    SearchForm,
    addUsers
  },
  setup () {
    const userDrawerRef = ref()
    const state = reactive({
      // 头部搜索框
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          value: 'id',
          urlKey: 'querySupplier'
        },
        productCode: {
          label: '产品编码',
          placeholder: '请输入产品编码，可根据关键字进行模糊查询'
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '1',
          data: [],
        }
      },
      searchNew: {},
      columns: [
        {
          title: '序号',
          width: 80,
          fixed: 'left',
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '产品编码',
          width: 120,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode'
        },
        {
          title: '供应商产品编码',
          width: 120,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode'
        },
        {
          title: '产品名称',
          width: 120,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName'
        },
        {
          title: '供应商名称',
          width: 120,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName'
        },
        {
          title: '操作',
          width: 120,
          align: 'center',
          key: 'operation',
        },
      ],
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      console.log('搜索参数==', filters)
      state.searchNew = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNew,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))

    const modifyUser = function (oldData, num) {
      userDrawerRef.value.showDrawer(oldData, num)
    }
    const addUsers = function (oldData, num) {
      userDrawerRef.value.showDrawer(oldData, num)
      // formRef.value.resetFields()
    }
    //产品下拉查询
    const getProductName = () => {
      request(getRequestURI('select'), {
        data: {
          productCode: '',
          productId: null,
          supProductCode: '',
          supplierId: null,
          systemCode: ''
        },
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          const label = 'productName'
          const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])

          for (let i = 0; i < sysName.length; i++) {
            arr.push({ label: sysName[i].productName, value: sysName[i].id })
          }
          state.condition.productId.data = arr
        }
      )
    }
    //供应商名称下拉

    onMounted(() => {
      // 搜索
      // handleSearch()
      getProductName()

    })
    return {
      ...toRefs(state),
      modifyUser,
      addUsers,
      run,
      handleSearch,
      handleTableChange,
      onMounted,

      getProductName,
      // increaseInterest,
      userDrawerRef,
      loading,
      current,
      pageSize,
      total,
      dataSource,
      pagination
    }
  }
})
</script>

