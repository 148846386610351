<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="调用方名称:" name="insurerName">
            <a-select :disabled="true" v-model:value="formData.insurerName">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="接口名称:" name="portName">
            <a-select :disabled="true" v-model:value="formData.portName">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
<!--          <a-select v-model:value="value3" style="width: 120px" loading>-->
<!--            <a-select-option value="lucy">Lucy</a-select-option>-->
<!--          </a-select>-->
          <a-form-item label="调用方字段名称:" name="icIdList" v-if="formData.type === 1">
            <a-tree-select v-model:value="formData.icIdList" multiple :tree-data="icldListTwo"
                           :filterTreeNode="filterTreeOption"
                           :disabled="formData.type === 2"
                           @change="handelTreeChange"
                           :fieldNames="{children:'children', label:'name', key:'id', value:'id'}">
            </a-tree-select>
          </a-form-item >
          <a-form-item label="调用方字段名称:" name="icId" v-else>
            <a-tree-select v-model:value="formData.icId" :tree-data="icldListTwo"
                           :filterTreeNode="filterTreeOption"
                           :disabled="formData.type === 2"
                           :fieldNames="{children:'children', label:'name', key:'id', value:'id'}">
            </a-tree-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="调用方字段层级关系:" name="irelationshipList"  v-if="formData.type === 1">
            <a-input :disabled="true" v-model:value="formData.irelationshipList" >
            </a-input>
          </a-form-item>
          <a-form-item label="调用方字段层级关系:" name="irelationship" v-else>
            <a-input :disabled="true" v-model:value="formData.irelationship" >
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="提供方字段名称:" name="pcId" v-if="formData.type === 1">
            <a-tree-select v-model:value="formData.pcId" :tree-data="listData.tigongfanganziduan"
                           :filterTreeNode="filterTreeOption"
                           :disabled="formData.type === 1"
                           :fieldNames="{children:'children', label:'name', key:'id', value:'id'}">
            </a-tree-select>
          </a-form-item>
          <a-form-item label="提供方字段名称:" name="pcIdList " v-else>
            <a-tree-select v-model:value="formData.pcIdList" :tree-data="listData.tigongfanganziduan"
                           multiple
                           :filterTreeNode="filterTreeOption"
                           :disabled="formData.type === 1"
                           :fieldNames="{children:'children', label:'name', key:'id', value:'id'}">
            </a-tree-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="提供方字段层级关系:" name="prelationship" v-if="formData.type === 1">
            <a-select v-model:value="formData.prelationship" :disabled="true">
            </a-select>
          </a-form-item>
          <a-form-item label="提供方字段层级关系:" name="prelationshipList" v-else>
            <a-select v-model:value="formData.prelationshipList" :disabled="true">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12" >
          <a-form-item label="映射方式" name="mappingType">
            <a-select
              show-search
              v-model:value="formData.mappingType"
              placeholder="请选择"
              @change="changeMappingMode" clearable allow-clear :options="mappingList">
            </a-select>

          </a-form-item>
        </a-col>

        <a-col span="12" v-for="item in Number(result.pcount)" :key="item">
          <!--          <a-form-item :label="result[`p${item}`]" name="`p${item}`">-->
          <!--            <a-select-->
          <!--              v-if="result[`v${item}`]"-->
          <!--              show-search-->
          <!--              v-model:value="formData.codeValueList[0][`p${item}`]"-->
          <!--              placeholder="请选择"-->
          <!--              clearable allow-clear :options="`p${item}List`">-->
          <!--            </a-select>-->
          <!--            <a-input v-model:value="formData.codeValueList[0][`p${item}`]" v-else placeholder="请输入"/>-->
          <!--            {{`p${item}List`}}-->
          <!--          </a-form-item>-->
          <template v-if="formData.mappingType!=='value_mapping'&&formData.mappingType!=='java_script'">
            <template v-if="result[`v${item}`]">
              <a-form-item :label="result[`p${item}`]" name="`p${item}`" >
                <a-select
                  v-if="item===1"
                  show-search
                  v-model:value="formData.codeValueList[0][`p${item}`]"
                  placeholder="请选择"
                  clearable allow-clear :options="p1List">
                </a-select>
                <a-select
                  v-if="item===2"
                  show-search
                  v-model:value="formData.codeValueList[0][`p${item}`]"
                  placeholder="请选择"
                  clearable allow-clear :options="p2List">
                </a-select>
                <a-select
                  v-if="item===3"
                  show-search
                  v-model:value="formData.codeValueList[0][`p${item}`]"
                  placeholder="请选择"
                  clearable allow-clear :options="p3List">
                </a-select>
                <a-select
                  v-if="item===4"
                  show-search
                  v-model:value="formData.codeValueList[0][`p${item}`]"
                  placeholder="请选择"
                  clearable allow-clear :options="p4List">
                </a-select>
                <!--            {{`p${item}List`}}&#45;&#45;{{item}}-->
              </a-form-item>
            </template>
            <template v-else>
              <a-form-item :label="result[`p${item}`]" name="`p${item}`" v-if="formData.mappingType!=='health_told' && formData.mappingType !=='intelligence_hb'" >
                <a-input v-model:value="formData.codeValueList[0][`p${item}`]"  placeholder="请输入"/>
              </a-form-item>
            </template>

          </template>
          <template v-if="formData.mappingType==='java_script'">

<!--            <a-form-item :label="result[`p${item}`]" name="`p${item}`">-->
<!--              <a-tree-select v-model:value="formData.codeValueList[0]['p1']" show-search allow-clear tree-default-expand-all-->
<!--                             :tree-data="gongyingshangmingcheng" placeholder="请输入供应商名称"-->
<!--                             :filterTreeNode="filterTreeOption1"-->
<!--                             :fieldNames="{children:'children', label:'supName', key:'id', value:'supCode'}">-->
<!--              </a-tree-select>-->
<!--            </a-form-item>-->
            <a-form-item label="上传js脚本" ><!---->
              <upload-file :filetype="editFileType"
                            num="1"
                           :showImg="showImg"
                           @getFileList="getFileList"
                           @fileChange="uploadCosFileChange" v-if="!fileUrl"></upload-file>
              <div class="updateUpload" v-else>
                <a href="javascript:void(0)" @click="onPreviewFile()">{{fileUrl.substring(fileUrl.lastIndexOf('/')+1)}}</a>
                <DeleteOutlined style="margin-left: 10px" @click="handelDel"/>
              </div>
            </a-form-item>
          </template>
          <template v-if="formData.mappingType==='health_told' || formData.mappingType==='intelligence_hb'">
            <a-form-item :label="result[`p${item}`]" name="`p${item}`">
              <a-tree-select v-model:value="formData.codeValueList[0]['p1']" show-search allow-clear tree-default-expand-all
                             :tree-data="gongyingshangmingcheng" placeholder="请输入供应商名称"
                             :filterTreeNode="filterTreeOption1"
                             :fieldNames="{children:'children', label:'supName', key:'id', value:'supCode'}">
              </a-tree-select>
            </a-form-item>
          </template>


        </a-col>
        <a-col span="24" v-if="formData.mappingType==='calculate_Factor_value'">
          <a-table :columns="columns3" :data-source="zhiform"
                   :pagination="false"
                   :row-key="(record) => record.id" :row-selection="{
            selectedRowKeys: selectedRowKeys2,
            onChange: onSelectChanges2}">
            <template #bodyCell="{ column,index,  record }">
              <template v-if="column.key === 'index'">
                {{ index + 1 }}
              </template>
              <template v-if="column.key === 'p1'">
                <a-input v-model:value="record.p1" placeholder="请输入">
                </a-input>
              </template>
              <template v-if="column.key === 'p2'">
                <a-input v-model:value="record.p2" placeholder="请输入">
                </a-input>
              </template>
              <template v-if="column.key === 'p3'" >
<!--                <a-input v-model:value="record.p3" placeholder="请输入">-->
<!--                </a-input>-->
                <a-select
                  show-search
                  v-model:value="record.p3"
                  placeholder="请选择"
                  clearable allow-clear :options="p3List">
                </a-select>
              </template>
            </template>
          </a-table>
          <a-button @click="add" >增加一行</a-button>&nbsp;
          <a-button @click="del" >删除</a-button>&nbsp;
        </a-col>


        <a-col span="24" v-if="formData.mappingType==='value_mapping'">
<!--                    <a-table-->
<!--                      class="ant-table-striped"-->
<!--                      size="middle"-->
<!--                      :columns="columns2"-->
<!--                      :data-source="zhiform"-->
<!--                      :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"-->
<!--                    />-->
<!--                    <a-button @click="add" v-if="!ischeck">增加一行</a-button>&nbsp;-->
          <a-table :columns="columns2" :data-source="zhiform"
                   :pagination="false"
                   :row-key="(record) => record.id" :row-selection="{
            selectedRowKeys: selectedRowKeys2,
            onChange: onSelectChanges2}">
            <template #bodyCell="{ column,index,  record }">
              <template v-if="column.key === 'index'">
                {{ index + 1 }}
              </template>
              <template v-if="column.key === 'p1'">
                <a-input v-model:value="record.p1" placeholder="请输入">
                </a-input>
              </template>
              <template v-if="column.key === 'p2'">
                <a-input v-model:value="record.p2" placeholder="请输入">
                </a-input>
              </template>

            </template>
          </a-table>
          <a-button @click="add" >增加一行</a-button>&nbsp;
          <a-button @click="del" >删除</a-button>&nbsp;
        </a-col>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>

  </a-drawer>
</template>

<script>
import {computed, defineComponent, onBeforeMount, onMounted, reactive, ref, toRefs, watch} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import {message} from 'ant-design-vue'
import {getDictionaryData} from '../../../utils/request_extend'
import uploadFile  from '../../../components/extended/uploadFileNew'


export default defineComponent({
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return '123'
      }
    }
  },
  components:{
    uploadFile

  },
  setup(props, {expose, emit}) {
    const state = reactive({
      treeSelect:[],
      visible: false,
      showImg:[],
      editFileType:'',
      fileUrl:'',
      query: {
        data: {},
        pagination: {
          current: 1,
          pageSize: 10000
        },
      },
      selectedRowKeys2: [],
      zhiform:[{id: 0, p1:'',p2:'',p3:undefined}],
      columns2: [
        {
          title: '序号',
          width: 140,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '调用方值',
          width: 140,
          fixed: 'left',
          key: 'p1',
          dataIndex: 'p1'
        },
        {
          title: '提供方值',
          width: 120,
          align: 'center',
          key: 'p2',
          dataIndex: 'p2',
        },

        // {
        //   title: '是否显示',
        //   width: 120,
        //   align: 'center',
        //   key: 'isShowStatus',
        //   dataIndex: 'isShowStatus',
        //   customRender: (text) => getDictionaryValue('yes_no', text.value)
        // },


      ],
      columns3: [
        {
          title: '序号',
          width: 140,
          fixed: 'left',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '调用方值',
          width: 140,
          fixed: 'left',
          key: 'p1',
          dataIndex: 'p1'
        },
        {
          title: '提供方值',
          width: 120,
          align: 'center',
          key: 'p2',
          dataIndex: 'p2',
        },
        {
          title: '要素类型',
          width: 200,
          align: 'center',
          key: 'p3',
          dataIndex: 'p3',
        },
      ],
    })
    const formData = reactive({
      id: '',
      type:0,
      insurerName: '',
      portName: '',
      icId: '',
      icIdList: [],
      icolumn:'',
      icolumnList: [],
      irelationship:'',
      irelationshipList: '',

      pcId:'',
      pcIdList: [],
      pcolumn:'',
      pcolumnList: [],
      prelationship:'',
      prelationshipList:'',

      codeValueList:[{}],
      mappingType:'',
    })
    const listData = reactive({
      tigongfanganziduan: [],
      guanxi: []
    })
    const uploadCosFileChange=(data)=>{
      console.log('上传地址',data)
      state.fileUrl = data.url
    }
    const handelTreeChange = (value) => {
      state.treeSelect = []
      // 弄成id: 1->1-1
      if(value.length){
        value.forEach(item =>{
          state.treeSelect.push(getTreeValue(item, icldListTwo.value))
        })
        formData.type === 1 ? formData.irelationshipList = state.treeSelect.join() : formData.irelationship = state.treeSelect.join()
      }else{
        formData.irelationship = ''
        formData.irelationshipList = ''
      }
    }
    // 获取选中的级别
    const getTreeValue = (id, list) =>{
      for (let i = 0; i < list.length; i++) {
        if(list[i].id === id){
          return list[i].relationship
        }else if(list[i].children && list[i].children.length){
          const value =  getTreeValue(id, list[i].children)
          if(value) return value
        }
      }
    }
    const onPreviewFile =() =>{
      // let index = file.uploadFileName.substr(-3)
      // if(index === 'pdf'){
      //   handelPdf(file)
      // }else{
      if(state.fileUrl){

        window.location = state.fileUrl

      }

    }
    const handelFileTypeChange =(e) =>{
      state.fileUrl=''
      // 产品介绍 6  只支持上传图片  其他支持图片, PDF
      if(e === '6'){
        state.editFileType = 'img'
      }else{
        state.editFileType = 'productType'

      }
    }
    const getFileList = (info) =>{
      console.log(11111111, info.file.status)
      if (/\s/g.test(info.file.name) && (info.file.status === 'done' || info.file.status === 'uploading')) {
        handelDel()
        message.warning('上传的文件名不能包含空格')
      }
    }
    const handelDel = () =>{
      state.fileUrl=''
      state.showImg = []
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const inNew = computed(() => {
      if (props.status == 'update') {
        return '参数映射修改'
      } else if (props.status == 'add') {
        return '调用方接口增加'
      }
    })
// 增加一行
    let id = 1
    const add = () => {
      id++
      if (state.zhiform.length === 0) {
        state.zhiform.push({
          id: 0,
          p1:'',
          p2:'',
          p3:undefined
        })
      } else {
        state.zhiform.push({
          id,
          p1:'',
          p2:'',
          p3:undefined
        })
      }
    }
    const del = () => {
      if (!state.selectedRowKeys2.length) message.warning('请选择数据')
      state.selectedRowKeys2.forEach((item) => {
        state.zhiform.forEach((item1, index) => {
          if (item1.id == item) {
            state.zhiform.splice(index, 1)
          }
        })
      })
    }


    const onSave = function () {
      if(formData.codeValueList[0]){
        formData.codeValueList[0]['mappingType']=formData.mappingType
      }else{
        formData.codeValueList[0]={mappingList:formData.mappingType}

      }
      console.log('-------------------------',formData)
      if(formData.mappingType==='value_mapping'||formData.mappingType==='calculate_Factor_value'){
        formData.codeValueList=[...state.zhiform]
        formData.codeValueList.forEach(item=>item.mappingType=formData.mappingType)
      }
      if(formData.mappingType==='java_script'){
        formData.codeValueList[0].p1=state.fileUrl
      }
      request(getRequestURI('ExcInsurerP_update123'), {data: formData}).then(res => {
        if (res.code === 1) {
          onClose()
          emit('handleSearch')
          message.success(res.message)
        } else {
          message.warning(res.message)
        }
      })
    }
    const result=ref({})
    onBeforeMount(()=>{
      result.value.pcount=0
    })
    const onSelectChanges2 = selectedRowKeys => {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys2 = selectedRowKeys
    }
    // const del = () => {
    //   if (!state.selectedRowKeys2.length) message.warning('请选择数据')
    //   state.selectedRowKeys2.forEach((item) => {
    //     data.productElementListForm.forEach((item1, index) => {
    //       if (item1.id == item) {
    //         data.productElementListForm.splice(index, 1)
    //       }
    //     })
    //   })
    //   state.selectedRowKeys2.forEach(item => {
    //     state.delIdList.forEach(del => {
    //       if (item === del) {
    //         data.delListIds.push(item)
    //       }
    //     })
    //   })
    //   state.selectedRowKeys2 = []
    // }
  const flag=ref(1)
    const changeMappingMode = function (value) {
      console.log('值映射----------------', value)
      if(flag.value===1){
        console.log(11)
        flag.value=0
      }else{
        state.zhiform=[{id: 0, p1:'',p2:'',p3:undefined}]
        formData.codeValueList=[{}]
      }
      // formData.codeValueList=[{}]

      // getDictionaryData(mappingList, 'value_Mapping_type')
      request(getRequestURI('getParamName'), {data: {mappingType:value}}).then(res => {
        if (res.code === 1&&res.result.pcount!=='0') {
          result.value=res.result
          for (let i = 1; i <= res.result.pcount; i++) {
            // formData[`p${i}`]
            // formData[`p${i}`]
            // console.log(`p${i}`)
            if(formData.codeValueList[0][`p${i}`]){
              continue
            }
            formData.codeValueList[0][`p${i}`]=''
            console.log(formData)
          }
          p1List.value=[]
          p2List.value=[]
          p3List.value=[]
          p4List.value=[]
          if(result.value.v1){
            getDictionaryData(p1List,result.value.v1)
            // console.log(10000000000000000000,p1List.value)
            // request(getExtendUri('dictionary_query_byType'),
            //   {data: {ids:[],types:[result.value.v1]}}).then(
            //   res =>{
            //     p1List.value=res.result[result.value.v1]
            //   })

          }
          if(result.value.v2){
            getDictionaryData(p2List,result.value.v2)
          }
          if(result.value.v3){
            getDictionaryData(p3List,result.value.v3)
          }
          if(result.value.v4){
            getDictionaryData(p4List,result.value.v4)
          }
         // message.success(res.message)
        } else {
         // message.warning(res.message)
        }
      })

    }

    const filterTreeOption = function(input, option) {
      return option.name.includes(input)
    }
    const icldListTwo=ref([])
    const showDrawer = (oldData = {}) => {
      flag.value=1
      state.visible = true
      state.query.data.ipId = oldData.ipId
      state.query.data.portId = oldData.portId
      state.query.data.type = oldData.type + ''
      request(getRequestURI('selectListByPortId'),{ data: {
          ipId:  state.query.data.ipId ,
          portId: state.query.data.portId,
          type: state.query.data.type
        },
        pagination: {
          current: 1,
          pageSize: 10000
        }
      }).then(res => {
        if (res.code === 1) {
          icldListTwo.value=res.result
        }
      })
      let uri = getRequestURI('ExcPortConfig_selectTree')
      request(uri, state.query).then(res => {
        listData.tigongfanganziduan = res.result
      })// state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      if (Array.isArray(formData.prelationshipList)) {
        formData.prelationshipList = formData.prelationshipList.join(',')
      }
      if (Array.isArray(formData.irelationshipList)) {
        formData.irelationshipList = formData.irelationshipList.join(',')
      }
      if (formData.type === 1) {
        formData.pcId = formData.id
      } else {
        formData.icId = formData.id
      }
      if(oldData?.codeValueList?.[0]?.mappingType==='java_script'){
        state.fileUrl=decodeURIComponent(oldData.codeValueList[0].p1)
        // state.showImg = []
        let enclosureName=state.fileUrl.substring(state.fileUrl.lastIndexOf('/')+1)
        state.showImg.push({url:state.fileUrl,enclosureName:enclosureName})
      }
      if(oldData?.codeValueList[0]?.mappingType){
        formData.mappingType=oldData.codeValueList[0].mappingType
      }
      if(Array.isArray(formData.codeValueList)){
        state.zhiform=[...formData.codeValueList]
      }
      // if(Array.isArray(formData.codeValueList)){
      //   formData.codeValueList = formData.codeValueList.join(',')
      //
      // }
      // if(oldData?.codeValueList[0]?.mappingType!=='value_mapping'){
      //   formData.codeValueList=formData.push(oldData.codeValueList[0])
      //
      // }
    }
    const onClose = () => {
      state.visible = false
      formData.insurerName = ''
      formData.portName = ''
      formData.mappingType=''
      formData.codeValueList=[{id: 0, p1: '',p2:''}]
      state.showImg.splice(0)
      formData.icId= ''
      formData.icIdList=[]
      formData.icolumn = ''
      formData.icolumnList=[]
      formData.irelationship=''
      formData.irelationshipList=''
      formData.pcId = ''
      formData.pcIdList=[]
      formData.pcolumn = ''
      formData.pcolumnList=[]
      formData.prelationship = ''
      formData.prelationshipList=''
      formData.type=0
      flag.value=1
      result.value.pcount = 0
      state.selectedRowKeys2 = []
      // flag.value=1

    }
    const init = function () {
    }
    const mappingList = ref([])

    const p1List = ref([])
    const p2List = ref([])
    const p3List = ref([])
    const p4List = ref([])
    const gongyingshangmingcheng = ref([])
    const getSupplier = function() {
      const uri = getRequestURI('supplier_querySupplier')
      request(uri, {data:{}}).then(res => {
        console.log(res)
        if (res.code == 1) {
          gongyingshangmingcheng.value = res.result
        }
      })
    }
    const filterTreeOption1= function(input, option) {
      return option.supName.includes(input)
    }
    watch(()=>formData.mappingType,(newV)=>{
        changeMappingMode(newV)
    })

    onMounted(() =>{
      getDictionaryData(mappingList,'value_Mapping_type')
      getSupplier()
      // changeMappingMode(formData.mappingType)
    })

    init()
    expose({
      showDrawer
    })
    return {
      ...toRefs(state),
      onClose,
      onSave,
      formData,
      listData,
      showDrawer,
      inNew,
      filterTreeOption,
      mappingList,
      changeMappingMode,
      result,
      p1List,
      p2List,
      p3List,
      p4List,
      icldListTwo,
      getSupplier,
      filterTreeOption1,
      gongyingshangmingcheng,
      onSelectChanges2,
      del,
      add,
      flag,
      uploadCosFileChange,
      getFileList,
      handelDel,
      handelFileTypeChange,
      onPreviewFile,
      handelTreeChange

    }
  }
})
</script>

<style scoped>

</style>
