<template>
  <search-form
    :Condition="condition"
    @nameLinked="changes"
    @resetAll="resetAll"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
    style="margin-top: 10px"
  >
    <a-spin :delay="200" />
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <a-button
          type="text"
          @click="addUser(record)"
          style="margin-right: 10px; color: orange"
          size="small"
        >
          关联标签
        </a-button>
      </template>
    </template>
  </a-table>
  <productLabelRelevanceEdit
    ref="productLabelRelevanceEditRef"
  ></productLabelRelevanceEdit>
</template>

<script>
import SearchForm from '../../../components/extended/search_form'
import { requestUsePagination, getRequestURI, request } from '../../../utils/request.js'
import productLabelRelevanceEdit from './productLabelRelevanceEdit.vue'
import { defineComponent, reactive, toRefs, computed, onMounted, ref } from 'vue'
export default defineComponent({
  components: {
    SearchForm,
    productLabelRelevanceEdit
  },
  setup () {
    const productLabelRelevanceEditRef = ref()
    const state = reactive({
      columns: [
        {
          title: '序号',
          width: 80,
          fixed: 'left',
          align: 'center',
          key: 'index',
          dataIndex: 'index'
        },
        {
          title: '产品编码',
          width: 120,
          align: 'center',
          key: 'productCode',
          dataIndex: 'productCode'
        },
        {
          title: '供应商产品编码',
          width: 120,
          align: 'center',
          key: 'supProductCode',
          dataIndex: 'supProductCode'
        },
        {
          title: '产品名称',
          width: 120,
          align: 'center',
          key: 'productName',
          dataIndex: 'productName'
        },
        {
          title: '供应商名称',
          width: 120,
          align: 'center',
          key: 'supplierName',
          dataIndex: 'supplierName'
        },
        {
          title: '操作',
          width: 120,
          align: 'center',
          key: 'operation',
        },
      ],
      condition: {
        supplierId: {
          label: '供应商名称',
          type: 'tree-select',
          value: 'id',
          urlKey: 'querySupplier'
        },
        productCode: {
          label: '产品编码',
          placeholder: '请输入产品编码，可根据关键字进行模糊查询'
        },
        productId: {
          label: '产品名称',
          type: 'select',
          default: '1',
          data: [],
        }
      },
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ppqa_selectInfo'))
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    const handleSearch = function (filters) {
      console.log('搜索参数==', filters)
      state.searchNew = filters
      run({
        pagination: {
          current: current.value,
          pageSize: pageSize.value
        },
        data: filters,
      })
    }
    //产品下拉查询
    const changes = function (value) {
      request(getRequestURI('select'), { data: { supplierId: value } }).then(result => {
        console.log(res)
        const arr = []
        const res = result.result
        const label = 'productName'
        const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])
        for (let i = 0; i < sysName.length; i++) {
          arr.push({ label: sysName[i].productName, value: sysName[i].id })
        }
        state.condition.productId.data = arr
      })
    }
    // 页面发生改变回调
    const handleTableChange = (pag, filters, sorter) => {
      console.log('===', filters)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.searchNew,
      })
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
      // pageSizeOptions: [10, 20, 50],
    }))
    const addUser = function (value) {
      productLabelRelevanceEditRef.value.showDrawer(value)
    }
    //产品下拉查询
    const getProductName = () => {
      request(getRequestURI('select'), {
        data: {
          productCode: '',
          productId: null,
          supProductCode: '',
          supplierId: null,
          systemCode: ''
        },
      }, true, 'post').then(
        (result) => {
          const arr = []
          const res = result.result
          const label = 'productName'
          const sysName = res.reduce((all, next) => all.some((atom) => atom[label] == next[label]) ? all : [...all, next], [])

          for (let i = 0; i < sysName.length; i++) {
            arr.push({ label: sysName[i].productName, value: sysName[i].id })
          }
          state.condition.productId.data = arr
        }
      )
    }
    const resetAll = () => {
      state.condition.productId.data = []
      getProductName()
    }

    onMounted(() => {
      // 搜索
      // handleSearch()
      getProductName()

    })
    return {
      ...toRefs(state),
      resetAll,
      addUser,
      handleSearch,
      handleTableChange,
      pagination,
      dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
      getProductName,
      productLabelRelevanceEditRef,
      changes
    }
  }
})
</script>

<style scoped>
</style>
