<template>
  <a-drawer
    :title="inNew"
    :width="'70%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form
      :model="formData"
      layout="vertical"
      ref="formRef"
      autocomplete="off"
    >
      <a-row :gutter="10">
        <a-col span="12">
          <a-form-item label="调用方名称:"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
                       name="insId">
            <a-select v-model:value="formData.insId" show-search
                      placeholder="请选择调用方名称"
              :filter-option="filterOption">
              <a-select-option v-for="item in listDate.qvdaomingcheng" :label="item.name" :key="item.id" :value="item.id" clearable>
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="提供方接口:"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
                       name="portId">
            <a-select v-model:value="formData.portId" show-search
                      placeholder="请选择提供方接口"
              :filter-option="filterOption">
              <a-select-option v-for="item in listDate.portIds" :label="item.name" :key="item.id" :value="item.id" clearable>
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
                       name="intoType">
            <template #label>
              <span>入参类型:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当调用方为产品工厂时，固定为JSON，如果调用方为保司时入参类型和保司要求一致
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.intoType" placeholder="请选择入参类型" :options="intoTypeList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]"
                       name="outType">
            <template #label>
              <span>出参类型:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      当调用方为产品工厂时，固定为JSON，如果调用方为保司时入参类型和保司要求一致
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.outType" placeholder="请选择出参类型" :options="outTypeList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="调用方接口地址:" name="receiverPath" :rules="[{required:false,message:'必填信息',trigger:'change'}]">
            <a-input v-model:value="formData.receiverPath" placeholder="请输入调用方接口地址"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
            <a-form-item label="接口类型:" name="reqType"
                         :rules="[{required:false,message:'必填信息',trigger:'change'}]"
            >
              <a-select v-model:value="formData.reqType"  placeholder="请选择接口类型" :options="reqTypeList">
              </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="WSDL命名空间:" name="wsdlNameSpace">
            <a-input v-model:value="formData.wsdlNameSpace" placeholder="请输入WSDL命名空间"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="WSDL响应名:" name="wsdlAction" >
            <a-input v-model:value="formData.wsdlAction" placeholder="请输入WSDL响应名"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="WSDL方法名:" name="wsdlMethod" >
            <a-input v-model:value="formData.wsdlMethod" placeholder="请输入WSDL方法名"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="WSDL参数名:" name="wsdlParam" >
            <a-input v-model:value="formData.wsdlParam" placeholder="请输入WSDL参数名"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="WSDL返回参数:" name="wsdlResult" >
            <a-input v-model:value="formData.wsdlResult" placeholder="请输入WSDL返回参数"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否解密:" name="decrypt">
            <a-select v-model:value="formData.decrypt" placeholder="请选择是否解密" :options="decryptList">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="解密算法:" name="decryptType">
            <a-input v-model:value="formData.decryptType" placeholder="请输入解密算法">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="是否加密:" name="encrypt">
            <a-select v-model:value="formData.encrypt" :options="encryptList" placeholder="请输入是否加密">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item label="加密算法:" name="encryptType">
            <a-input v-model:value="formData.encryptType" placeholder="请输入加密算法">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item name="emptySite">
            <template #label>
              <span>是否过滤空节点:</span>&nbsp;
              <span>
                <a-popover placement="right">
                  <template #content>
                    <p>
                      如果选择是，在映射时会过滤掉值为空的节点，如果选择“否”，空节点会继续保留
                    </p>
                  </template>
                  <template #title>
                    <span>规则说明:</span>
                  </template>
                  <question-circle-outlined />
                </a-popover>
              </span>
            </template>
            <a-select v-model:value="formData.emptySite" placeholder="请选择是否过滤空节点" :options="emptySiteList">
            </a-select>
          </a-form-item>
        </a-col>
        </a-row>
      </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button html-type="submit" type="primary" @click="onSave()">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>

<script>
import {computed, defineComponent, toRefs, reactive, ref} from 'vue'
import {message} from 'ant-design-vue'
import {getRequestURI, request} from '../../../utils/request'
import {getExtendUri} from '../../../utils/request_extend'
export default  defineComponent({
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return ''
      }
    },
    searchfFilters: { type: Object },
    handleSearch: {
      type: Function,
      default:() => {}
    }
  },
  setup(props, {expose}) {
    const formData = reactive({
      insurerName:'',//调用方名称
      portId:undefined,//提供方接口
      reqType: undefined,
      wsdlNameSpace: '',
      wsdlAction: '',
      wsdlMethod: '',
      wsdlParam: '',
      wsdlResult: '',
      receiverPath:'', // 调用方地址
      intoType:undefined,//入参类型
      outType:undefined,//出参类型
      decrypt:'N',//解密
      decryptType:'',//解密标记
      encrypt:'N',//是否加密
      encryptType:'',//加密标记
      emptySite:'N',//是否过滤节点
      insId:undefined,
      id:''
    })
    const listDate = reactive({
      qvdaomingcheng:[],
      portIds:[],
      intoType: [
        {
          label: 'JSON',
          value: 1
        },
        {
          label: 'XML',
          value: 2
        },
      ],
      outType: [
        {
          label: 'JSON',
          value: 1
        },
        {
          label: 'XML',
          value: 2
        },
      ],
      decrypt: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        },
      ],
      encrypt: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        },
      ],
      emptySite: [
        {
          label: '是',
          value: 'Y'
        },
        {
          label: '否',
          value: 'N'
        },
      ]
    })
    const state = reactive({
      visible: false,
      // 入参出参
      intoTypeList: [],
      outTypeList: [],
      // 接口类型
      reqTypeList: [],
      //是否加密、解密
      decryptList: [],
      encryptList: [],
      //
      emptySiteList: [],
    })
    const formRef = ref()
    const onClose = () => {
      Object.assign(formData, {
        insurerName:undefined,//调用方名称
        portId:undefined,//提供方接口
        intoType:undefined,//入参类型
        receiverPath:'',
        outType:undefined,//出参类型
        decrypt:'N',//解密
        decryptType:'',//解密标记
        encrypt:'N',//是否加密
        encryptType:'',//加密标记
        emptySite:'N',//是否过滤节点
        insId:undefined,
        id:'',
        reqType: undefined,
        wsdlNameSpace: '',
        wsdlAction: '',
        wsdlMethod: '',
        wsdlParam: '',
        wsdlResult: '',

      })
      console.log(formData)
      state.visible = false
    }
    // eslint-disable-next-line vue/return-in-computed-property
    const inNew = computed(() => {
      if (props.status == 'update') {
        return '调用方接口修改'
      } else if(props.status == 'add') {
        return '调用方接口增加'
      }
    })
    const onSave = function() {
      //调用方名称校验
      if(!formData.insId) {
        message['warning']('调用方名称不能为空')
        return
      }
      //提供方接口校验
      if(!formData.portId) {
        message['warning']('提供方接口不能为空')
        return
      }
      //入参类型校验
      if(!formData.intoType) {
        message['warning']('入参类型不能为空')
        return
      }
      //出参类型校验
      if(!formData.outType) {
        message['warning']('出参类型不能为空')
        return
      }
      //调用方接口地址校验
      // if(formData.receiverPath === '') {
      //   message['warning']('调用方接口地址不能为空')
      //   return
      // }
      /*if(!formData.reqType){
        message['warning']('接口类型不能为空')
        return
      }*/
      // if(!formData.wsdlNameSpace) {
      //   message['warning']('WSDL命名空间不能为空')
      //   return
      // }
      // if(!formData.wsdlAction) {
      //   message['warning']('WSDL响应名不能为空')
      //   return
      // }
      // if(!formData.wsdlMethod) {
      //   message['warning']('WSDL方法名不能为空')
      //   return
      // }
      // if(!formData.wsdlParam) {
      //   message['warning']('WSDL参数名不能为空')
      //   return
      // }
      // if(!formData.wsdlResult) {
      //   message['warning']('WSDL返回参数不能为空')
      //   return
      // }

      let obj = {
        data:formData
      }
      if(props.status == 'update'){
        let uri = getRequestURI('ExcInsurerPort_update')
        console.log(uri)
        request(uri, obj).then(res => {
          if(res.code === 1) {
            props.handleSearch(props.searchfFilters)
            onClose()
            message['success']('修改成功')
          }else{
            message.warning(res.message)
          }
        })
      }else  if(props.status == 'add'){
        console.log(obj)
        let uri = getRequestURI('ExcInsurerPort_insert')
        request(uri, obj).then(data => {
          if (data.code === 1) {
            props.handleSearch({})
            onClose()
            message['success']('保存成功')
          }else{
            message.warning(data.message)
          }
        })
      }

    }
    const showDrawer = (oldData = {}) => {
      console.log(oldData)
      state.visible = true
      // state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      // console.log('editNewUser', state.isNew, oldData)
      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      formData.reqType = formData.reqType ? formData.reqType + '' : undefined
      formData.intoType = formData.intoType ? formData.intoType + '' : undefined
      formData.outType = formData.outType ? formData.outType + '' : undefined
      if(oldData !== 'add'){
        formData.insId = oldData.insId
      }
      console.log('数据',oldData,formData)
      qudaomingcheng()
      tigongfang()
    }
    //获取调用方名称
    const qudaomingcheng =()=>{
      let uri = getRequestURI('ExcInsurer_selectTree')
      request(uri, {}).then(res => {
        console.log(res.result)
        if(res.code == 1) {
          listDate.qvdaomingcheng = res.result
        }
      })
    }
    //获取提供方接口
    const tigongfang =()=>{
      let uri = getRequestURI('ExcPort_selectName')
      request(uri, {}).then(res => {
        console.log(res.result)
        if(res.code == 1) {
          listDate.portIds=res.result
        }
      })
    }
    const init = function() {
      request(getExtendUri('dictionary_query_byType'), {data: {types:['paramterContentType']}}).then(res => {
        state.outTypeList = res.result.paramterContentType
        state.intoTypeList = res.result.paramterContentType
      })
      request(getExtendUri('dictionary_query_byType'), {data: {types:['yes_no']}}).then(res => {
        state.emptySiteList = res.result.yes_no
        // console.log('1111 state.emptySiteList', state.emptySiteList)
      })
      request(getExtendUri('dictionary_query_byType'), {data: {types:['yes_no']}}).then(res => {
        console.log('1111 state.decryptList', state.decryptList)
        state.decryptList = res.result.yes_no
        state.encryptList = res.result.yes_no
      })
      request(getExtendUri('dictionary_query_byType'), {data: {types:['interfaceType']}}).then(res => {
        state.reqTypeList = res.result.interfaceType
      })
    }
    init()
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    expose({
      showDrawer
    })
    return {
      filterOption,
      onClose,
      showDrawer,
      formData,
      inNew,
      ...toRefs(state),
      formRef,
      listDate,
      onSave,
      qudaomingcheng,
      tigongfang
    }
  }
})
</script>

<style scoped>

</style>
