<style scoped>
</style>

<template>
  <a-drawer
    :title="panDuan"
    :width="'30%'"
    :visible="visible"
    :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <a-form :model="formData"  layout="vertical" ref="formRef" autocomplete="off">
      <a-input v-model="formData.id" v-show="false"/>
      <a-row :gutter="10">
        <a-col span="24">
          <a-form-item label="对接调用方名称" name="insId"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-select
            v-model:value="formData.insId"
            show-search
            :filter-option="filterOption"
            placeholder="请选择"
            @change="changeName" clearable allow-clear :options="nameList">
<!--              <a-select-option v-for="item in nameList" :key="item.insId" :value="item.systemCode" clearable>-->
<!--                {{ item.systemName }}-->
<!--              </a-select-option>-->
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="调用方接口名称" name="portId"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-select
              show-search
              :filter-option="filterOption"
              v-model:value="formData.portId"
              placeholder="请选择"
              @change="changequdaojiekoi" clearable allow-clear :options="qvdaojiekoumingcheng">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24">
          <a-form-item label="调用方参数名称" name="icId"
                       :rules="[{required:true,message:'必填信息',trigger:'change'}]">
            <a-tree-select v-model:value="formData.icId" :tree-data="qvdaocanshuminghceng"
                           show-search
                           :filterTreeNode="filterTreeOption1"
                           :fieldNames="{children:'children', label:'column', key:'id', value:'id'}">
            </a-tree-select>
          </a-form-item>
        </a-col>
        <a-col span="24" >
          <a-form-item label="映射方式" name="mappingType" :rules="[{required:true,message:'映射方式必填信息',trigger:'change'}]">
            <a-select
              show-search
              v-model:value="formData.mappingType"
              placeholder="请选择"
              @change="changeMappingMode" clearable allow-clear :options="mappingList">
            </a-select>

          </a-form-item>
        </a-col>
        <template v-if="formData.mappingType==='java_script'">
          <a-form-item label="上传js脚本" ><!---->
            <upload-file :filetype="editFileType"
                         num="1"
                         :showImg="showImg"
                         @getFileList="getFileList"
                         @fileChange="uploadCosFileChange" v-if="!formData.code"></upload-file>
            <div class="updateUpload" v-else>
              <a href="javascript:void(0)" @click="onPreviewFile()">{{formData.code.substring(formData.code.lastIndexOf('/')+1)}}</a>
              <DeleteOutlined style="margin-left: 10px" @click="handelDel"/>
            </div>
          </a-form-item>
        </template>

        <template v-if="formData.mapValue=== 'mapping'">
          <a-col span="24">
            <a-form-item label="调用方参数对应码值:" name="code"
                         :rules="[{max:20, message: '输入长度不能超过20' ,trigger: 'change'},
                         { required: true, message: '必填信息' ,trigger: 'change'}]">
              <a-input v-model:value="formData.code" placeholder="请输入调用方编码"/>
            </a-form-item>
          </a-col>
          <a-col span="24">
            <a-form-item label="映射码值:" name="value"
                         :rules="[{max:20, message: '输入长度不能超过20' ,trigger: 'change'},
                         { required: true, message: '必填信息' ,trigger: 'change'}]">
              <a-input v-model:value="formData.value" placeholder="请输入调用方编码"/>
            </a-form-item>
          </a-col>

        </template>
        <template v-if="formData.mapValue=== 'mutate'">
          <a-col span="24">
            <a-form-item label="时间格式" name="code" :rules="[{required:true,message:'必填信息',trigger:'change'}]">
              <a-select
                show-search
                v-model:value="formData.code"
                placeholder="请选择"
                clearable allow-clear :options="dataFormatList">
              </a-select>
            </a-form-item>
          </a-col>
        </template>
        <template v-if="formData.mapValue=== 'valueCompute'">
          <a-col span="24">
            <a-form-item label="计算方式:" name="code"
                         :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
              <a-select
                show-search
                v-model:value="formData.code"
                placeholder="请选择计算方式"
                clearable allow-clear :options="computeModeList">
              </a-select>
            </a-form-item>
            <a-form-item label="计算值:" name="value"
                         :rules="[{ required: true, message: '必填信息' ,trigger: 'change'},
                         {pattern:/^[0-9]/, message: '支持数字' ,trigger: 'change'}]">
              <a-input v-model:value="formData.value" placeholder="请输入计算值"/>
            </a-form-item>
          </a-col>
        </template>
        <template v-if="formData.mapValue=== 'subString'">
          <a-col span="24">
            <a-form-item label="起止位置(使用间隔):" name="code"
                         :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
              <a-input v-model:value="formData.code" placeholder="请输入起止位置"/>
            </a-form-item>
          </a-col>
        </template>
        <template v-if="formData.mapValue=== 'healthTold'">
          <a-col span="24">
            <a-form-item label="供应商名称:" name="code"
                         :rules="[{ required: true, message: '必填信息' ,trigger: 'change'}]">
              <a-tree-select v-model:value="formData.code" show-search allow-clear tree-default-expand-all
                             :tree-data="gongyingshangmingcheng" placeholder="请输入供应商名称"
                             :filterTreeNode="filterTreeOption"
                             :fieldNames="{children:'children', label:'supName', key:'id', value:'supCode'}">
              </a-tree-select>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
    <template #extra>
      <a-space>
        <a-button @click="onClose">
          <template #icon>
            <CloseOutlined/>
          </template>
          关闭
        </a-button>
        <a-button type="primary" @click="onSave">
          <template #icon>
            <SaveOutlined/>
          </template>
          提交
        </a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import {defineComponent, reactive, toRefs, ref, computed, onMounted} from 'vue'
import {getRequestURI, request} from '../../../utils/request'
import uploadFile  from '../../../components/extended/uploadFileNew'
import {message} from 'ant-design-vue'
import {getDictionaryData} from '../../../utils/request_extend'

export default defineComponent({
  components: {uploadFile},
  props: {
    status: {
      type: String,
      required: true,
      default() {
        return ''
      }
    },
    searchfFilters: { type: Object },
    handleSearch: {type: Function}
  },
  setup(props, {expose}, context) {
    const formRef = ref()
    const mappingList = ref([])
    const dataFormatList = ref([])
    const computeModeList = ref([])
    const gongyingshangmingcheng = ref([])
    const formData = reactive({
      code: '',
      icId: undefined,
      id: 0,
      insId: undefined,
      portId: undefined,
      mappingType: undefined,
      value: '',
      mapValue: undefined,
    })


    const state = reactive({
      editFileType:'',
      showImg: [],
      nameList: [],
      dataInterface: [],
      qvdaocanshuminghceng: [],
      qvdaojiekoumingcheng: [],
      dataSourceList: [],
      visible: false,
      isNew: true
    })
    const uploadCosFileChange=(data)=>{
      console.log('上传地址',data)
      formData.code = data.url

    }
    const getFileList = (info) =>{
      if (/\s/g.test(info.file.name) && (info.file.status === 'done' || info.file.status === 'uploading')) {
        handelDel()
        message.warning('上传的文件名不能包含空格')
      }
    }
    const handelDel = () =>{
      formData.code =''
      state.showImg = []
    }
    const onPreviewFile =() =>{
      if(formData.code){
        window.location = formData.code

      }

    }
    const changeName = function(value) {
      state.qvdaojiekoumingcheng = []
      formData.portId = ''
      state.dataInterface.forEach(res => {
        if (res.insId === value) {
          res.children.forEach(item => {
            let obj = {}
            obj.value = item.id
            obj.name = item.ipId
            obj.label = item.name
            state.qvdaojiekoumingcheng.push(obj)
          })
        }
        console.log(res)
        console.log(value)
      })
    }
    const filterTreeOption1 = function(input, option) {
      return option.column.includes(input)
    }
    //获取供应商名称
    const getSupplier = function() {
      const uri = getRequestURI('supplier_querySupplier')
      request(uri, {data:{}}).then(res => {
        console.log(res)
        if (res.code == 1) {
          gongyingshangmingcheng.value = res.result
        }
      })
    }
    const filterTreeOption = function (input, option) {
      return option.supName.includes(input)
    }
    const showDrawer = (status, oldData = {}) => {
      state.visible = true
      state.isNew = !(typeof oldData.id === 'string' && oldData.id.length > 0)
      if(oldData.mappingType === 'value_mapping') {
        formData.mapValue = 'mapping'
      }
      else if(oldData.mappingType === 'date_format_mutate') {
        formData.mapValue = 'mutate'
      }
      else if(oldData.mappingType === 'value_calculation') {

        formData.mapValue = 'valueCompute'
      }
      else if(oldData.mappingType === 'subString') {

        formData.mapValue = 'subString'
      }
      else if(oldData.mappingType === 'health_told') {

        formData.mapValue = 'healthTold'
      }

      Object.keys(formData).forEach(key => {
        formData[key] = oldData[key] || formData[key]
      })
      console.log(state.qvdaojiekoumingcheng)
      state.qvdaojiekoumingcheng.forEach(item => {
        if (formData.portId === item.value) {
          changequdaojiekoi('a',item)
        }
      })
    }
    //title判断状态
    // eslint-disable-next-line vue/return-in-computed-property
    const panDuan = computed(() => {
      if (props.status == 'newAdd') {
        return '码值对应关系新增'
      } else if (props.status == 'edit') {
        return '码值对应关系修改 '
      }
    })
    //获取系统名称数据
    const systemName = () => {
      let uri = getRequestURI('env_sync_query')
      request(uri, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }, true, 'get').then(res => {
        console.log(res.result, '456')
        state.nameList = res.result
      })
    }
    // 获取映射列表数据
   const changeMappingMode = function (value) {
     const showList = [
       {value: 'value_mapping', status: 'mapping'},
       {value: 'date_format_mutate', status: 'mutate'},
       {value: 'value_calculation', status: 'valueCompute'},
       {value: 'subString', status: 'subString'},
       {value: 'health_told', status: 'healthTold'}
     ]
    const mappingItem =  mappingList.value.find(item => item.value === value )
     showList.forEach(item => {
       if(item.value === mappingItem.value) {
         formData.mapValue = item.status
       }
     })
     console.log('值映射', value, mappingList)

   }
    const changequdaojiekoi = function(value, label) {
      console.log(label.name)
      if (value === 'a') {
        console.log()
      } else {
        formData .icId = ''
      }
      request(getRequestURI('ExcInsurerC_selectListForIpId'),{data: {ipId: label.name}}).then(res => {
        if (res.code === 1) {
          state.qvdaocanshuminghceng = res.result
        }
      })
    }
    const onFinish = (values) => {
      // console.log('search-form-state: ', formState)
      context.emit('callback', values)
    }

    //关闭
    const onClose = () => {
      Object.assign(formData, {
        code: '',
        icId: undefined,
        id: null,
        insId: undefined,
        portId: undefined,
        value: '',
        mappingType: undefined,
        mapValue: undefined,
      })
      state.visible = false
      // state.code = '',
      // state.icId = undefined,
      // state.insId = undefined,
      // state.ipId = undefined,
      // state.value = ''
      onFormClean()
    }
    //重置
    const onFormClean = () => {

      // formData.InterfaceName = ''//接口名称

    }

    //保存/下一步
    const onSave = () => {
      // //接口名称校验
      // if (formData.InterfaceName === '') {
      //   message['warning']('接口名称不能为空')
      //   return
      // }
      // let reg1 = formData.InterfaceName
      // if (reg1.length > 20) {
      //   formData.InterfaceName = ''
      //   message['warning']('接口名称输入长度不能超过20')
      //   return
      // }
      //
      // //接口标识校验
      // if (formData.InterfaceIdentification === '') {
      //   message['warning']('接口标识不能为空')
      //   return
      // }
      // let reg2 = formData.InterfaceIdentification
      // if (reg2.length > 20) {
      //   formData.InterfaceIdentification = ''
      //   message['warning']('接口标识输入长度不能超过20')
      //   return
      // }
      // delete formData.id
      let obj = {
        data: formData
      }
      console.log('提交',obj)
      if (props.status == 'edit') {
        let uri = getRequestURI('ExcCodeValue_update1')
        request(uri, obj).then(res => {
          props.handleSearch(props.searchfFilters)
          if (res.code == 1) {
            onClose()
            message['success']('修改成功')
          }else{
            message['warning'](res.message)
          }
        })
      } else if (props.status == 'newAdd') {
        console.log(obj)
        let uri = getRequestURI('ExcCodeValue_insert1')
        request(uri, obj).then(data => {
          props.handleSearch({})
          if (data.code == 1) {
            onClose()
            message['success']('保存成功')
          }else{
            message['warning'](data.message)
          }
        })
      }
    }
    const init = function() {
      request(getRequestURI('select_treeLeft'),{}).then(res => {
        state.dataInterface = res.result
        res.result.forEach(item => {
          if (item.children.length !== 0) {
            item.children.forEach(item1 => {
              let obj1 = {}
              obj1.value = item1.id
              obj1.name = item1.ipId
              obj1.label = item1.name
              state.qvdaojiekoumingcheng.push(obj1)
            })
            console.log(formData.portId)
          }
          let obj = {}
          obj.value = item.insId
          obj.label = item.insName
          state.nameList.push(obj)
        })
      })
      // 获取调用方参数
      request(getRequestURI('ExcInsurerC_selectListForIpId'), {data: {}}).then(res => {
        if (res.code === 1) {
          state.qvdaocanshuminghceng = res.result
          console.log(state.qvdaocanshuminghceng)
        }
      })
    }
    init()
    const filterOption = (input, option) => {
      return option.label.indexOf(input) > -1
    }
    expose({
      showDrawer
    })
    onMounted(() =>{
      getSupplier()
      getDictionaryData(mappingList,'value_Mapping_type')
      getDictionaryData(dataFormatList,'date_format')
      getDictionaryData(computeModeList,'compute_mode')
    })

    return {
      dataFormatList,
      computeModeList,
      mappingList,
      filterOption,
      formRef,
      formData,
      ...toRefs(state),
      onClose,
      onSave,
      onFormClean,
      getRequestURI,
      onFinish,
      showDrawer,
      panDuan,
      systemName,
      changeName,
      changequdaojiekoi,
      changeMappingMode,
      getSupplier,
      gongyingshangmingcheng,
      filterTreeOption,
      filterTreeOption1,
      getFileList,
      uploadCosFileChange,
      onPreviewFile,
      handelDel
    }
  }

})
</script>
