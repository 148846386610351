<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
    @call="changes"
  ></search-form>
<!--  <div class="btn-bar">-->
<!--    <a-button danger @click="chongxinfasong()">-->
<!--      <template #icon> </template>-->
<!--      重新发送-->
<!--    </a-button>-->
<!--  </div>-->
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-key="(record) => record.id"
    :pagination="pagination"
    :scroll="{ x: 800 }"
    :loading="loading"
    @change="handleTableChange"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
  >
    <!--    :expandIcon="expandIcon"-->
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a-button
            type="text"
            style="color: orange"
            size="small"
            @click="checkUser(record)"
          >
            查看
          </a-button>
          <a-button
            type="text"
            style="color: orange"
            size="small"
            @click="resendRun(record)"
          >
            重新发送
          </a-button>
        </div>
      </template>
    </template>
  </a-table>
  <index-details ref="userDrawerRef"></index-details>
</template>

<script>
import SearchForm from '../../../components/extended/search_form'
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from 'vue'
import {getRequestURI, request, requestUsePagination} from '../../../utils/request'
import IndexDetails from './indexDetails'
import {message, Modal} from 'ant-design-vue'

export default defineComponent({
  components: {
    IndexDetails,
    SearchForm
  },
  setup() {
    const userDrawerRef = ref()
    const state = reactive({
      selectedRowKeys: [],
      selectedRows: [],
      query: [],
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 80,
          fixed: 'left',
          align: 'center',
        },
        {
          title: '调用方名称',
          width: 150,
          fixed: 'left',
          align: 'center',
          key: 'portName',
          dataIndex: 'portName',
        },
        {
          title: '流水号',
          width: 230,
          key: 'processRecordId',
          align: 'center',
          dataIndex: 'processRecordId',
        },
        {
          title: '保单号',
          width: 150,
          key: 'contNo',
          align: 'center',
          dataIndex: 'contNo',
        },
        {
          title: '请求时间',
          width: 200,
          align: 'center',
          key: 'requestTime',
          dataIndex: 'requestTime',
        },
        {
          title: 'url地址',
          width: 280,
          key: 'actionUrl',
          align: 'center',
          dataIndex: 'actionUrl',
        },
        {
          title: '响应时间',
          width: 150,
          align: 'center',
          key: 'processTime',
          dataIndex: 'processTime',
        },
        {
          title: '请求人ip',
          width: 150,
          align: 'center',
          key: 'userId',
          dataIndex: 'userIp',
        },
        {
          title: '状态',
          align: 'center',
          key: 'errorFlag',
          fixed: 'right',
          dataIndex: 'errorFlag',
          width: 100,
          customRender: (text) => {
            if (text.value === 1) {
              return '失败'
            } else {
              return '成功'
            }
          }
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          align: 'center',
          width: 180,
        },
      ],
      condition: {
        insId: {
          type: 'select',
          label: '调用方名称',
          default: '',
          data: []
        },
        ipId: {
          type: 'select',
          label: '接口名称',
          default: '',
          data: []
        },
        tradeNo: '流水号',
        contNo: '保单号',
        actionUrl: '请求url',
        errorFlag: {
          type: 'select',
          label: '交易状态',
          data: [
            {
              label: '成功',
              value: 0
            },
            {
              label: '失败',
              value: 1
            }
          ]
        },
        toTime: {
          type: 'date-time-picker',
          label: '开始时间'
        },
        outTime: {
          type: 'date-time-picker',
          label: '结束时间'
        }
      }
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ExcLogselect_selectList'))
    const onSelectChange = (selectedRowKeys, selectedRows) => {
      state.query = []
      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
    }
    const chongxinfasong = function () {
      if (state.selectedRows.length === 0) {
        message['warning']('请选择要重新发送的数据')
      } else {
        state.selectedRows.forEach(item => {
          let obj = {}
          obj.innerId = item.innerId
          obj.method = item.method
          obj.param = item.toData
          state.query.push(obj)
        })
        const uri = getRequestURI('engine_run')
        request(uri, { data: state.query }).then(res => {
          if (res.code === 1) {
            message.success(res.message)
          } else {
            message.success(res.message)
          }
          state.selectedRowKeys = []
        })
      }
    }
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter,
        data: state.lastFilter,
      })
    }
    const checkUser = function (value) {
      userDrawerRef.value.showDrawer(value)
    }
    // 重新发送
    const resendRun = (data) =>{
      loading.value = true
      Modal.confirm({
        title: '发送提示',
        content: '确定重新发送此数据吗?',
        onOk: () => {
          request(getRequestURI('resend_run'),{id: data.id}).then(res =>{
            if (res.code === 1) {
              message.success(res.message)
              loading.value = false
            } else {
              message.warning(res.message)
            }
          })
        },
        onCancel: () => {
          loading.value = false
          Modal.destroyAll()   // 清除弹出框
        }
      })

    }
    //页面挂载调用数据
    onMounted(() => {
      handleSearch({})
      getInsId()
    })
    const handleSearch = function (filters) {
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        data: filters,
      })
    }
    //获取调用方名称
    const getInsId = () => {
      request(getRequestURI('select_treeLeft'), {}).then(res => {
        state.dataInterface = res.result
        res.result.forEach(item => {
          let obj = {}
          obj.value = item.insId
          obj.label = item.insName
          state.condition.insId.data.push(obj)
        })
      })
    }
      const changes = function(value) {
        state.condition.ipId.data = []
        state.dataInterface.forEach(res => {
          if (res.insId === value) {
            res.children.forEach(item => {
              let obj = {}
              obj.value = item.ipId
              obj.label = item.name
              state.condition.ipId.data.push(obj)
            })
          }
        })
      }
    return {
      ...toRefs(state),
      getInsId,
      handleSearch,
      handleTableChange,
      run,
      loading,
      current,
      pageSize,
      total,
      dataSource,
      onSelectChange,
      pagination,
      checkUser,
      userDrawerRef,
      chongxinfasong,
      changes,
      resendRun
    }
  }
})
</script>

<style scoped>
</style>
