<template>
  <search-form
    :Condition="condition"
    :ColSpan="12"
    @callback="handleSearch"
  ></search-form>
  <div class="btn-bar">
    <a-button type="primary" @click="addUser('newAdd')">
      <template #icon>
        <FileAddOutlined />
      </template>
      新增
    </a-button>
    <a-button danger @click="deleteUser">
      <template #icon>
        <DeleteOutlined />
      </template>
      删除
    </a-button>
    <a-button danger @click="dataSynch">
      <template #icon>
        <RetweetOutlined />
      </template>
      数据同步
    </a-button>
  </div>
  <a-table
    :columns="columns"
    :data-source="dataSource ? dataSource['result'] : []"
    :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }"
    :row-key="(record) => record.id"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="loading"
  >
    <template #bodyCell="{ column, index, record }">
      <template v-if="column.key === 'index'">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </template>
      <template v-if="column.key === 'operation'">
        <div>
          <a style="color: orange" @click="modifyUser(record, 'edit')">编辑</a
          >&nbsp;&nbsp;
          <!--          <a style="color: orange" @click="toConfigure()">模板配置</a>&nbsp;&nbsp;-->
          <!--          <a style="color: orange" @click="relation(record)">关联产品</a>-->
        </div>
      </template>
    </template>
  </a-table>
  <productTemplateAdd
    ref="userDrawerRef"
    :handleSearch="handleSearch"
    :status="status"
    :searchfFilters="searchfFilters"
  ></productTemplateAdd>

  <dataTemplateSynch
    ref="dataDrawerRef">
  </dataTemplateSynch>
  <!--  <productTemplateguanLian ref="userDrawerRefOne" :handleSearch="handleSearch" :status="status"></productTemplateguanLian>-->
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { getRequestURI, request, requestUsePagination } from '../../../utils/request'
import { getExtendUri } from '../../../utils/request_extend'
import SearchForm from '../../../components/extended/search_form'
import productTemplateAdd from './InterfaceProviderManagementAdd.vue'
import dataTemplateSynch from './DataTemplateSynch.vue'
import { Modal, message } from 'ant-design-vue'
export default defineComponent({
  components: {
    SearchForm,
    productTemplateAdd,
    dataTemplateSynch
    // productTemplateguanLian
  },
  setup () {
    onMounted(() => {
      // huoqu()
      qudao()
      leixing()
    })
    const userDrawerRef = ref()
    const userDrawerRefOne = ref()
    const dataDrawerRef = ref()

    const state = reactive({
      condition: {
        name: '接口名称',
        method: '接口标识'
      },
      status: '',
      result: [],
      abc: [],
      lastFilter: {},
      query: {
        data: {
          riskCode: '',
          riskLengthSign: '',
          riskName: '',
          riskSign: '',
          riskTypeLargeCode: '',
          supRiskCode: '',
        }
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          fixed: 'left',
          width: 60,
          align: 'left',
        },
        {
          title: '接口名称',
          width: 140,
          align: 'center',
          key: 'name',
          dataIndex: 'name',
        },
        {
          title: '接口标识',
          width: 140,
          align: 'center',
          key: 'method',
          dataIndex: 'method',
        },
        {
          title: '入参类型',
          width: 140,
          align: 'center',
          key: 'intoType',
          dataIndex: 'intoType',
          customRender: (text) => {
            if (text.value === 1) {
              return 'JSON'
            } else if (text.value === 2) {
              return 'XML'
            }
          }
        },
        {
          title: '出参类型',
          width: 140,
          align: 'center',
          key: 'outType',
          dataIndex: 'outType',
          customRender: (text) => {
            if (text.value === 1) {
              return 'JSON'
            } else if (text.value === 2) {
              return 'XML'
            }
          }
        },
        {
          title: '接口地址',
          width: 140,
          align: 'center',
          key: 'serverPath',
          dataIndex: 'serverPath',
        },
        {
          title: '操作',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          width: 200,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      selectedRowKeys: [],
      data: [],// 删除data
    })
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = requestUsePagination(getRequestURI('ExcPort_selectList12'))
    const pagination = computed(() => ({
      current: current.value,
      pageSize: pageSize.value,
      total: total.value,
    }))
    const handleTableChange = (pag, filters, sorter) => {
      console.log('handleTableChange', pag, filters, sorter)
      run({
        pagination: {
          current: pag?.current,
          pageSize: pag?.pageSize
        },
        sorter: [{
          field: '',
          order: ''
        }],
        data: state.lastFilter,
      })
    }//页面挂载调用数据
    onMounted(() => {
      handleSearch({})
    })
    // 存储搜索组件传过来的数据
    const searchfFilters = ref({})
    const handleSearch = function (filters) {
      if (filters) {
        searchfFilters.value = filters
      }
      state.lastFilter = filters
      run({
        pagination: {
          current: 1,
          pageSize: 10
        },
        sorter: [{
          field: '',
          order: ''
        }],
        data: filters,
      })
    }

    //获取销售渠道
    const qudao = () => {
      let obj = {
        types: ['channel_type'],
        ids: []
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, { data: obj }).then(result => {
        const arr = []
        const res = result.result.channel_type
        for (let i = 0; i < res.length; i++) {
          arr.push({ label: res[i].label, value: res[i].value })
        }

        // state.condition.method.data = arr

      })
    }

    //获取模板类型
    const leixing = () => {
      let obj = {
        types: ['template_type'],
        ids: []
      }
      let uri = getExtendUri('dictionary_query_byType')
      request(uri, { data: obj }).then(res => {
        console.log(res, '456')
        state.abc = res.result.template_type
      })
    }

    //多选
    const onSelectChange = (selectedRowKeys) => {
      state.data.splice(0)
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
      for (let selectedRowKeys of state.selectedRowKeys) {
        for (let result in state.result) {
          if (state.result[result].id == selectedRowKeys && state.data.includes(state.result[result]) == false) {
            state.data.push(state.result[result])
          }
        }
      }
      console.log(state.data)
    }


    //新增
    const addUser = function (status) {
      console.log(status)
      state.status = status
      userDrawerRef.value.showDrawer(status)
    }
    //编辑
    const modifyUser = function (record, status) {
      console.log(record)
      let obj = {
        id: record.id
      }
      state.status = status
      // userDrawerRef.value.showDrawer(record,status)
      const uri = getRequestURI('ExcPort_selectById')
      request(uri, { data: obj }).then(res => {
        userDrawerRef.value.showDrawer(res.result, status)
        console.log(res)
      })
    }
    //批量删除
    const deleteUser = () => {
      let keys = state.selectedRowKeys
      if (keys.length === 0) {
        message.warning('请选择数据')
        return
      }
      Modal.confirm({
        title: '确认删除吗?',
        async onOk () {

          let uri = getRequestURI('ExcPort_deleteList')
          request(uri, {
            data: {
              ids: keys
            }
          }).then(
            (result) => {
              if (result.code == 1) {
                message['success']('删除成功')
                handleSearch({})
                state.selectedRowKeys = []
              } else {
                message.warning(result.message)
              }

            }
          )
        },
        onCancel () {
        }
      })
    }

    // 数据同步
    const dataSynch = (params)=> {
      console.log(params)
      dataDrawerRef.value.showDrawer()
    }
    // //模板配置
    // const toConfigure = () => {
    //
    // }
    // //关联产品
    // const relation = (record) => {
    //   state.status = status
    //   userDrawerRefOne.value.showDrawerOne(record)
    // }

    return {
      ...toRefs(state),
      handleSearch,
      addUser,
      userDrawerRef,
      dataDrawerRef,
      userDrawerRefOne,
      deleteUser,
      dataSynch,
      modifyUser,
      onSelectChange,
      pagination,
      dataSource,
      loading,
      handleTableChange,
      run,
      qudao,
      // toConfigure,
      current,
      pageSize,
      total,
      // relation,
      leixing,
      searchfFilters

    }
  },
})
</script>

<style scoped>
</style>
