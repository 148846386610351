<style scoped>

</style>

<template>
  Home
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {}
  }
}
</script>

